import React from 'react';
import {Button, Form, Input, Modal, Select, Row, Col} from 'antd';
import {fromJS, is} from 'immutable';
import {operationTypeValue} from 'modules/brand/enums';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";
import TrainingPreconditionModel from "../../training-management/components/TrainingPreconditionModel";
import { getLadderBrandList } from "../../../modules/brand/action";
import { FloorPosition, enumToArray } from '../../../dictionary/dictionary';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 9},
    wrapperCol: {span: 12},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddCertificationModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            defaultValue: null,
            preconditionValue: null,
            isShowPreconditionModal : false,
        };
    }


    componentWillMount() {
        this.onInitData();
    }

    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            // eslint-disable-next-line react/prop-types
            this.props.form.resetFields();
            this.onInitData(nextProps);
        }
    }

    onInitData = (props = this.props) => {
        const {value} = props;
        console.log(value)
        const defaultValue = value || {};
        this.setState({
            // eslint-disable-next-line react/no-unused-state
            defaultValue,
            preconditionValue: defaultValue.precondition ? defaultValue.precondition : null
        });

        const {getGameList, getLadderBrandList} = props;
        getGameList();
        getLadderBrandList({page: 1, size: 100000});
    }

    onSubmit = () => {
        // eslint-disable-next-line react/prop-types
        const { ladderBrands } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {       
                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                if(!values.floorPosition) delete values.floorPosition;
                onSuccess && onSuccess(values, ladderBrands);
            }
        });
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const {getFieldDecorator} = this.props.form;
        const {value, visible, operationType, games, ladderBrands} = this.props;
        let positions = enumToArray(FloorPosition);
        
        return (
            <div>
                <Modal
                    width={'30%'}
                    visible={visible}
                    title={"添加参赛认证"}
                    onCancel={this.props.onClose}
                    cancelText={"取消"}
                    onOk={this.onSubmit}
                    okText={"确认"}
                >
                    <Form>
                        <FormItem 
                            label="品牌赛事名"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout}
                        >
                            {getFieldDecorator('brandId', {
                                    initialValue: ladderBrands.data.length > 0 ? ladderBrands.data[0].id : '',
                                    rules: [{required: true, message: '请选择品牌赛事'}],
                                }
                            )(
                                <Select style={{width: '250px'}}>
                                    {ladderBrands.data && ladderBrands.data.map(item => {
                                        let gameList = games.filter((game) => game.code == item.game);
                                        return (
                                            <Option
                                                value={item.id}>{(gameList.length > 0 ? gameList[0].gameName + '-' : '') + item.name}</Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </FormItem>                       
                        <FormItem 
                            label="用户展示ID"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...formItemLayout} 
                        >
                            {getFieldDecorator('exhibitionId', {
                                initialValue: "",
                                rules: [{required: true, message: '请填写用户展示ID'}],
                            })(
                                <Input style={{width: '200px'}} allowClear placeholder="请填写用户展示ID"/>,
                            )}
                        </FormItem>
                        <FormItem 
                            label="场上位置"
                            {...formItemLayout}
                        >
                            {getFieldDecorator('floorPosition', {
                                initialValue: 'ALL_POSITION',
                                rules: [{required: true, message: '请选择场上位置'}],
                            })(
                                <Select  style={{width: 250}}>
                                    <Option value="">请选择</Option>
                                    {positions.map(item => {
                                        return (
                                            <Option value={item.value}>{item.name}</Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </FormItem>                                            
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
        ...state.Brand
    }
};
const mapDispatchToProps = ({
    getGameList,
    getLadderBrandList,
});
AddCertificationModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddCertificationModel)));
