import React from 'react';
import {Button, Card, Col, Form, Input, Modal, Radio, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {
    getPersonalRankDataConfigBrands,
    getPersonalSortFieldDictionaries,
    getPersonalTrainingPlayerRankList,
    updatePersonalTrainingPlayerRank
} from "../../modules/training/action";
import TrainingDataFilter from "./components/TrainingDataFilter";
import moment, {now} from "moment";

const {confirm} = Modal;

class PersonalTrainingDataPlayerManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            size: 10,
            cycle: null,
            yqlBrandType: null,
            dataType: null,
            edition: false,
            updateTrainingPlayerRankDate: null,
            sortField: null,
            currentWeek: null,
        };
    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const {getPelLeagueDictionaries, getPersonalRankDataConfigBrands, getPersonalSortFieldDictionaries, getPersonalTrainingPlayerRankList} = props;
        const startDate = moment(now()).day(1).format('YYYYMMDD'); // 周一日期
        const endDate = moment(now()).day(7).format('YYYYMMDD'); // 周日日期
        await getPersonalRankDataConfigBrands().then((data1) => {
            if(data1 && data1.length == 0)return;
            getPersonalSortFieldDictionaries({
                leagueBrandType: 'YQL',
                rankType: 'PLAYER',
                rankDataType: 'TOTAL',
                brandId: data1[0].id
            }).then((data) => {
                // getPersonalRankDataConfigBrands().then((data1) => {
                getPersonalTrainingPlayerRankList({
                    page: 1, size: 10,
                    yqlBrandType: data1[0].id,
                    cycle: `${startDate}-${endDate}`, sort: data[0].value
                })
                this.setState({
                    cycle: `${startDate}-${endDate}`,
                    currentWeek: `${startDate}-${endDate}`,
                    sortField: {key: data[0].key, value: data[0].value},
                    dataType: 'TOTAL',
                    updateTrainingPlayerRankDate: null,
                    yqlBrandType: data1[0].id
                })
            }).catch(error => {
            })
        }).catch(error => {
        });
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getPersonalTrainingPlayerRankList} = this.props;
        const {sortField, yqlBrandType, cycle, page, size} = this.state;
        this.setState({
            loading: true,
            updateTrainingPlayerRankDate: null
        })
        getPersonalTrainingPlayerRankList({
            page: page, size: size, yqlBrandType: yqlBrandType, cycle: cycle, sort: sortField.value
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.personaltrainingPlayerRankList !== this.props.personaltrainingPlayerRankList) {
            this.setState({
                loading: false
            })
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getPersonalTrainingPlayerRankList} = this.props;
        const {yqlBrandType, cycle, sortField} = this.state;
        getPersonalTrainingPlayerRankList({
            page: current, size: pageSize, yqlBrandType: yqlBrandType, cycle: cycle, sort: sortField.value
        })
        this.setState({
            page: current,
            size: pageSize,
            loading: true,
            edition: false,
            updateTrainingPlayerRankDate: null
        });
    }

    onBtnClick = (type) => {
        if (type === true) {
            this.setState({
                edition: type,
            });
        } else {
            let _this = this;
            confirm({
                content: '确认要保存本次修改吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.onSavePelData(type)
                },
                onCancel() {
                },

            });
        }
    }

    onSavePelData = async (type) => {
        const {updateTrainingPlayerRankDate} = this.state;
        const {updatePersonalTrainingPlayerRank} = this.props;
        await updatePersonalTrainingPlayerRank(updateTrainingPlayerRankDate).then((data) => {
        }).catch(error => {
        })
        await this.onGetTableData()
        await this.setState({
            edition: type,
        });
    }

    onBtnCancelClick = (type) => {
        let _this = this;
        confirm({
            content: '确认要取消编辑吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.setState({
                    edition: type,
                });
            },
            onCancel() {
            },

        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary"
                        onClick={() => this.onBtnClick(!this.state.edition)}>{this.state.edition ? `保存` : `编辑`}</Button>
                {this.state.edition ? <Button type="primary" style={{marginLeft: 20}}
                                              onClick={() => this.onBtnCancelClick(!this.state.edition)}>撤销</Button> : null}
            </Col>
        </Row>
    )

    //条件查找动态方法
    onFilterTrainingDataIntegralEvent = async (cycle, brandType, dataType) => {
        const {size} = this.state;
        const {getPersonalSortFieldDictionaries, getPersonalTrainingPlayerRankList} = this.props;
        await getPersonalSortFieldDictionaries({
            leagueBrandType: 'YQL',
            rankType: 'PLAYER',
            rankDataType: dataType,
            brandId: brandType
        }).then((data) => {
            getPersonalTrainingPlayerRankList({
                page: 1, size: size, cycle: cycle, yqlBrandType: brandType, sort: data[0].value
            })
            this.setState({
                yqlBrandType: brandType,
                cycle: cycle,
                sortField: data[0],
                dataType: dataType,
                page: 1,
                edition: false,
                updateTrainingPlayerRankDate: null
            })
        })
    }

    onChangeRadio = e => {
        const {personalSortFieldDicList, getPersonalTrainingPlayerRankList} = this.props;
        let field = personalSortFieldDicList.filter(item => item.value === e.target.value);
        getPersonalTrainingPlayerRankList({
            page: 1,
            size: this.state.size,
            cycle: this.state.cycle, yqlBrandType: this.state.yqlBrandType,
            sort: e.target.value,
            updateTrainingPlayerRankDate: null
        })
        this.setState({
            sortField: field.length > 0 ? field[0] : this.state.sortField,
            page: 1
        });
    };

    render() {
        const {
            personaltrainingPlayerRankList, personalSortFieldDicList
        } = this.props;
        console.log('personaltrainingPlayerRankList',personaltrainingPlayerRankList);
        const {
            page, size, loading, sortField, cycle, currentWeek
        } = this.state;
        const radioStyle = {
            display: 'block',
            marginBottom: '5px',
            height: '40px',
            width: '120px',
            lineHeight: '40px',
            textAlign: 'center'
        };
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrainingDataFilter dataSource={{...this.props}} onCommit={this.onFilterTrainingDataIntegralEvent}/>
                </Card>
                <Card>
                    <Row>
                        <Col span={2}>
                            <Radio.Group onChange={this.onChangeRadio}
                                         value={sortField ? sortField.value : personalSortFieldDicList ? personalSortFieldDicList[0].value : null}
                                         buttonStyle="solid">
                                {personalSortFieldDicList && personalSortFieldDicList.map((item) => <Radio.Button style={radioStyle}
                                                                                                  value={item.value}>{item.key}</Radio.Button>)}
                            </Radio.Group>
                        </Col>
                        <Col span={22}>
                            <Table
                                dataSource={personaltrainingPlayerRankList ? personaltrainingPlayerRankList.data : []}
                                title={cycle === currentWeek ? null : this.renderTableTitle}
                                columns={this.getAllTableColumns()}
                                bordered
                                pagination={{
                                    total: personaltrainingPlayerRankList ? personaltrainingPlayerRankList.total : 0,
                                    current: page,
                                    pageSize: size,
                                    onChange: this.onPageChangeEvent,
                                    showTotal: (total) => {
                                        return `总计 ${total}条数据`;
                                    }
                                }}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>)
    }


    onChangeTable = (key, row, value) => {
        const {personaltrainingPlayerRankList} = this.props;
        let {updateTrainingPlayerRankDate} = this.state;
        console.log()
        if (!updateTrainingPlayerRankDate) {
            console.log("updateTrainingPlayerRankDate")
            updateTrainingPlayerRankDate = JSON.parse(JSON.stringify(personaltrainingPlayerRankList.data));
        }
        updateTrainingPlayerRankDate[row][key] = value;
        this.setState({updateTrainingPlayerRankDate: updateTrainingPlayerRankDate})
    }

    getAllTableColumns = () => {
        const {getFieldDecorator} = this.props.form;
        const {sortField, dataType} = this.state;
        console.log(sortField)
        let columns = [
            {
                title: '排名',
                dataIndex: 'rank',
                key: 'rank',
                align: 'center',
            },
            {
                title: '用户展示ID',
                dataIndex: 'exhibitionId',
                key: 'exhibitionId',
                align: 'center',
                // render: (index, text, row) => {
                //     return (this.state.edition ?
                //         <div>
                //             <Form.Item style={{margin: 0}}>
                //                 {getFieldDecorator('exhibitionId' + row, {
                //                     initialValue: text.exhibitionId ? text.exhibitionId : null,
                //                 })(<InputNumber style={{width: 120}}
                //                                 onChange={(e) => this.onChangeTable('exhibitionId', row, e)}/>)}
                //             </Form.Item>
                //         </div> : text.exhibitionId)
                // },
            },
            {
                title: '用户名',
                dataIndex: 'playerName',
                key: 'playerName',
                align: 'center',
            },
           ];
        if (sortField) {
            columns.push({
                title: `${sortField.key}`,
                dataIndex: `${sortField.value}`,
                key: `${sortField.value}`,
                align: 'center',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator(`${sortField.value}` + row, {
                                    initialValue: text[`${sortField.value}`],
                                    rules: [
                                        {
                                            required: true,
                                            message: `总积分不能为空.`,
                                        },
                                    ],
                                })(<Input
                                    onChange={(e) => this.onChangeTable(`${sortField.value}`, row, e)}/>)}
                            </Form.Item>
                            {/*</div> : text[`${sortField.value}`])*/}
                        </div> : dataType === 'AVG' ? (sortField.value === 'avgWinCount' || sortField.value === 'avgTop5Count' || sortField.value === 'avgHighHurtRatio' || sortField.value === 'avgHitRatio' ?
                            text[`${sortField.value}`] ? (text[`${sortField.value}`] * 100).toFixed(2) + '%' : '0%' :
                            text[`${sortField.value}`] ? (text[`${sortField.value}`]).toFixed(2) : 0)
                            : text[`${sortField.value}`])
                },
            })
        }
        return (columns)
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.Brand,
        ...state.viewTraining,
    }
};

const mapDispatchToProps = ({
    getPersonalSortFieldDictionaries,
    getPersonalTrainingPlayerRankList,
    updatePersonalTrainingPlayerRank,
    getPersonalRankDataConfigBrands,
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(PersonalTrainingDataPlayerManagement)));
