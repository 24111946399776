import React from 'react';
import {Button, Card, Col, Form, InputNumber, Modal, Row, Table} from 'antd';
import {connect} from 'react-redux';
import TrainingDataIntegralFilter from "./components/TrainingDataIntegralFilter"; //***/
import moment, {now} from "moment";
import {
    getFourSomeRankDataConfigBrands,
    getFourSomeTrainingPlayerRankList,
    updateFourSomeTrainingPlayerRank
} from "../../modules/training/action";
import {injectIntl} from "react-intl-context";

const {confirm} = Modal;

class FoursomeLadderMatchDataIntegralManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            size: 10,
            yqlBrandType: null,
            sort: 'generalScore',
            edition: false,
            updateTrainingTeamRankIntegralDate: null,
            currentWeek: null,
        };
    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = async (props = this.props) => {
        const {getFourSomeTrainingPlayerRankList, getFourSomeRankDataConfigBrands} = props
        const startDate = moment(now()).day(1).format('YYYYMMDD'); // 周一日期
        const endDate = moment(now()).day(7).format('YYYYMMDD'); // 周日日期
        await getFourSomeRankDataConfigBrands().then((data) => {
            if(data && data.length == 0)return;
            getFourSomeTrainingPlayerRankList({
                page: 1,
                size: 10,
                yqlBrandType: data[0].id,
                cycle: `${startDate}-${endDate}`,
                sort: this.state.sort
            }).then((data1) => {
                this.setState({
                    cycle: `${startDate}-${endDate}`,
                    currentWeek: `${startDate}-${endDate}`,
                    yqlBrandType: data[0].id
                })
            })
        }).catch(error => {
        });

    }

    //刷新界面数据
    onGetTableData = () => {
        const {getFourSomeTrainingPlayerRankList} = this.props;
        const {cycle, yqlBrandType, page, size} = this.state;
        this.setState({
            loading: true,
            updateTrainingTeamRankIntegralDate: null,
        })
        getFourSomeTrainingPlayerRankList({
            page: page,
            size: size,
            yqlBrandType: yqlBrandType,
            cycle: cycle,
            sort: this.state.sort
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.fourSometrainingPlayerRankList !== this.props.fourSometrainingPlayerRankList) {
            this.setState({
                loading: false
            })
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getFourSomeTrainingPlayerRankList} = this.props;
        const {yqlBrandType, cycle, sort} = this.state;
        getFourSomeTrainingPlayerRankList({
            page: current,
            size: pageSize,
            yqlBrandType: yqlBrandType,
            cycle: cycle,
            sort: sort
        })
        this.setState({
            page: current,
            size: pageSize,
            loading: true,
            edition: false,
            updateTrainingTeamRankIntegralDate: null
        });
    }

    onBtnClick = (type) => {
        if (type === true) {
            this.setState({
                edition: type,
            });
        } else {
            let _this = this;
            confirm({
                content: '确认要保存本次修改吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.onSavePelData(type)
                },
                onCancel() {
                },

            });
        }
    }

    onSavePelData = async (type) => {
        const {updateTrainingTeamRankIntegralDate} = this.state;
        const {updateFourSomeTrainingPlayerRank} = this.props;
        if(!updateTrainingTeamRankIntegralDate)return;
        await updateFourSomeTrainingPlayerRank(updateTrainingTeamRankIntegralDate).then((data) => {
        }).catch(error => {
        })
        await this.onGetTableData()
        await this.setState({
            edition: type,
        });
    }

    onBtnCancelClick = (type) => {
        let _this = this;
        confirm({
            content: '确认要取消编辑吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.setState({
                    edition: type,
                });
            },
            onCancel() {
            },

        });
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                {/* <Button type="primary"
                        onClick={() => this.onBtnClick(!this.state.edition)}>{this.state.edition ? `保存` : `编辑`}</Button>
                {this.state.edition ? <Button type="primary" style={{marginLeft: 20}}
                                              onClick={() => this.onBtnCancelClick(!this.state.edition)}>撤销</Button> : null} */}
            {/* <Button type="primary" style={{marginLeft: 20}}
            onClick={() => this.onBtnCancelClick(!this.state.edition)}>撤销</Button> */}
            </Col>
        </Row>
    )

    //条件查找动态方法
    onFilterTrainingDataIntegralEvent = async (cycle, brandType) => {
        const {size} = this.state;
        const {getFourSomeTrainingPlayerRankList} = this.props;
        await getFourSomeTrainingPlayerRankList({
            page: 1, size: size,
            yqlBrandType: brandType ? brandType : null,
            cycle: cycle ? cycle : null,
            sort: this.state.sort
        });
        await this.setState({
            yqlBrandType: brandType ? brandType : null,
            cycle: cycle ? cycle : null,
            page: 1,
            edition: false,
            updateTrainingTeamRankIntegralDate: null
        })
    }

    render() {
        const {
            fourSometrainingPlayerRankList
        } = this.props;
        const {
            page, size, loading, cycle, currentWeek
        } = this.state;
        console.log('fourSometrainingPlayerRankList',fourSometrainingPlayerRankList);
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrainingDataIntegralFilter dataSource={{yqlBrandType: this.state.yqlBrandType}} onCommit={this.onFilterTrainingDataIntegralEvent}/>
                </Card>
                <Card>
                    <Table
                        dataSource={fourSometrainingPlayerRankList ? fourSometrainingPlayerRankList.data : []}
                        title={cycle === currentWeek ? null : this.renderTableTitle}
                        columns={this.getAllTableColumns()}
                        bordered
                        pagination={{
                            total: fourSometrainingPlayerRankList ? fourSometrainingPlayerRankList.total : 0,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                </Card>
            </div>)
    }


    onChangeTable = (key, row, value) => {
        const {fourSometrainingPlayerRankList} = this.props;
        let {updateTrainingTeamRankIntegralDate} = this.state;
        if (!updateTrainingTeamRankIntegralDate) {
            updateTrainingTeamRankIntegralDate = JSON.parse(JSON.stringify(fourSometrainingPlayerRankList.data));
        }
        updateTrainingTeamRankIntegralDate[row][key] = value;
        this.setState({updateTrainingTeamRankIntegralDate: updateTrainingTeamRankIntegralDate})
    }

    getAllTableColumns = () => {
        const {getFieldDecorator} = this.props.form;
        return ([
            {
                title: '排名',
                dataIndex: 'rank',
                key: 'rank',
                align: 'center',
            },
            {
                title: '用户展示ID',
                dataIndex: 'exhibitionId',
                key: 'exhibitionId',
                align: 'center',
                // render: (index, text, row) => {
                //     return (this.state.edition ?
                //         <div>
                //             <Form.Item style={{margin: 0}}>
                //                 {getFieldDecorator('teamShowId' + row, {
                //                     initialValue: text.teamShowId ? text.teamShowId : null,
                //                 })(<InputNumber style={{width: 120}}
                //                                 onChange={(e) => this.onChangeTable('teamShowId', row, e)}/>)}
                //             </Form.Item>
                //         </div> : text.teamShowId)
                // },
            },
            {
                title: '用户名',
                dataIndex: 'userName',
                align: 'center',
                key: 'userName',
                render: (row, text) => {
                    return (<div><span>{text.userName}</span></div>)
                }
            },
            {
                title: '总淘汰人数',
                dataIndex: 'killNum',
                align: 'center',
                key: 'killNum',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('killNum' + row, {
                                    initialValue: text.killNum,
                                    rules: [
                                        {
                                            required: true,
                                            message: `总淘汰人数不能为空.`,
                                        },
                                    ],
                                })(<InputNumber
                                    onChange={(e) => this.onChangeTable('killNum', row, e)}/>)}
                            </Form.Item>
                        </div> : text.killNum)
                },
            },
            {
                title: '吃蛋糕次数',
                dataIndex: 'winCount',
                align: 'center',
                key: 'winCount',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('winCount' + row, {
                                    initialValue: text.winCount,
                                    rules: [
                                        {
                                            required: true,
                                            message: `吃蛋糕次数不能为空.`,
                                        },
                                    ],
                                })(<InputNumber
                                    onChange={(e) => this.onChangeTable('winCount', row, e)}/>)}
                            </Form.Item>
                        </div> : text.winCount)
                },
            }, {
                title: '吃蛋糕率',
                dataIndex: 'avgWinCount',
                align: 'center',
                key: 'avgWinCount',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('avgWinCount' + row, {
                                    initialValue: text.avgWinCount,
                                    rules: [
                                        {
                                            required: true,
                                            message: `吃蛋糕率不能为空.`,
                                        },
                                    ],
                                })(<InputNumber
                                    onChange={(e) => this.onChangeTable('avgWinCount', row, e)}/>)}
                            </Form.Item>
                        </div> : (text.avgWinCount ? (text.avgWinCount * 100).toFixed(2) + '%' : '0%'))
                },
            }, {
                title: '排名分',
                dataIndex: 'totalRankingIntegral',
                align: 'center',
                key: 'totalRankingIntegral',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('totalRankingIntegral' + row, {
                                    initialValue: text.totalRankingIntegral,
                                    rules: [
                                        {
                                            required: true,
                                            message: `排名分不能为空.`,
                                        },
                                    ],
                                })(<InputNumber
                                    onChange={(e) => this.onChangeTable('totalRankingIntegral', row, e)}/>)}
                            </Form.Item>
                        </div> : text.totalRankingIntegral)
                },
            },
            {
                title: '总积分',
                dataIndex: 'generalScore',
                key: 'generalScore',
                align: 'center',
                render: (index, text, row) => {
                    return (this.state.edition ?
                        <div>
                            <Form.Item style={{margin: 0}}>
                                {getFieldDecorator('generalScore' + row, {
                                    initialValue: text.generalScore,
                                    rules: [
                                        {
                                            required: true,
                                            message: `总积分不能为空.`,
                                        },
                                    ],
                                })(<InputNumber
                                    onChange={(e) => this.onChangeTable('generalScore', row, e)}/>)}
                            </Form.Item>
                        </div> : text.generalScore)
                },
            }
        ])
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.PartnerTraining,
        ...state.Team,
        ...state.Brand,
        ...state.viewTraining,
    }
};

const mapDispatchToProps = ({
    getFourSomeTrainingPlayerRankList,
    updateFourSomeTrainingPlayerRank,
    getFourSomeRankDataConfigBrands
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(FoursomeLadderMatchDataIntegralManagement)));
