import api from '../utils/api';
import {map} from 'lodash/fp';


class CommentRequest {


    /**
     * 获取评论列表
     * @param page
     * @param size
     * @param dynamicId
     * @param userId
     * @param commentContent
     * @param commentStatus
     * @param dateRange
     * @returns {*}
     * @returns {*}
     */
    static getComment(page, size, dynamicId, userId, commentContent,commentStatus, dateRange) {
        console.log(page, size, dynamicId, userId, commentContent,commentStatus, dateRange);
        let startTime = null, endTime = null;
        if (dateRange !== undefined) {
            console.log(dateRange)
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0]+" 00:00:00";
            endTime = dateRange[1] == undefined ? '' : dateRange[1]+" 23:59:59";
        }
        return api.get('/admin/comments/conditions', {page, size, dynamicId, userId, commentContent, commentStatus,startTime, endTime});
    }


}
export default CommentRequest;