import createAsyncAction from 'utils/createAsyncAction';
import ReportRequest from '../../request/ReportRequest' ;
import {
    GET_REPORT,
    GET_REPORTS,
    DELETE_REPORT,
    ASSIGN
} from '../report/actionTypes'

const getReports = (pageNo,pageSize,type,result) => (
    createAsyncAction(GET_REPORTS,() => (ReportRequest.getReports(pageNo,pageSize,type,result)))
);

const getReport = (id) => (
    createAsyncAction(GET_REPORT,() => (ReportRequest.getReport(id)))
);

const deleteReport = (id) => (
    createAsyncAction(DELETE_REPORT,() => (ReportRequest.deleteReport(id)))
);


const assign = (id,result) => (
    createAsyncAction(ASSIGN,() => (ReportRequest.assign(id,result)))
)

export {
    getReport,getReports,deleteReport,assign
}
