
export const vipStateType = {
  EFFECT: {
    label: '生效中',
    value:'EFFECT',
  },
  EXPIRED: {
    label: '已过期',
    value:'EXPIRED'
  },
  NOT_OPEN:{
    label: '未开通',
    value:'NOT_OPEN'
  }
};

export const codeType = {

  VIP_NOT_EXIST:{
    label:"会员账户不存在",
    value:"VIP_NOT_EXIST",
  }

}
