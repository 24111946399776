export const GET_LADDER_TRAINING_LIST = 'GET_LADDER_TRAINING_LIST'; // 天梯四排赛-获取训练赛列表

export const CREATE_LADDER_TRAINING = 'CREATE_LADDER_TRAINING'; // 天梯四排赛-创建训练赛

export const UPDATE_LADDER_TRAINING = 'UPDATE_LADDER_TRAINING'; // 天梯四排赛-修改训练赛信息

export const DELETE_LADDER_TRAINING = 'DELETE_LADDER_TRAINING'; // 天梯四排赛-删除训练赛

export const UPDATE_LADDER_TRAINING_TOP = 'UPDATE_LADDER_TRAINING_TOP'; // 天梯四排赛-训练赛置顶

export const UPDATE_LADDER_VIDEO_CONFIG = 'UPDATE_LADDER_VIDEO_CONFIG'; // 天梯四排赛-配置回放视频

export const GET_LADDER_JOIN_PLAYER = 'GET_LADDER_JOIN_PLAYER'; // 天梯四排赛-获取参与详情列表（报名选手列表）

export const ADD_LADDER_JOIN_PLAYER = 'ADD_LADDER_JOIN_PLAYER'; // 天梯四排赛-（批量）添加报名选手

export const UPDATE_LADDER_PLAYER_SUBSTITUTE = 'UPDATE_LADDER_PLAYER_SUBSTITUTE'; // 天梯四排赛-将选手设为替补（踢出当前队伍）

export const UPDATE_LADDER_PLAYER_ORDER = 'UPDATE_LADDER_PLAYER_ORDER'; // 天梯四排赛-变更选手次序值

export const UPDATE_LADDER_PLAYER_ASSIGN = 'UPDATE_LADDER_PLAYER_ASSIGN'; // 天梯四排赛-修改选手坑位号（指派队伍）

export const DELETE_LADDER_PLAYER_SIGN_UP = 'DELETE_LADDER_PLAYER_SIGN_UP'; // 天梯四排赛-移除报名

export const LADDER_TRAINING_NOTICE = 'LADDER_TRAINING_NOTICE'; // 天梯四排赛-推送通知给选手

export const GET_LADDER_TEAM_GRADE = 'GET_LADDER_TEAM_GRADE'; // 天梯四排赛-获取队伍战绩列表

export const GET_LADDER_PERSON_GRADE = 'GET_LADDER_PERSON_GRADE'; // 天梯四排赛-获取个人战绩列表

export const GET_LADDER_PERSON_GRADE_DETAIL = 'GET_LADDER_PERSON_GRADE_DETAIL'; // 天梯四排赛-获取个人战绩
