import React from 'react';
import { Upload, Icon, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { fromJS, is } from 'immutable';
import Cookie from 'js-cookie';
import { ACCESS_TOKEN } from 'modules/authentication/constants';

const getAuthorizationHeader = headers => {
    try {
        let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
        const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
        if (headers && accessToken) {
            headers.Authorization = accessToken.token;
        }
        return headers;
    } catch (error) {
        return '';
    }
};

class PicturesWall extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool, //是否禁止上传
        maxNumber: PropTypes.number, //最大上传数量
        double: PropTypes.bool, //是否上传多张
        width: PropTypes.number, //图片默认宽度
        height: PropTypes.number, //图片默认高度
        aspectRatio: PropTypes.number, //宽高比(宽高比跟宽度高度同时上传以宽高比为主)
    };

    static defaultProps = {
        disabled: false,
        maxNumber: 9,
        double: false,
    };
    state = {
        previewVisible: false,
        previewImage: '',
        fileList: [
            {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
            {
                uid: '-2',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
            {
                uid: '-3',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
            {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
            {
                uid: '-5',
                name: 'image.png',
                status: 'done',
                url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            },
        ],
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handleChange = ({ file, fileList }) => {
        console.log(file, 'file', fileList);
        this.setState(
            {
                fileList,
            },
            () => this.handleFilesOfValue()
        );
    };
    //转化数据格式
    handleVauleOfFiles = props => {
        const { double, value } = props;
        let files = value;
        if (!double) files = value ? [value] : [];
        files = files.map((img, key) => {
            return {
                uid: key,
                name: 'image.png',
                status: 'done',
                url: img,
            };
        });
        this.setState({
            fileList: files,
        });
    };
    handleFilesOfValue = () => {
        const { onChange, double } = this.props;
        const { fileList } = this.state;
        let imgs = [];
        fileList.forEach(img => {
            if (img.url) {
                imgs.push(img.url);
            }
        });
        if (!double) imgs = imgs.length > 0 ? imgs[0] : '';
        onChange && onChange(imgs);
    };
    setResponseImage = () => {
        const { fileList } = this.state;
        const { onChange, double } = this.props;
        let imgs = [];
        fileList.forEach(img => {
            if (img) imgs.push(img.url);
        });
        imgs = double ? imgs : imgs[0];
        onChange && onChange(imgs);
    };
    componentWillReceiveProps(nextProps) {
        if (!is(fromJS(this.props.value), fromJS(nextProps.value))) {
            this.handleVauleOfFiles(nextProps);
        }
    }
    componentWillMount() {
        this.header = getAuthorizationHeader({});
        this.handleVauleOfFiles(this.props);
    }
    handleRemove = img => {
        let { fileList } = this.state;
        fileList = fileList.filter(item => item.uid != img.uid)
        console.log(fileList.length);
        this.setState({fileList},()=> this.setResponseImage())
    };
    handleSuccess = response => {
        let { fileList } = this.state;
        fileList.push({
            uid: fileList.length,
            name: 'image.png',
            status: 'done',
            url: response.url,
        });
        this.setState(
            {
                fileList,
            },
            () => this.handleFilesOfValue()
        );
    };
    beforeUpload = file => {
        // const isPNG = file.type === 'image/png';
        // if (!isPNG) {
        //     message.error('图片格式不正确，请修改后重新上传！', 0.8);
        //     return isPNG;
        // }
        let{size} = this.props;
        const isLt2M = file.size / 1024  < (size-1);
        if (!isLt2M) {
            message.error('图片大小超出限制'+(size-1)+'KB，请修改后重新上传', 1.5);
            return isLt2M;
        }
        const isSize = this.isSize(file);
        // return isPNG && isLt2M && isSize;
        return isSize;
    };
    //检测尺寸
    isSize = file => {
        let { height, width, aspectRatio } = this.props;
        if ((width && height) || aspectRatio) {
            return new Promise((resolve, reject) => {
                let _URL = window.URL || window.webkitURL;
                let img = new Image();
                img.onload = function() {
                    let valid = aspectRatio
                        ? aspectRatio == img.width / img.height
                        : img.width == width && img.height == height;
                    valid ? resolve() : reject();
                };
                img.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    let ErrorMsg = aspectRatio
                        ? `图片的宽高比是${aspectRatio},请确认上传图片，修改后重新上传`
                        : `图片的宽度为${width},高度为${height},请确认上传图片，修改后重新上传`;
                    message.error(ErrorMsg);
                    return Promise.reject();
                }
            );
        }
        return true;
    };
    handlePreview = async file => {
        this.setState({
            previewImage: file.url || file.response.url,
            previewVisible: true,
        });
    };

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const { double, maxNumber } = this.props;
        const header = this.header;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">上传</div>
            </div>
        );
        return (
            <div >
                <Upload
                    action="/api/admin/oss/upload"
                    listType="picture-card"
                    name="file"
                    headers={header}
                    beforeUpload={this.beforeUpload}
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onSuccess={this.handleSuccess}
                    onRemove={this.handleRemove}
                >
                    {(!double && fileList.length === 1) || (double && fileList.length === maxNumber)
                        ? null
                        : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage ? previewImage.split("?")[0]+"?x-oss-process=image/resize,w_690" : previewImage} />
                </Modal>
            </div>
        );
    }
}

export default PicturesWall;
