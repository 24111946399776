import api from 'utils/api';

class TrainingTemplate {
    /**
     * 天梯四排赛-获取赛事模板列表
     * @param {Object} params 请求参数
     */
     static async getLadderTemplateList(parmas) {
        return api.get('/admin/mixed_team/training/template', parmas);
    }

    /**
     * 天梯四排赛-创建赛事模板
     * @param params
     * @returns {Promise<*>}
     */
     static async createLadderTemplate(params){
        return api.post(`/admin/mixed_team/training/template`, params);
    }

    /**
     * 天梯四排赛-编辑赛事模板
     * @param id
     * @param params
     * @returns {Promise<*>}
     */
     static async updateLadderTemplate(templateId, params){
        return api.put(`/admin/mixed_team/training/template/${templateId}`, params)
    }

    /**
     * 天梯四排赛-删除赛事模板
     */
     static async deleteLadderTemplate(templateId) {
        return api.delete(`/admin/mixed_team/training/template/${templateId}`);
    }

    /**
     * 天梯四排赛-获取赛事模板字典
     */
     static async getLadderTemplateDictionaries(){
        return api.get(`/admin/mixed_team/training/template/dictionaries`);
    }

    /**
     * 天梯四排赛-获取赛事模板信息
     */
     static async getLadderTemplateInfo(game, brandId, type) {
        return api.get(`/admin/mixed_team/training/template/${game}/${brandId}/${type}`);
    }
}

export default TrainingTemplate;
