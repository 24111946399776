export const GET_LADDER_TEMPLATE_LIST = 'GET_LADDER_TEMPLATE_LIST';

export const CREATE_LADDER_TEMPLATE = 'CREATE_LADDER_TEMPLATE';

export const UPDATE_LADDER_TEMPLATE = 'UPDATE_LADDER_TEMPLATE';

export const DELETE_LADDER_TEMPLATE = 'DELETE_LADDER_TEMPLATE';

export const GET_LADDER_TEMPLATE_DICTIONARIES = 'GET_LADDER_TEMPLATE_DICTIONARIES';

export const GET_LADDER_TEMPLATE_INFO = 'GET_LADDER_TEMPLATE_INFO';
