import api from 'utils/api';

class TrainingVideoRequest {
    /**
     * 天梯四排赛-获取回放视频列表
     * @param {Object} params 请求参数
     */
     static async getLadderVideoList(params) {
        return api.get('/admin/mixed_team/training/video', params);
    }

    /**
     * 天梯四排赛-修改回放视频
     * @param videoId
     * @param params
     * @returns {Promise<*>}
     */
     static async updateLadderVideo(videoId, params){
        return api.put(`/admin/mixed_team/training/video/${videoId}`, params)
    }

    /**
     * 天梯四排赛-删除回放视频
     */
     static async deleteLadderVideo(videoId) {
        return api.delete(`/admin/mixed_team/training/video/${videoId}`);
    }
}

export default TrainingVideoRequest;
