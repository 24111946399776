import React from 'react';
import {Cascader,Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import PicturesWall from '../../../components/upload';
import moment from "moment";
import AreaSelect from '../../../components/area';
import { FloorPosition, enumToArray } from '../../../dictionary/dictionary';

const FormItem = Form.Item;
const {Option} = Select;

const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};
const uploadBox = {
    display: 'inline-block',
    border: '1px solid #eee',
    width: '60px',
    height: '60px',
}

class AddPlayerModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: null,
            address: {
                province: '',
                city: '',
            }
        };
    }


    componentWillMount() {}

    componentDidMount() {}

    renderTitle = () => {
        const {showId} = this.props;
        if (showId) {
            return (
                <div>添加职业选手主页</div>
            )
        } else return (
            <div>编辑职业选手主页</div>
        )
    }
    onChange = (value) => {
        console.log("value", value)
        console.log("vauew111", value[0], value[1])
        const {address} = this.state;
        address.province = value[0];
        address.city = value[1]
        this.setState({
            address
        })
    }
    renderAddress = () => {
        const {defaultValue} = this.props;
        //let address = defaultValue.address
        let address = []
        if (defaultValue.censusRegister) {
            address.push(defaultValue.censusRegister.province, defaultValue.censusRegister.city)
        }
        return address
    }
 
    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                const {address} = this.state
                if(values && values.backgroundUrl){
                    values.backgroundUrl=values.backgroundUrl.split("?")[0]
                }
                if(values && values.gunUrl1){
                    values.gunUrl1=values.gunUrl1.split("?")[0]
                }
                if(values && values.gunUrl2){
                    values.gunUrl2=values.gunUrl2.split("?")[0]
                }
                if(values && values.fingeringKeysUrl){
                    values.fingeringKeysUrl=values.fingeringKeysUrl.split("?")[0]
                }
                if(values && values.birthday){
                    values.birthday = moment(values.birthday).format('YYYY-MM-DD');
                }
                onSuccess && onSuccess(values,address);
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible, defaultValue} = this.props;
        // let positions = enumToArray(FloorPosition).slice(0, 4); //场上位置枚举
        let positions = enumToArray(FloorPosition) //场上位置枚举
        return (
            <Modal
                visible={visible}
                title={this.renderTitle()}
                onCancel={this.props.onClose}
                destroyOnClose={true}
                onOk={this.onSubmit}
                okText={"确认"}
                cancelText={"取消"}
                width={750}
            >
                <Form>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="用户展示ID"
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('exhibitionId', {
                                    rules: [{required: true, message: '请填写用户展示ID', pattern: /^\S+$/}],
                                    initialValue: defaultValue.exhibitionId,
                                })(
                                    <Input placeholder="请填写用户展示ID"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手类型"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('playerType', {
                                    rules: [{required: true, message: '请选择选手类型', pattern: /^\S+$/}],
                                    initialValue: defaultValue.playerType,
                                })(
                                    (
                                        <Select style={{width: '150px'}}>
                                            <Option value='COMMON'>普通</Option>
                                            <Option value='PEL'>PEL</Option>
                                            <Option value='PEN'>PEN</Option>
                                            <Option value='PEC'>PEC</Option>
                                        </Select>
                                    ),
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="选手ID名称"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('pelPlayerIdName', {
                                    rules: [{required: true, message: '请填写选手ID名称', pattern: /^\S+$/}],
                                    initialValue: defaultValue.pelPlayerIdName,
                                })(
                                    <Input placeholder="请填写选手ID名称"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手姓名"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('playerName', {
                                    rules: [{required: true, message: '请填写选手姓名', pattern: /^\S+$/}],
                                    initialValue: defaultValue.playerName,
                                })(
                                    <Input placeholder="请填写选手姓名"/>,
                                )}
                            </FormItem>
                        </Col>

                    </Row>


                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="所属战队ID"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('teamShowId', {
                                    rules: [{required: true, message: '请填写所属战队ID', pattern: /^\S+$/}],
                                    initialValue: defaultValue.teamShowId,
                                })(
                                    <Input placeholder="请填写所属战队ID"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手生日"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('birthday', {
                                    rules: [{required: true, message: '请填写选手生日'}],
                                    initialValue: defaultValue.birthday ? moment(defaultValue.birthday, "YYYY-MM-DD") : null,
                                })(
                                    <DatePicker format="YYYY-MM-DD"/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>

                        <Col span={12}>
                            <FormItem
                                label="选手身价"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('worth', {
                                    rules: [{ message: '请填写选手身价', pattern: /^\S+$/}],
                                    initialValue: defaultValue.worth,
                                })(
                                    <Input placeholder="请填写选手身价" type="number"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="合同到期"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('contractDue', {
                                    // rules: [{ message: '请选择合同到期时间'}],
                                    initialValue: defaultValue.contractDue ? moment(defaultValue.contractDue, "YYYY-MM-DD") : null,
                                })(
                                    <DatePicker format="YYYY-MM-DD" placeholder="请选择合同到期时间"/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="选手户籍"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('address', {
                                    rules: [{required: true, message: '请选择选手户籍',pattern: /^\S+$/}],
                                    initialValue: this.renderAddress(),
                                })(
                                    <AreaSelect
                                        onChange={this.onChange}
                                        placeholder="请选择选手户籍"
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="场上位置"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('floorPosition', {
                                    rules: [{required: true, message: '请选择场上位置'}],
                                    initialValue:defaultValue.floorPosition
                                })(
                                    (
                                        <Select style={{width: '150px'}}>
                                            {positions.map(item => {
                                                return (
                                                    <Option value={item.value}>{item.name}</Option>
                                                );
                                            })}
                                        </Select>
                                    ),
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="选手背景图"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('backgroundUrl', {
                                    rules: [{required: true, message: '请上传选手背景图', pattern: /^\S+$/}],
                                    initialValue: defaultValue.backgroundUrl ? defaultValue.backgroundUrl+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    <PicturesWall style={uploadBox}
                                        //图片格式限制
                                        //           width={200}
                                        //           height={200}
                                                  size={501}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="枪械搭配图1"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunUrl1', {
                                    rules: [{ message: '请上传枪械搭配图1', pattern: /^\S+$/}],
                                    initialValue: defaultValue.gunUrl1 ? defaultValue.gunUrl1+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    <PicturesWall style={uploadBox}
                                        //图片格式限制
                                        //           width={690}
                                        //           height={400}
                                                  size={251}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="枪械搭配图2"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunUrl2', {
                                    rules: [{ message: '请上传枪械搭配图2', pattern: /^\S+$/}],
                                    initialValue: defaultValue.gunUrl2 ? defaultValue.gunUrl2+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    <PicturesWall style={uploadBox}
                                        //图片格式限制
                                        //           width={690}
                                        //           height={400}
                                                  size={251}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="枪械名称1"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunName1', {
                                    rules: [{ message: '请填写枪械名称1'}],
                                    initialValue: defaultValue.gunName1
                                })(
                                    <Input placeholder="请填写枪械名称1"/>,
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="枪械名称2"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('gunName2', {
                                    rules: [{ message: '请填写枪械名称2'}],
                                    initialValue:defaultValue.gunName2
                                })(
                                    <Input placeholder="请填写枪械名称2"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormItem
                                label="指法键位图"
                                labelCol={{span: 5}}
                                wrapperCol={{span: 19}}
                            >
                                {getFieldDecorator('fingeringKeysUrl', {
                                    rules: [{ message: '请上传指法键位图', pattern: /^\S+$/}],
                                    // initialValue: defaultValue.imageUrlList || [],
                                    initialValue: defaultValue.fingeringKeysUrl ? defaultValue.fingeringKeysUrl+'?x-oss-process=image/resize,w_86' : "",
                                })(
                                    // double={true}
                                    <PicturesWall style={uploadBox} 
                                        //图片格式限制
                                        //           width={690}
                                        //           height={220}
                                                  size={251}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="指法指数"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('fingeringKeys', {
                                    rules: [{ message: '请填写指法指数'}],
                                    initialValue:defaultValue.fingeringKeys
                                })(
                                    <Input placeholder="请填写指法指数"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        );
    }
}

AddPlayerModel.propTypes = propTypes;
AddPlayerModel.defaultProps = defaultProps;
const mapStateToProps = (state => {
        return {
            ...state.ProfessionalPlayer
        }
    }
);

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(AddPlayerModel))));

