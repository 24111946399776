import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, notification, Popconfirm, Table,} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {getComments} from "../../modules/comment/action";
import {deleteComment,updateCommentStatus} from "../../modules/dynamic/action";
import CommentFilter from '../comment-management/CommentFilter'

class CommentManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,
            userId: "",
            dynamicId: "",
            commentContent: "",
            commentStatus: "",
            dateRange: "",
            isShowEditAdminModal: false,
            isShowDynamicModal: false,
            isShowCommentModal: false,
            isShowCreateAdminModal: false,
            isShowAssignAdminRoleModal: false,
        };
    }

    //分页方法
    onPageChangeEvent = (current, pageSize) => {
        let {getComments} = this.props;
        let { dynamicId, userId, commentContent, commentStatus, dateRange} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getComments(current, pageSize, dynamicId, userId, commentContent, commentStatus, dateRange);
    }

    //条件查找动态方法
    onFilterCommentEvent = async (userId, dynamicId, commentContent, commentStatus, dateRange) => {
        let {getComments} = this.props;
        console.log(dateRange)
        const {pageSize,} = this.state;
        this.setState({
            userId: userId,
            dynamicId: dynamicId,
            commentContent: commentContent,
            commentStatus: commentStatus,
            dateRange: dateRange,
            pageNo: 1
        });
        getComments(1, pageSize, dynamicId, userId, commentContent, commentStatus, dateRange);

    };

    //删除评论的方法
    onDeleteCommentEvent = async (adminId) => {
        const { deleteComment, getComments } = this.props;
        const { pageNo, pageSize } = this.state;
        console.log(adminId)
        try {
            await deleteComment(adminId);
            notification.success({ message: '屏蔽成功' });

            getComments(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    };

    //恢复评论的方法
    onCommentEvent = async (adminId) => {
        const { updateCommentStatus, getComments } = this.props;
        const { pageNo, pageSize } = this.state;
        try {
            await updateCommentStatus(adminId);
            notification.success({ message: '恢复成功' });

            getComments(pageNo, pageSize);
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const {
            comments, loading
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CommentFilter dataSource={{}} onCommit={this.onFilterCommentEvent}/>
                </Card>

                <Card title="评论列表">
                    <Table

                        columns={
                            [
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                    width: "5%",
                                },
                                {
                                    title: '评论人展示Id',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '被评论人展示Id',
                                    dataIndex: 'commentedId',
                                    key: 'commentedId',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '动态Id',
                                    dataIndex: 'dynamicId',
                                    key: 'dynamicId',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '评论内容',
                                    dataIndex: 'content',
                                    key: 'content',
                                    align: 'center',
                                    width: "20%",
                                },
                                {
                                    title: '发布时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                    width: "10%",
                                },
                                {
                                    title: '状态',
                                    dataIndex: '',
                                    key: '',
                                    align: 'center',
                                    width: "10%",
                                    render(index, data) {
                                        if (data.commentStatus == "0") {
                                            return "正常";
                                        } else {
                                            return "已屏蔽";
                                        }

                                    }
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: "15%",
                                    render: (id, text) => {
                                        return (<div>
                                            {text.commentStatus === "0" ? <Popconfirm title={`确定要屏蔽这条评论吗?`}
                                                                                      onConfirm={() => this.onDeleteCommentEvent(text.id)}
                                                                                      okText="确认"
                                                                                      cancelText="取消" style={{
                                                    backgroundColor: '#ff0000',
                                                    color: '#fff'
                                                }}>
                                                    <Button type="danger"
                                                            >{text.commentStatus === "0" ? "屏蔽" : "恢复"}</Button>
                                                </Popconfirm> :
                                                <Button type="primary" onClick={() => {
                                                    this.onCommentEvent(text.id)
                                                }}>{text.commentStatus === "0" ? "屏蔽" : "恢复"}</Button>}
                                        </div>)

                                    },
                                },
                            ]
                        }

                        // title={
                        //     this.renderTableTitle
                        // }
                        dataSource={comments.data ? comments.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: comments ? comments.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
            </div>
        )
    }
}

const mapStateToProps = (state => {
        console.log(state.Comments)
        return {
            ...state.Comments

        }

    }

);

const mapDispatchToProps = {
    getComments,
    deleteComment,
    updateCommentStatus
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(CommentManagement)));