import React from 'react';
import {Button, Card, Col, Modal, Row, message, Table} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {getGameList} from "../../modules/team/action";
import {createBrand, getBrandInfo, getBrandList, updateBrand} from "../../modules/brand/action";
import { 
    getLadderTemplateList, 
    createLadderTemplate,
    updateLadderTemplate,
    deleteLadderTemplate 
} from '../../modules/training-template/action';
import TrainingTemplateFilter from "./components/TrainingTemplateFilter";
import {operationTypeValue} from '../../modules/training/enums';
import TrainingTemplateInfoModel from "./components/TrainingTemplateInfoModel";
const {confirm} = Modal;


class TrainingTemplateLadderManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowTrainingTemplateInfoModel: false, // 模版信息
            seletionValue: null,
            brandId: '',
            page: 1,
            size: 10,
            indexVisible: false,
            visible: false
        };
    }

    componentWillMount() {
        this.initData();
    }

    initData = async ()=> {
        const {getGameList} = this.props;
        await getGameList();
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.ladderTemplateList !== this.props.ladderTemplateList) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getLadderTemplateList} = this.props;
        const {page, size} = this.state;
        this.setState({
            loading: true
        })
        getLadderTemplateList({page, size,
            brandId: this.state.brandId ? this.state.gameMap : null,
        });
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getLadderTemplateList} = this.props;
        getLadderTemplateList({
            page: current, size: pageSize,
            brandId: this.state.brandId ? this.state.gameMap : null,
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onFormSubmit = (values) => {
        const {operationType} = this.state;
        console.log(operationType)
        let param = {}
        Object.keys(values).map(item => {
            if (values[item] != undefined) {
                if (item === "signUpStartTime" || item === "signUpEndTime" || item === "gameStartTime") {
                    param[item] = values[item].format('HH:mm:00');
                    return null;
                }
                param[item] = values[item];
            }
            return null;
        });
        switch (operationType) {
            case operationTypeValue.INCREASE:
                this.onCreateTrainingTemplateEvent(param);
                break;
            case operationTypeValue.UPDATE:
                this.onUpdateTrainingTemplateEvent(param);
                break;
            default:
        }
    }
    onCreateTrainingTemplateEvent = async (params) => {
        const {isShowTrainingTemplateInfoModel} = this.state;
        const {createLadderTemplate} = this.props;
        await createLadderTemplate(params);
        await this.onGetTableData();
        message.success('新增成功');
        this.setState({isShowTrainingTemplateInfoModel: !isShowTrainingTemplateInfoModel});
    }

    onUpdateTrainingTemplateEvent = async (params) => {
        console.log(params)
        const {seletionValue, isShowTrainingTemplateInfoModel} = this.state;
        const {updateLadderTemplate} = this.props;
        await updateLadderTemplate(seletionValue.id, params);
        await this.onGetTableData();
        message.success('编辑成功');
        this.setState({isShowTrainingTemplateInfoModel: !isShowTrainingTemplateInfoModel});
    }

    //条件查找动态方法
    onFilterTrainingTemplateEvent = async (values) => {
        const {size} = this.state;
        const {getLadderTemplateList} = this.props;
        this.setState({
            brandId: values.brandId,
            page: 1,
            loading: true
        })
        await getLadderTemplateList({page: 1, size: size, brandId: values.brandId});
    }

    handleCancel = () => {
        const {visible} = this.state
        this.setState({visible: !visible});
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.onBtnClick(operationTypeValue.INCREASE, {})}>新增</Button>
            </Col>
        </Row>
    )

    onBtnClick = (type, seletionValue) => {
        this.setState({
            operationType: type,
            isShowTrainingTemplateInfoModel: true,
            seletionValue: seletionValue,
        });
    }

    renderTabPane = () => {
        const {games, ladderTemplateList
        } = this.props;
        const {
            visible, isShowTrainingTemplateInfoModel, seletionValue, page, size,loading
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <TrainingTemplateFilter onCommit={this.onFilterTrainingTemplateEvent}/>
                </Card>
                <Card>
                    <Table
                        dataSource={ladderTemplateList ? ladderTemplateList.data : []}
                        title={this.renderTableTitle}
                        columns={this.getTableColumns(games)}
                        bordered
                        scroll={{ x: 1850}}
                        pagination={{
                            total: ladderTemplateList.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    { isShowTrainingTemplateInfoModel ? <TrainingTemplateInfoModel
                        onSuccess={this.onFormSubmit}
                        trainingTemplateInfo = {seletionValue}
                        visible={isShowTrainingTemplateInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowTrainingTemplateInfoModel: false,
                            });
                        }}
                    /> : null}
                </Card>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderTabPane()}
            </div>
        );
    }

    onGetTrainingTemplateInfoEvent = async (value) => {
        const {isShowTrainingTemplateInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowTrainingTemplateInfoModel: !isShowTrainingTemplateInfoModel,
            operationType: operationTypeValue.UPDATE,
        });
    }

    onDeleteTrainingTemplateInfoConfirm = (id) =>{
        let _this = this
        confirm({
            content: '确认要删除该模版吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onDeleteTrainingTemplateInfo(id)
            },
            onCancel() {
            },

        });
    }
    onDeleteTrainingTemplateInfo = async (id) =>{
        const {deleteLadderTemplate} = this.props
        await deleteLadderTemplate(id);
        await this.onGetTableData();
        message.success('删除成功');
    }

    getTableColumns = (games) => [{
            title: '品牌名称',
            dataIndex: 'brandName',
            key: 'brandName',
            align: 'center',
            width: 100
        },  {
            title: '品牌训练赛名称',
            dataIndex: 'trainingTheme',
            align: 'center',
            key: 'trainingTheme',
            width: 150
        }, {
            title: '游戏类型',
            dataIndex: 'game',
            align: 'center',
            key: 'game',
            width: 100,
            render: (row, text) => (
                <span>{games.filter((item) => item.code == text.game).length > 0 ? games.filter((item) => item.code == text.game)[0].gameName : ""}</span>
            ),
        }, {
            title: '类型',
            dataIndex: 'type',
            align: 'center',
            key: 'type',
            width: 100,
        }, {
            title: '局数',
            dataIndex: 'gameNum',
            align: 'center',
            key: 'gameNum',
            width: 100,
            render: (row, text) => (
                <span>
                    {"BO"+ text.gameNum}
                </span>
            ),
        }, {
            title: '最大报名人数',
            dataIndex: 'personalTotal',
            key: 'personalTotal',
            align: 'center',
            width: 100
        },{
            title: '报名开始时间',
            dataIndex: 'signUpStartTime',
            align: 'center',
            key: 'signUpStartTime',
            width: 150
        }, {
            title: '报名结束时间',
            dataIndex: 'signUpEndTime',
            align: 'center',
            key: 'signUpEndTime',
            width: 150
        }, {
            title: '比赛开始时间',
            dataIndex: 'gameStartTime',
            width: 200,
            align: 'center',
            key: 'gameStartTime',
        }, {
            title: '房间号',
            dataIndex: 'roomNo',
            align: 'center',
            key: 'roomNo',
            width: 100
        }, {
            title: '联系方式',
            dataIndex: 'communication',
            align: 'center',
            key: 'communication',
            width: 200
        }, {
            title: '比赛规则',
            dataIndex: 'gameRule',
            align: 'center',
            key: 'gameRule',
            width: 200,
            render: (row, text) => (
                <span>
                    {text.gameRule ? text.gameRule.length < 20 ? text.gameRule :  text.gameRule.substring(0, 20)+"......" : ""}
                </span>
            ),
        }, {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            fixed: 'right',
            render: (row, text) => {
                return (
                    <div>
                        <Button style={{marginLeft: "10px", marginBottom: "10px"}} type="primary" onClick={() => this.onGetTrainingTemplateInfoEvent(text)}
                                color="blue">编辑</Button>

                        <Button style={{marginLeft: "10px"}} type="danger" onClick={() => this.onDeleteTrainingTemplateInfoConfirm(text.id)}
                                >删除</Button>
                    </div>
                )
            },
        }
    ]
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
        ...state.Brand,
        ...state.viewTraining,
        pageButtons: state.authentication.pageButtons,
        admins: state.admin.admins,
        ...state.TrainingTemplate
    }
};

const mapDispatchToProps = ({
    getGameList,
    getBrandInfo,
    getBrandList,
    createBrand,
    updateBrand,
    getLadderTemplateList,
    createLadderTemplate,
    updateLadderTemplate,
    deleteLadderTemplate
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(TrainingTemplateLadderManagement));
