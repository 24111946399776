import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Col, Modal, notification, Popconfirm, Row, Table, Tabs} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {addRecommendUser, getUser, getRecommendUsers, unBanUserClaim, updateRecommendUser, updateUserState,deleteRecommendUser} from '../../modules/user/action';
import {getAccount, operateAccountCoin} from 'modules/account/action';
import {genderType, stateType} from '../../modules/user/enums';
import LangBiRechargeForm from './LangBiRechargeForm';
import UserDetail from './UserDetail';
import SearchForm from './SearchForm';
import utils from 'utils/utils';
import whetherButtonPermission from '../../modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';
import BanUserModel from "./BanUserModel";
import AddUserModel from "./AddUserModel";
import UpdateUserModel from "./UpdateUserModel";
import UpdateCretificationModal from "./UpdateCretificationModal";
import {updateUserCertification} from "../../modules/certification/action"
import CheckVipModel from "./CheckVipModel";


const {confirm} = Modal;

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 10,
            isShowLangbiRechargeModal: false,
            isShowUserDetailModal: false,
            isShowBanUserModel: false,
            isShowAddUserModel: false,
            currentUserId: null,
            exhibitionId: null,
            isShowDetail: false,
            isShowCertificationModel :false,
            userId: "",
            telephone: "",
            username: "",
            gender : "",
            imageUrl : "",
            city : "",
            province : "",
            selectValues: null,
            search: {
                exhibitionId: null,
                username: null,
                telephone: null,
                state: null,
                registerBeginTime: null,
                registerEndTime: null,
                ytc: null
            }
        };
    }

    renderTableTitle = () => (
        <Row type="flex" justify="end">
            <Col>
                <Button type="primary" onClick={() => this.setState({
                    isShowAddUserModel: true,
                })}>新增</Button>
            </Col>
        </Row>
    )


    onDeleteRecommendUserEvent = async (adminId) => {
        const { deleteRecommendUser, getCircles } = this.props;
        const { page, size } = this.state;
        try {
            await deleteRecommendUser(adminId);
            notification.success({ message: '删除成功' });

            this.onRefreshUsers()
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const {
            users, info, loading, total, account, pageButtons
        } = this.props;
        const {
            isShowLangbiRechargeModal, isShowUserDetailModal, page, size, exhibitionId, isShowBanUserModel, isShowAddUserModel, isShowDetail,isShowCertificationModel,selectValues
        } = this.state;
        console.log(this.props)
        return (
            <div>
                <Card style={{marginBottom: '30px'}}><SearchForm onSearch={this.onSearch}/></Card>
                <Card>
                    <Table

                        title={this.renderTableTitle}
                        columns={
                            [
                                {
                                    title: '用户ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                    align: 'center',
                                },
                                {
                                    title: '用户展示ID',
                                    dataIndex: 'exhibitionId',
                                    key: 'exhibitionId',
                                    align: 'center',
                                },
                                {
                                    title: '用户名',
                                    dataIndex: 'username',
                                    key: 'username',
                                    align: 'center',
                                },
                                {
                                    title: '头像',
                                    dataIndex: 'headImgUrl',
                                    key: 'headImgUrl',
                                    align: 'center',
                                    render: (text, {id}) => (
                                        <img src={text ? text + '?x-oss-process=image/resize,w_32' : '/avatar.png'}
                                             alt={""}
                                             style={{width: '2rem', height: '2rem', borderRadius: '50%'}}/>
                                    ),
                                },
                                // {
                                //     title: 'YTC',
                                //     dataIndex: 'ytc',
                                //     key: 'ytc',
                                //     align: 'center',
                                //     render: (text, {id}) => (
                                //         <CheckVipModel userId={id}/>
                                //     ),
                                // },
                                // {
                                //     title: '性别',
                                //     dataIndex: 'gender',
                                //     key: 'gender',
                                //     align: 'center',
                                //     render: (text) => (text ? genderType[text].label : null)
                                // },
                                {
                                    title: '推荐优先级',
                                    dataIndex: 'recommendationWeight',
                                    key: 'recommendationWeight',
                                    align: 'center',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                // {
                                //     title: '手机号',
                                //     dataIndex: 'telephone',
                                //     key: 'telephone',
                                //     align: 'center',
                                // },
                                // {
                                //     title: '状态',
                                //     dataIndex: 'state',
                                //     key: 'state',
                                //     align: 'center',
                                //     render: (text) => (
                                //         text ? stateType[text].label : null
                                //     )
                                // },
                                // {
                                //     title: '解禁时间',
                                //     dataIndex: 'releaseTime',
                                //     key: 'releaseTime',
                                //     align: 'center',
                                // },
                                // {
                                //     title: '封禁剩余天数',
                                //     dataIndex: 'days',
                                //     key: 'days',
                                //     align: 'center',
                                //     render: (text) => (
                                //         text == -1 ? "永久" : `${text}天`
                                //     )
                                // },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    width: '350px',
                                    render: (row, text,{id}) => (
                                        <div>
                                            {

                                                text.state == stateType.BAN.value ?
                                                    whetherButtonPermission(buttonType.UN_BAN_USER.value, pageButtons) &&
                                                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                                                            onClick={() => this.confirmUnBanUser(text.id)}
                                                            color="blue">{buttonType.UN_BAN_USER.label}</Button> :
                                                    text.state == stateType.NORMAL.value ?
                                                        whetherButtonPermission(buttonType.BAN_USER.value, pageButtons) &&
                                                        <Button type="danger" style={{marginLeft: 10, marginBottom: 10}}
                                                                onClick={() => this.onBanUserStatusEvent(text.id)}>{buttonType.BAN_USER.label}</Button> : null
                                            }

                                            {/*&nbsp;*/}
                                            {/*{*/}
                                            {/*    whetherButtonPermission(buttonType.OPERATE_COIN.value, pageButtons) &&*/}
                                            {/*    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}*/}
                                            {/*            onClick={() => this.onOpenLangbiRechargeModal(text.id, text.exhibitionId)}*/}
                                            {/*            color="blue">{buttonType.OPERATE_COIN.label}</Button>}*/}
                                            {/*&nbsp;*/}
                                            {/*<Button type="primary" style={{marginLeft: 10, marginBottom: 10}}*/}
                                            {/*        onClick={() => this.onOpenUserDetailModal(text.id)}>详情</Button>*/}
                                            &nbsp;
                                            <Button type="primary" style={{marginLeft: 10,marginRight:10, marginBottom: 10}}
                                                    onClick={() => this.onOpenUpdateModal(text)}>修改</Button>

                                            <Popconfirm title={`确定要删除这条推荐吗?`}
                                                        onConfirm={() => this.onDeleteRecommendUserEvent(text.id)}
                                                        okText="确认" onCancel={this.onEvent} cancelText="取消" style={{ backgroundColor: '#ff0000', color: '#fff'}}>
                                                <Button type="danger" >删除</Button>
                                            </Popconfirm>
                                            {/*&nbsp;*/}
                                            {/*<Button type="primary" onClick={() => this.clickCertification(text.id)}>编辑认证资料</Button>*/}
                                            &nbsp;
                                        </div>
                                    ),
                                },
                            ]
                        }

                        dataSource={users}
                        bordered
                        loading={loading}
                        pagination={{
                            total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}></Table>
                    <Modal title="操作浪币" visible={isShowLangbiRechargeModal} footer={null} destroyOnClose
                           onCancel={this.onCloseLangbiRechargeModal}>
                        <LangBiRechargeForm exhibitionId={exhibitionId} onSubmitEvent={this.onLangBiRecharge}/>
                    </Modal>
                    <Modal title="用户详情" {...info} visible={isShowUserDetailModal} footer={null} destroyOnClose
                           onCancel={this.onCloseUserDetailModal} width={1024}>
                        <UserDetail info={info} account={account}/>
                    </Modal>

                    {isShowBanUserModel ? <BanUserModel
                        onSuccess={this.onBanUser}
                        visible={isShowBanUserModel}
                        onClose={() => {
                            this.setState({
                                isShowBanUserModel: false,
                            });
                        }}
                    /> : null}

                    {isShowAddUserModel ? <AddUserModel
                        onSuccess={this.onAddUser}
                        visible={isShowAddUserModel}
                        onClose={() => {
                            this.setState({
                                isShowAddUserModel: false,
                            });
                        }}
                    /> : null}

                    {isShowDetail ? <UpdateUserModel
                        {...this.state}
                        onSuccess={this.onUpdateUser}
                        visible={isShowDetail}
                        value={selectValues}
                        onClose={() => {
                            this.setState({
                                isShowDetail: false,
                            });
                        }}
                    /> : null}

                    <Modal title="修改认证资料" visible={isShowCertificationModel} footer={null} destroyOnClose
                           onCancel={this.onCloseAccountModal}>
                        <UpdateCretificationModal {...this.props}{...this.state} onSubmit={this.onOperationCertification}
                        />
                    </Modal>
                </Card>
            </div>
        );
    }

    // --------页面事件开始---------

    componentDidMount() {
        this.onRefreshUsers()
    }

    /**
     * 点击查询事件
     */
    onSearch = ({exhibitionId, username, telephone, state, registerTime, ytc}) => {
        const page = 1;
        const size = 10;
        const {getRecommendUsers} = this.props;
        let registerBeginTime, registerEndTime = null;
        if (registerTime) {
            registerBeginTime = utils.formatMomentToStr(registerTime[0]);
            registerEndTime = utils.formatMomentToStr(registerTime[1]);
        }
        this.setState({
            search: {
                exhibitionId,
                username,
            },
            page,
            size
        })
        getRecommendUsers(exhibitionId, username, page, size);
    }

    /**
     * 解绑用户三方登录
     * @param userId
     * @param type
     * @returns {Promise<void>}
     */
    unBanUserClaim = async (userId, loginType) => {
        let {unBanUserClaim} = this.props;

        await unBanUserClaim(userId, loginType);
        this.onRefreshUsers()
    }

    onCloseAccountModal = () => {
        this.setState({isShowCertificationModel : false});
    }

    /**
     * 用户详情模态窗打开事件监听
     * @param userId
     */
    onOpenUserDetailModal = async (userId) => {
        let {getUser, getAccount} = this.props;
        await Promise.all([getUser(userId), getAccount(userId)]);
        this.setState({
            isShowUserDetailModal: true,
            currentUserId: userId
        });
    }

    clickCertification = (id) => {


        this.setState({
            userId : id,
            isShowCertificationModel: true
        })
    }

    /**
     * 修改用户信息
     * @param userId
     */
    onOpenUpdateModal = async (values) => {
        console.log(values)
        this.setState({
            selectValues: values,
            isShowDetail: true,
        });
    }

    onOperationCertification = async (id,params) => {
        const {userId} = this.state;
        const {updateUserCertification} = this.props;

        await updateUserCertification(id, params);
        notification['success']({
            message: '修改成功',
            description: '',
        });
        this.setState({isShowCertificationModel: false})
        this.onRefreshUsers()

    }

    /**
     * 用户详情模态窗关闭事件监听
     */
    onCloseUserDetailModal = () => {
        this.setState({
            isShowUserDetailModal: false,
            currentUserId: null
        });
    }

    /**
     * 浪币充值模态窗打开事件监听
     * @param userId
     * @param exhibitionId
     */
    onOpenLangbiRechargeModal = (userId, exhibitionId) => {
        this.setState({
            currentUserId: userId,
            exhibitionId,
            isShowLangbiRechargeModal: true
        });
    }

    /**
     * 浪币充值模态窗关闭事件监听
     */
    onCloseLangbiRechargeModal = () => {
        this.setState({
            isShowLangbiRechargeModal: false,
            exhibitionId: null,
            currentUserId: null
        })
    }

    /**
     * 封禁用户事件监听
     * @param userId
     */
    onBanUser = async (params) => {
        params["state"] = stateType.BAN.value
        const {updateUserState} = this.props;
        const {currentUserId, isShowBanUserModel} = this.state;
        await updateUserState(currentUserId, params);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({
            isShowBanUserModel: !isShowBanUserModel,
        })
        this.onRefreshUsers()
    }

    /**
     * 添加用户事件监听
     * @param userId
     */
    onAddUser = async (params) => {
        const {addRecommendUser} = this.props;
        const {isShowAddUserModel} = this.state;
        await addRecommendUser(params);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({
            isShowAddUserModel: !isShowAddUserModel,
        })
        this.onRefreshUsers()
    }

    /**
     * 更新用户事件
     * @param userId
     */
    onUpdateUser = async (params) => {
        const {updateRecommendUser} = this.props;
        const {selectValues, isShowDetail} = this.state;
        await updateRecommendUser(selectValues.id, Object.assign(params));
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({
            isShowDetail: false,
        })
        this.onRefreshUsers()
    }


    onBanUserStatusEvent = async (id) => {
        const {isShowBanUserModel} = this.state;
        this.setState({
            currentUserId: id,
            isShowBanUserModel: !isShowBanUserModel,
        });
    }

    confirmUnBanUser = (id) => {
        let _this = this;
        confirm({
            content: '确认解除封禁吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUnBanUser(id)
            },
            onCancel() {
            },

        });
    }
    /**
     * 解封用户事件监听
     * @param userId
     */
    onUnBanUser = async (userId) => {
        const {updateUserState} = this.props;
        let params = {}
        params["state"] = stateType.NORMAL.value
        await updateUserState(userId, params);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.onRefreshUsers();
    }

    /**
     * 刷新用户列表事件
     */
    onRefreshUsers = () => {
        const {exhibitionId, username} = this.state.search;
        const {page, size} = this.state
        const {getRecommendUsers} = this.props;
        getRecommendUsers(exhibitionId, username, page, size);
    }

    /**
     * 浪币充值事件监听
     */
    onLangBiRecharge = async ({coinAmount, exhibitionId, reason, type}) => {
        const {operateAccountCoin} = this.props;
        const {currentUserId} = this.state;
        await operateAccountCoin(currentUserId, type, coinAmount, reason);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.onCloseLangbiRechargeModal();
    }

    /**
     * 点击分页事件监听
     * @param current
     * @param pageSize
     */
    onPageChangeEvent = (current, pageSize) => {
        let {getRecommendUsers} = this.props;
        let {exhibitionId, username} = this.state.search;
        this.setState({
            page: current,
            size: pageSize,
        });
        getRecommendUsers(exhibitionId, username, current, pageSize);
    }


    // --------页面事件结束---------
}

const mapStateToProps = ({
                             user: {
                                 users, userInfo, usersLoading, usersTotal,
                             },
                             account: {
                                 accountInfo
                             },
                             authentication: {pageButtons}
                         }) => ({
    users,
    loading: usersLoading,
    total: usersTotal,
    info: userInfo,
    account: accountInfo,
    pageButtons
});

const mapDispatchToProps = {
    getRecommendUsers, updateUserState, operateAccountCoin, getUser, getAccount, unBanUserClaim, addRecommendUser, updateRecommendUser,updateUserCertification,deleteRecommendUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(UserManagement)));
