import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Dropdown,
    Form,
    Icon,
    InputNumber,
    Menu,
    message,
    Modal,
    Popconfirm,
    Row,
    Table
} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {
    getLadderJoinPlayerList,
    addLadderJoinPlayer,
    updateLadderPlayerSubstitute,
    updateLadderPlayerOrder,
    updateLadderPlayerAssign,
    deleteLadderPlayerSignUp
} from "../../modules/ladder-training/action";
import { getLadderBrandList } from "../../modules/brand/action";
import TrainingJoinFilter from "./components/TrainingJoinFilter";
import "./index.scss"
import TrainingJoinModal from "./components/TrainingJoinModal";
import TrainingPushModal from "./components/TrainingPushModal";
import { FloorPosition } from "../../dictionary/dictionary";

const {confirm} = Modal;

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TrainingJoinManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 25,
            exhibitionId: '',
            username: '',
            memberType: '',
            param: {},
            modalVisible: false,
            teamIdArr: [],
            pushModalVisible: false,
            currentCheckItem: [],
            disabled: false,
            indexVisible: false,
            positionNum: '', //坑位号
            playerOrderVisible: false,
            orderNum: '', //次序
        };
    }

    componentWillMount() {
        this.requestPlayer();
        this.getLadderBrandList();
    }

    onSubmit = (values) => {
        let param = {}
        let {page, size} = this.state;
        Object.keys(values).map(item => {
            if (values[item]) {
                param[item] = values[item];
            }
            return null;
        });
        this.setState({param, loading: true})
        param["page"] = page;
        param["size"] = size;
        this.requestPlayer(param);
        this.getLadderBrandList();
    }

    requestPlayer = (param) => {
        let {page, size} = this.state;
        let {getLadderJoinPlayerList} = this.props;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        console.log(trainingId)
        console.log(id)
        if (param) {
            getLadderJoinPlayerList({trainingId: id, page, size, ...param})
        } else {
            getLadderJoinPlayerList({trainingId: id, page, size})
        }
    }

    getLadderBrandList = () => {
        let {getLadderBrandList} = this.props;
        getLadderBrandList({page: 1, size: 100000});
    }

    onPageChangeEvent = async (current, pageSize) => {
        this.state.page = current;
        this.requestPlayer();
        this.setState({
            page: current,
            size: pageSize,
        });
    }

    commitJoinPlayer = (values) => {
        let {addLadderJoinPlayer} = this.props;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        let userIds = values.userIds.replace(/，/g, ',').split(",");
        addLadderJoinPlayer({trainingId: id, userIds})
            .then(res => {
                message.success("操作成功")
                this.setState({modalVisible: false})
                this.requestPlayer()
            })
            .catch(e => {
                console.log(e)
            })
    }

    // 将选手设为替补（踢出当前队伍）
    updatePlayerSubstituteConfirm = (params) => {
        let _this = this;

        confirm({
            title: '提示',
            content: '确认要将该选手踢出当前队伍吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.updatePlayerSubstitute(params);
            },
            onCancel() {}
        });
    }
    
    updatePlayerSubstitute = (params) => {
        const { updateLadderPlayerSubstitute } = this.props;
        let {param} = this.state;
        this.setState({
            disabled: true
        });

        updateLadderPlayerSubstitute(params).then(res => {
            message.success("操作成功")
            this.requestPlayer(param);
            this.setState({
                currentCheckItem: [],
                disabled: false
            });
        }).catch(err => {
            this.setState({
                disabled: false
            });
        });
    }

    // 修改选手坑位号（分配队伍）
    updateLadderPlayerAssign = (params) => {
        const { updateLadderPlayerAssign } = this.props;
        let { positionNum } = this.state;

        if(!positionNum) {
            message.error("号位不能为空");
            return;
        }

        this.setState({
            disabled: true
        });
        params['position'] = parseInt(positionNum);

        updateLadderPlayerAssign(params).then(res => {
            message.success("分配成功");
            this.requestPlayer();
            this.setState({
                positionNum: '',
                disabled: false
            });
        }).catch(err => {
            this.setState({
                disabled: false
            });
        });
    }

    // 修改选手次序值
    updateLadderPlayerOrder = (params) => {
        const { updateLadderPlayerOrder } = this.props;
        let { orderNum } = this.state;

        if(!orderNum) {
            message.error("次序不能为空");
            return;
        }

        this.setState({
            disabled: true
        });
        params['playerOrder'] = parseInt(orderNum);

        updateLadderPlayerOrder(params).then(res => {
            message.success("修改成功");
            this.requestPlayer();
            this.setState({
                orderNum: '',
                disabled: false
            });
        }).catch(err => {
            this.setState({
                disabled: false
            });
        });
    }

    // 移除报名选手
    confirm = (signUpId, type) => {
        let _this = this;
        let {currentCheckItem} = this.state;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        if (type === 0) {
            confirm({
                title: '提示',
                content: '确认要移除该选手吗?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    _this.deleteLadderPlayerSignUp([signUpId])
                },
                onCancel() {
                }
            });
        } else {
            confirm({
                content: `确认批量删除${currentCheckItem.length}条记录吗?`,
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    let signUpIds = currentCheckItem.map(e => e + "_" + id);
                    _this.deleteLadderPlayerSignUp(signUpIds);
                },
                onCancel() {
                },

            });
        }
    }

    deleteLadderPlayerSignUp = async (signUpIds) => {
        let {deleteLadderPlayerSignUp} = this.props;
        let {trainingId} = this.props.match.params;
        let id = window.atob(trainingId);
        this.setState({
            disabled: true
        });
        deleteLadderPlayerSignUp({signUpIds: signUpIds, trainingId: id})
            .then(res => {
                message.success("操作成功")
                let {param} = this.state;
                this.setState({
                    currentCheckItem: []
                })
                this.requestPlayer(param)
                this.setState({
                    disabled: false
                });
            })
            .catch(e => {
                this.setState({
                    disabled: false
                });
            });
    }

    renderTableTitle = () => {
        let {trainingId, trainingName, brandId, personalTotal} = this.props.match.params;
        let {ladderJoinPlayer, ladderBrands} = this.props;
        let data = ladderBrands.data && ladderBrands.data.filter(item => item.id == window.atob(brandId));
        let brandName = data && data.length > 0 ? data[0].name : ""
        return (
            <Row>
                <Col span={18}>
                    <div style={{width: "100%", height: "40px", lineHeight: "40px", marginTop: "10px"}}>
                        赛事名：<span className="titleValue">{trainingName}</span>
                        赛事ID：<span className="titleValue">{window.atob(trainingId)}</span>
                        赛事类型：<span className="titleValue">{brandName}</span>
                        已参与人数：<span className="titleValue">{ladderJoinPlayer.total}</span>
                        {personalTotal < ladderJoinPlayer.total ? <span className={"titleValue"}>提示:  参赛人数超出预定报名数</span> : null }
                    </div>
                </Col>
                <Col span={6}>
                    <div style={{
                        width: "100%",
                        height: "40px",
                        lineHeight: "40px",
                        marginTop: "10px",
                        textAlign: "right"
                    }}>
                        <Button type="primary" htmlType="submit" style={{marginRight: "30px"}} onClick={() => {
                            this.setState({modalVisible: true})
                        }}>
                            添加新选手
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={() => {
                            // console.log(this.props)
                            // let {trainingId} = this.props.match.params;
                            // let {getLadderJoinPlayerList} = this.props;
                            // getLadderJoinPlayerList({trainingId: window.atob(trainingId),state:"PASS", size:10000, page:1});
                            this.setState({pushModalVisible: true})
                        }}>
                            推送
                        </Button>
                    </div>
                </Col>
            </Row>

        )
    }

    render() {
        let {ladderJoinPlayer} = this.props;
        const {page, size, modalVisible, pushModalVisible} = this.state;
        return (
            <div>
                <Card style={{marginBottom: '30px'}}>
                    <TrainingJoinFilter onCommit={this.onSubmit}/>
                </Card>

                <Card>
                    <Table
                        title={this.renderTableTitle}
                        dataSource={ladderJoinPlayer.data ? ladderJoinPlayer.data : []}
                        columns={this.getTableColumns()}
                        bordered
                        pagination={{
                            total: ladderJoinPlayer.total ? ladderJoinPlayer.total : 0,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                        }}
                    />
                </Card>
                {modalVisible ?
                <TrainingJoinModal {...this.props} visible={modalVisible} onSuccess={this.commitJoinPlayer} onClose={() => {
                    this.setState({modalVisible: false})
                }}/> : null}

                {pushModalVisible ? 
                <TrainingPushModal 
                    {...this.props}
                    visible={pushModalVisible} 
                    onClose={() => {
                        this.setState({pushModalVisible: false})
                    }}
                /> : null}
            </div>
        );
    }

    getTableColumns = () => {
        let date = this.props.match.params.gameStartDate;
        let {trainingId, brandId} = this.props.match.params;
        let id = window.atob(trainingId);
        let {currentCheckItem, disabled, indexVisible, playerOrderVisible} = this.state;
        let {ladderJoinPlayer, ladderBrands} = this.props;
        let data = ladderBrands.data && ladderBrands.data.filter(item => item.id == window.atob(brandId));
        let brandName = data && data.length > 0 ? data[0].name : "";
        return (
            [{
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
                key: 'id',
            }, {
                title: '用户ID/名',
                dataIndex: 'userName',
                align: 'center',
                key: 'userName',
                render: (row, text) => {
                    return text.exhibitionId + "/" + text.userName
                }
            },{
                title: '次序',
                dataIndex: 'playerOrder',
                align: 'center',
                key: 'playerOrder',
                render: (row, index) => {
                    return (
                        <div>
                            {index.playerOrder && <Popconfirm
                                title={
                                    playerOrderVisible ? 
                                    <div>
                                        <span style={{display: 'block', marginBottom: 10, color: "red"}}>请输入次序：</span>
                                        <InputNumber
                                            style={{width: 260}} 
                                            placeholder={"请输入次序"}
                                            onChange={(e) => {
                                                this.setState({
                                                    orderNum: e
                                                })
                                            }}
                                        />
                                    </div> : null
                                }
                                okType="primary"
                                icon={null}
                                onConfirm={() => {
                                    this.updateLadderPlayerOrder({teamId: index.teamId, userExhibitionId: index.exhibitionId});
                                    this.setState({playerOrderVisible: false})
                                }}
                                onCancel={() => {
                                    this.setState({playerOrderVisible: false})
                                }}
                                okText="提交"
                                cancelText="取消"
                            >
                                <Button disabled={disabled}
                                    style={{marginLeft: 10, marginBottom: 10}}
                                    type="dashed" 
                                    onClick={() => {
                                        this.setState({playerOrderVisible: true});
                                    }}
                                >{index.playerOrder}</Button>
                            </Popconfirm>}
                        </div>
                    );
                }
            }, {
                title: '比赛日期',
                dataIndex: 'date',
                align: 'center',
                key: 'date',
                render: (row, text) => {
                    return window.atob(date)
                }
            }, {
                title: '类型',
                dataIndex: 'state',
                align: 'center',
                key: 'state',
                render: (row, text) => {
                    if (row === "UNAUDITED") {
                        return "未审核"
                    } else if (row === "PASS") {
                        return "通过"
                    } else {
                        return "替补"
                    }
                }
            }, {
                title: '参与时间',
                dataIndex: 'signUpTime',
                align: 'center',
                key: 'signUpTime'
            }, {
                title: '操作时间',
                dataIndex: 'updateTime',
                align: 'center',
                key: 'updateTime'
            }, {
                title: '场上位置',
                dataIndex: 'floorPosition',
                align: 'center',
                key: 'floorPosition',
                render: (row, text) => {
                    return <div>{FloorPosition[text.floorPosition] || ''}</div>
                }
            }, {
                title: 'Rank分',
                dataIndex: 'rank',
                align: 'center',
                key: 'rank'
            }, {
                title: '位置',
                dataIndex: 'position',
                align: 'center',
                key: 'position',
                render: (row, text) => {
                    return <div>{text.position ? text.position + '号位' : ''}</div>
                }
            },
            {
                title: '操作',
                key: '',
                dataIndex: "",
                align: 'center',
                width: 460,
                render: (text, index) => {                   
                    return (
                        <div>
                            {index.certificationStatus && <Popconfirm
                                title={
                                    indexVisible ? 
                                    <div>
                                        <span style={{display: 'block', marginBottom: 10, color: "red"}}>请输入号位：</span>
                                        <InputNumber
                                            style={{width: 260}} 
                                            placeholder={"请输入号位"}
                                            onChange={(e) => {
                                                this.setState({
                                                    positionNum: e
                                                })
                                            }}
                                        />
                                    </div> : null
                                }
                                okType="primary"
                                icon={null}
                                onConfirm={() => {
                                    this.updateLadderPlayerAssign({trainingId: id, userExhibitionId: index.exhibitionId});
                                    this.setState({indexVisible: false})
                                }}
                                onCancel={() => {
                                    this.setState({indexVisible: false})
                                }}
                                okText="提交"
                                cancelText="取消"
                            >
                                <Button disabled={disabled}
                                    style={{marginLeft: 10, marginBottom: 10}}
                                    type="primary" 
                                    onClick={() => {
                                        this.setState({indexVisible: true});
                                    }}
                                >分配</Button>
                            </Popconfirm>}

                            {/* 选手没有认证信息 */}
                            {!index.certificationStatus && 
                            <Button disabled={true}
                                style={{marginLeft: 10, marginBottom: 10}}
                                type="primary"
                            >
                                {'未认证' + (brandName || '')}
                            </Button>}

                            {index.position !== null && 
                            <Button disabled={index.state === "FAIL" || disabled}
                                    style={{marginLeft: 10, marginBottom: 10}}
                                    type={"dashed"} onClick={() => {
                                this.updatePlayerSubstituteConfirm({trainingId: id, userExhibitionId: index.exhibitionId});
                            }}>替补</Button>}

                            <Button style={{marginLeft: 10, marginBottom: 10}}
                                disabled={ disabled} type="danger"
                                onClick={() => {
                                    this.confirm(index.id, 0);
                            }}>移除</Button>
                        </div>
                    );

                }
            }]
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.LadderTraining,
        ...state.Brand
    }
};
const mapDispatchToProps = ({
    deleteLadderPlayerSignUp,
    getLadderJoinPlayerList,
    addLadderJoinPlayer,
    getLadderBrandList,
    updateLadderPlayerSubstitute,
    updateLadderPlayerOrder,
    updateLadderPlayerAssign,
});
TrainingJoinManagement.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TrainingJoinManagement)));
