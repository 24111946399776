import createReducer from "utils/createReducer";
import {
  CREATE_TRAINING,
  DELETE_TRAINING,
  EDIT_TRAINING,
  GET_ADDTEAM,
  GET_BRAND,
  GET_GRADE,
  GET_JOIN_TEAM,
  GET_PERSONAL_GRADE,
  GET_PERSONAL_GRADE_DETAIL,
  GET_TRAINING,
  UPDATE_GRADE,
  GET_GAME_MAPS,
  GET_VIDEO_CONFIG,
  QUERY_VIDEO_LIST,
  GET_TRAINING_TEMPLATE,
  GET_TRAINING_TEMPLATE_DICTIONARIES,
  GET_TRAINING_TEMPLATE_LIST,
  GET_PEL_LEAGUE_DIC,
  GET_PEL_TEAM_INTEGRAL,
  GET_PEL_TEAM_WEEKLY_INTEGRAL,
  GET_SORT_FIELD_DIC,
  GET_PEL_PLAYER_RANK,
  GET_PEL_TEAM_RANK,
  GET_TRAINING_TEAM_RANK,
  GET_TRAINING_PLAYER_RANK,
  GET_RANK_BASE_DATA_CONFIG,
  GET_RANK_DATA_CONFIG,
  GET_RANK_DATA_CONFIG_BRANDS,
  DELETE_RANK_DATA_CONFIG,

  GET_PERSON_TRAINING,
  CREATE_PERSON_TRAINING,
  EDIT_PERSON_TRAINING,
  DELETE_PERSON_TRAINING,
  GET_JOIN_PLAYER,
  GET_JOIN_STATE_PLAYER,
  GET_PERSON_TRAINING_GRADE,
  GET_PERSON_TRAINING_GRADE_DETAIL,
  QUERY_PERSON_VIDEO_LIST,
  GET_PERSON_BRAND,
  GET_PERSON_TRAINING_TEMPLATE_LIST,
  GET_PERSON_TRAINING_TEMPLATE_DICTIONARIES,
  GET_PERSON_TRAINING_TEMPLATE,

  GET_PERSONAL_RANK_DATA_CONFIG_BRANDS,
  GET_PERSONAL_RANK_BASE_DATA_CONFIG,
  GET_PERSONAL_RANK_DATA_CONFIG,
  DELETE_PERSONAL_RANK_DATA_CONFIG,

  GET_PERSONAL_SORT_FIELD_DIC,
  GET_PERSONAL_TRAINING_PLAYER_RANK,

  GET_FOURSOME_BRAND,

  GET_FOURSOME_RANK_DATA_CONFIG_BRANDS,
  GET_FOURSOME_RANK_BASE_DATA_CONFIG,
  GET_FOURSOME_RANK_DATA_CONFIG,
  DELETE_FOURSOME_RANK_DATA_CONFIG,

  GET_FOURSOME_SORT_FIELD_DIC,
  GET_FOURSOME_TRAINING_PLAYER_RANK,

} from "./actionTypes";

const defaultState = () => ({
  trainingData: {},
  brandData: {},
  editStatus: {},
  grade: {},
  editGradeStatus: {},
  teamInfo: {},
  joinTeam: {},
  personalGrade: {},
  gameMaps: null,
  videoDataList: [],
  videoData: {},
  trainingTemplate: {},
  trainingTemplateDictionaries: [],
  trainingTemplateList: [],
  leagueDicList: null,
  pelTeamRankIntegralList: null,
  pelTeamRankWeeklyIntegralList: null,
  sortFieldDicList: null,
  pelPlayerRankList: null,
  trainingTeamRankList: null,
  trainingPlayerRankList: null,
  rankBaseDataConfig: null,
  rankDataConfig: null,
  rankDataConfigBrands: null,

  personTrainingData: {},
  personEditStatus: {},
  playerInfo: {},
  statePlayerInfo: {},
  personTrainGrade: {},
  personVideoData: {},
  personBrandData: {},
  personTemplateList: [],
  personTemplateDictionaries: [],
  personTemplate: {},

//   trainingPersonalRankList:null,
  rankPersonalBaseDataConfig:null,
  rankPersonalDataConfig:null,
  rankPersonalDataConfigBrands:null,

  fourSomeBrandData: {},

  rankFourSomeBaseDataConfig:null,
  rankFourSomeDataConfig:null,
  rankFourSomeDataConfigBrands:null,
  
});

const onGetTrainingDataSuccess = (state, { payload }) => ({
  ...state,
  trainingData: payload,
});

const onGetBrandDataSuccess = (state, { payload }) => ({
  ...state,
  brandData: payload,
});

const onEditTrainingSuccess = (state, { payload }) => ({
  ...state,
  editStatus: Boolean(true),
});

const onCreateTrainingSuccess = (state, { payload }) => ({
  ...state,
  editStatus: Boolean(true),
});

const onDeleteTrainingSuccess = (state, { payload }) => ({
  ...state,
  editStatus: Boolean(true),
});

const onGetGradeSuccess = (state, { payload }) => ({
  ...state,
  grade: payload,
});

const onUpdateGradeSuccess = (state, { payload }) => ({
  ...state,
  editGradeStatus: Boolean(true),
});

const onGetAddTeam = (state, { payload }) => ({
  ...state,
  teamInfo: payload,
});

const onGetJoinTeamSuccess = (state, { payload }) => ({
  ...state,
  joinTeam: payload,
});

const onGetPersonalGradeSuccess = (state, { payload }) => ({
  ...state,
  personalGrade: Object.assign({}, payload),
});

const onGetGameMapsSuccess = (state, { payload }) => ({
  ...state,
  gameMaps: payload,
});

const onGetVideoConfigSuccess = (state, { payload }) => ({
  ...state,
  videoDataList: payload,
});

const onQueryVideoListSuccess = (state, { payload }) => ({
  ...state,
  videoData: payload,
});

const onGetPelLeagueDictionariesSuccess = (state, { payload }) => ({
  ...state,
  leagueDicList: payload,
});

const onGetPersonalGradeDetailSuccess = (state, { payload }) => ({
  ...state,
  personalGrade: {
    data: state.personalGrade.data.map((v) => {
      let gamerIdArr = payload.data.map((v) => v.gamerId);
      if (gamerIdArr.indexOf(v.gamerId) !== -1) {
        v["grades"] = payload.data.map((item) => ({
          gameKill: item.gameKill,
          dieNums: item.dieNums,
          killIntegral: item.killIntegral,
          rankingIntegral: item.rankingIntegral,
          gameSequenceNo: item.gameSequenceNo,
          isEatChicken: item.isEatChicken,
          position: item.position,
        }));
      }
      return v;
    }),
    total: state.personalGrade.total,
  },
});

const onGetTrainingTemplateSuccess = (state, { payload }) => ({
  ...state,
  trainingTemplate: payload,
});

const onGetTrainingTemplateDictionariesSuccess = (state, { payload }) => ({
  ...state,
  trainingTemplateDictionaries: payload,
});

const onGetPelTeamRankIntegralListSuccess = (state, { payload }) => ({
  ...state,
  pelTeamRankIntegralList: payload,
});

const onGetPelTeamRankListSuccess = (state, { payload }) => ({
  ...state,
  pelTeamRankIntegralList: payload,
});

const onGetPelTeamRankWeeklyIntegralListSuccess = (state, { payload }) => ({
  ...state,
  pelTeamRankWeeklyIntegralList: payload,
});

const onGetTrainingTemplateListSuccess = (state, { payload }) => ({
  ...state,
  trainingTemplateList: payload,
});

const onGetSortFieldDictionariesSuccess = (state, { payload }) => ({
  ...state,
  sortFieldDicList: payload,
});

const onGetPelPlayerRankListSuccess = (state, { payload }) => ({
  ...state,
  pelPlayerRankList: payload,
});

const onGetTrainingTeamRankListSuccess = (state, { payload }) => ({
  ...state,
  trainingTeamRankList: payload,
});

const onGetTrainingPlayerRankListSuccess = (state, { payload }) => ({
  ...state,
  trainingPlayerRankList: payload,
});

const onGetRankBaseDataConfigSuccess = (state, { payload }) => ({
  ...state,
  rankBaseDataConfig: payload,
});

const onGetRankDataConfigSuccess = (state, { payload }) => ({
  ...state,
  rankDataConfig: payload,
});

const onGetRankDataConfigBrandsSuccess = (state, { payload }) => ({
  ...state,
  rankDataConfigBrands: payload,
});

const onClearTrainingTemplate = (state) => ({
  ...state,
  trainingTemplate: {},
});

const onDeleteRankDataConfigSuccess = (state) => ({
    ...state
});

//天梯单排赛管理
const onGetPersonTrainingDataSuccess = (state, { payload }) => ({
  ...state,
  personTrainingData: payload,
});

const onCreatePersonTrainingSuccess = (state, { payload }) => ({
  ...state,
  personEditStatus: Boolean(true),
});

const onEditPersonTrainingSuccess = (state, { payload }) => ({
  ...state,
  personEditStatus: Boolean(true),
});

const onDeletePersonTrainingSuccess = (state, { payload }) => ({
  ...state,
  personEditStatus: Boolean(true),
});

const onGetJoinPlayerSuccess = (state, { payload }) => ({
  ...state,
  playerInfo: payload,
});

const onGetJoinStatePlayerSuccess = (state, { payload }) => ({
  ...state,
  statePlayerInfo: payload,
});

const onGetPersonTrainingGradeSuccess = (state, { payload }) => ({
  ...state,
  personTrainGrade: Object.assign({}, payload),
});

const onGetPersonTrainingGradeDetailSuccess = (state, { payload }) => ({
  ...state,
  personTrainGrade: {
    data: state.personTrainGrade.data.map((v) => {
      let gamerIdArr = payload.data.map((v) => v.gamerId);
      if (gamerIdArr.indexOf(v.gamerId) !== -1) {
        v["grades"] = payload.data.map((item) => ({
          gameKill: item.gameKill,
          dieNums: item.dieNums,
          killIntegral: item.killIntegral,
          rankingIntegral: item.rankingIntegral,
          gameSequenceNo: item.gameSequenceNo,
          isEatChicken: item.isEatChicken,
          position: item.position,
          hurt: item.hurt,
          hurtIntegral: item.hurtIntegral,
          survivalTime: item.survivalTime,
        }));
      }
      return v;
    }),
    total: state.personTrainGrade.total,
  },
});

const onQueryPersonVideoListSuccess = (state, { payload }) => ({
  ...state,
  personVideoData: payload,
});

const onGetPersonBrandDataSuccess = (state, { payload }) => ({
  ...state,
  personBrandData: payload,
});

const onGetFourSomeBrandDataSuccess = (state, { payload }) => ({
  ...state,
  fourSomeBrandData: payload,
});

const onGetPersonTrainingTemplateListSuccess = (state, { payload }) => ({
  ...state,
  personTemplateList: payload,
});

const onGetPersonTrainingTemplateDictionariesSuccess = (state, { payload }) => ({
  ...state,
  personTemplateDictionaries: payload,
});

const onGetPersonTrainingTemplateSuccess = (state, { payload }) => ({
  ...state,
  personTemplate: payload,
});

//天梯单排赛数据榜单管理
const onGetPersonalRankDataConfigBrandsSuccess = (state, { payload }) => ({
  ...state,
  rankPersonalDataConfigBrands: payload,
});

const onGetPersonalRankBaseDataConfigSuccess = (state, { payload }) => ({
  ...state,
  rankPersonalBaseDataConfig: payload,
});

const onGetPersonalRankDataConfigSuccess = (state, { payload }) => ({
  ...state,
  rankPersonalDataConfig: payload,
});

const onDeletePersonalRankDataConfigSuccess = (state) => ({
  ...state
});

const onGetPersonalSortFieldDictionariesSuccess = (state, { payload }) => ({
  ...state,
  personalSortFieldDicList: payload,
});

const onGetPersonalTrainingPlayerRankListSuccess = (state, { payload }) => ({
  ...state,
  personaltrainingPlayerRankList: payload,
});

//天梯四排赛赛数据榜单管理
const onGetFourSomeRankDataConfigBrandsSuccess = (state, { payload }) => ({
  ...state,
  rankFourSomeDataConfigBrands: payload,
});
  
const onGetFourSomeRankBaseDataConfigSuccess = (state, { payload }) => ({
  ...state,
  rankFourSomeBaseDataConfig: payload,
});

const onGetFourSomeRankDataConfigSuccess = (state, { payload }) => ({
  ...state,
  rankFourSomeDataConfig: payload,
});

const onDeleteFourSomeRankDataConfigSuccess = (state) => ({
  ...state
});

const onGetFourSomeSortFieldDictionariesSuccess = (state, { payload }) => ({
  ...state,
  fourSomeSortFieldDicList: payload,
});

const onGetFourSomeTrainingPlayerRankListSuccess = (state, { payload }) => ({
  ...state,
  fourSometrainingPlayerRankList: payload,
});

export default createReducer(defaultState, {
  [`${GET_TRAINING}_SUCCESS`]: onGetTrainingDataSuccess,
  [`${GET_BRAND}_SUCCESS`]: onGetBrandDataSuccess,
  [`${EDIT_TRAINING}_SUCCESS`]: onEditTrainingSuccess,
  [`${CREATE_TRAINING}_SUCCESS`]: onCreateTrainingSuccess,
  [`${DELETE_TRAINING}_SUCCESS`]: onDeleteTrainingSuccess,
  [`${GET_GRADE}_SUCCESS`]: onGetGradeSuccess,
  [`${UPDATE_GRADE}_SUCCESS`]: onUpdateGradeSuccess,
  [`${GET_ADDTEAM}_SUCCESS`]: onGetAddTeam,
  [`${GET_JOIN_TEAM}_SUCCESS`]: onGetJoinTeamSuccess,
  [`${GET_PERSONAL_GRADE}_SUCCESS`]: onGetPersonalGradeSuccess,
  [`${GET_PERSONAL_GRADE_DETAIL}_SUCCESS`]: onGetPersonalGradeDetailSuccess,
  [`${GET_GAME_MAPS}_SUCCESS`]: onGetGameMapsSuccess,
  [`${GET_VIDEO_CONFIG}_SUCCESS`]: onGetVideoConfigSuccess,
  [`${QUERY_VIDEO_LIST}_SUCCESS`]: onQueryVideoListSuccess,
  [`${GET_TRAINING_TEMPLATE}_SUCCESS`]: onGetTrainingTemplateSuccess,
  [`${GET_TRAINING_TEMPLATE_DICTIONARIES}_SUCCESS`]: onGetTrainingTemplateDictionariesSuccess,
  [`${GET_TRAINING_TEMPLATE_LIST}_SUCCESS`]: onGetTrainingTemplateListSuccess,
  [`${GET_PEL_LEAGUE_DIC}_SUCCESS`]: onGetPelLeagueDictionariesSuccess,
  [`${GET_PEL_TEAM_INTEGRAL}_SUCCESS`]: onGetPelTeamRankIntegralListSuccess,
  [`${GET_PEL_TEAM_WEEKLY_INTEGRAL}_SUCCESS`]: onGetPelTeamRankWeeklyIntegralListSuccess,
  [`${GET_SORT_FIELD_DIC}_SUCCESS`]: onGetSortFieldDictionariesSuccess,
  [`${GET_PEL_PLAYER_RANK}_SUCCESS`]: onGetPelPlayerRankListSuccess,
  [`${GET_PEL_TEAM_RANK}_SUCCESS`]: onGetPelTeamRankListSuccess,
  [`${GET_TRAINING_TEAM_RANK}_SUCCESS`]: onGetTrainingTeamRankListSuccess,
  [`${GET_TRAINING_PLAYER_RANK}_SUCCESS`]: onGetTrainingPlayerRankListSuccess,
  [`${GET_RANK_BASE_DATA_CONFIG}_SUCCESS`]: onGetRankBaseDataConfigSuccess,
  [`${GET_RANK_DATA_CONFIG}_SUCCESS`]: onGetRankDataConfigSuccess,
  [`${GET_RANK_DATA_CONFIG_BRANDS}_SUCCESS`]: onGetRankDataConfigBrandsSuccess,
  [`${DELETE_RANK_DATA_CONFIG}_SUCCESS`]: onDeleteRankDataConfigSuccess,

  [`${GET_PERSON_TRAINING}_SUCCESS`]: onGetPersonTrainingDataSuccess,
  [`${CREATE_PERSON_TRAINING}_SUCCESS`]: onCreatePersonTrainingSuccess,
  [`${EDIT_PERSON_TRAINING}_SUCCESS`]: onEditPersonTrainingSuccess,
  [`${DELETE_PERSON_TRAINING}_SUCCESS`]: onDeletePersonTrainingSuccess,
  [`${GET_JOIN_PLAYER}_SUCCESS`]: onGetJoinPlayerSuccess,
  [`${GET_JOIN_STATE_PLAYER}_SUCCESS`]: onGetJoinStatePlayerSuccess,
  [`${GET_PERSON_TRAINING_GRADE}_SUCCESS`]: onGetPersonTrainingGradeSuccess,
  [`${GET_PERSON_TRAINING_GRADE_DETAIL}_SUCCESS`]: onGetPersonTrainingGradeDetailSuccess,
  [`${QUERY_PERSON_VIDEO_LIST}_SUCCESS`]: onQueryPersonVideoListSuccess,
  [`${GET_PERSON_BRAND}_SUCCESS`]: onGetPersonBrandDataSuccess,
  [`${GET_PERSON_TRAINING_TEMPLATE_LIST}_SUCCESS`]: onGetPersonTrainingTemplateListSuccess,
  [`${GET_PERSON_TRAINING_TEMPLATE_DICTIONARIES}_SUCCESS`]: onGetPersonTrainingTemplateDictionariesSuccess,
  [`${GET_PERSON_TRAINING_TEMPLATE}_SUCCESS`]: onGetPersonTrainingTemplateSuccess,

  [`${GET_PERSONAL_RANK_DATA_CONFIG_BRANDS}_SUCCESS`]: onGetPersonalRankDataConfigBrandsSuccess,
  [`${GET_PERSONAL_RANK_BASE_DATA_CONFIG}_SUCCESS`]: onGetPersonalRankBaseDataConfigSuccess,
  [`${GET_PERSONAL_RANK_DATA_CONFIG}_SUCCESS`]: onGetPersonalRankDataConfigSuccess,
  [`${DELETE_PERSONAL_RANK_DATA_CONFIG}_SUCCESS`]: onDeletePersonalRankDataConfigSuccess,

  [`${GET_PERSONAL_SORT_FIELD_DIC}_SUCCESS`]: onGetPersonalSortFieldDictionariesSuccess,
  [`${GET_PERSONAL_TRAINING_PLAYER_RANK}_SUCCESS`]: onGetPersonalTrainingPlayerRankListSuccess,

  [`${GET_FOURSOME_BRAND}_SUCCESS`]: onGetFourSomeBrandDataSuccess,

  [`${GET_FOURSOME_RANK_DATA_CONFIG_BRANDS}_SUCCESS`]: onGetFourSomeRankDataConfigBrandsSuccess,
  [`${GET_FOURSOME_RANK_BASE_DATA_CONFIG}_SUCCESS`]: onGetFourSomeRankBaseDataConfigSuccess,
  [`${GET_FOURSOME_RANK_DATA_CONFIG}_SUCCESS`]: onGetFourSomeRankDataConfigSuccess,
  [`${DELETE_FOURSOME_RANK_DATA_CONFIG}_SUCCESS`]: onDeleteFourSomeRankDataConfigSuccess,

  [`${GET_FOURSOME_SORT_FIELD_DIC}_SUCCESS`]: onGetFourSomeSortFieldDictionariesSuccess,
  [`${GET_FOURSOME_TRAINING_PLAYER_RANK}_SUCCESS`]: onGetFourSomeTrainingPlayerRankListSuccess,

});
