import createReducer from 'utils/createReducer';

import {
    GET_COMMENTS
} from './actionType';

const defaultState = () => ({
    comments: [],
    commentsTotal: 0,
    commentsLoading: false,
    commentsInfo: {},
    adminRoles: [], // 用户所拥有的角色
});




const ongetCommentsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        comments:payload

    }

};



export default createReducer(defaultState, {
    [`${GET_COMMENTS}_SUCCESS`]: ongetCommentsRequest,
});
