import React from 'react';
import {Button, Form, Input, message, Modal, Switch, Table} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import {sendAppNotices} from "../../modules/appNotice/action";

const FormItem = Form.Item;

const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 15},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

const {confirm} = Modal;

class AppNoticePushModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pushContent: '',
            page: 1,
            size: 10,
            sendAll: false
        };
    }

    doNotice = async (values) => {
        const {sendAppNotices} = this.props;
        await sendAppNotices(values)
        message.info('消息推送成功')

        const {onSuccess} = this.props;
        await onSuccess && onSuccess(values);
    }

    onSubmit = () => {
        let _this = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // eslint-disable-next-line no-unused-expressions
                confirm({
                    content: '确认要推送消息吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _this.doNotice(values);
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    onChange = (checked) => {
        this.setState({
            sendAll: checked
        })
    }


    render() {
        let {visible} = this.props
        let {sendAll} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                visible={visible}
                title="推送"
                width={'50%'}
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="推送内容"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('noticeContent', {
                            rules: [{required: true, message: '请输入要推送的内容'}],
                        })(
                            <TextArea placeholder="请输入要推送的内容" row={3}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="通知所有用户"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('all', {})(
                            <Switch onChange={this.onChange}/>
                        )}
                    </FormItem>
                    <FormItem
                        label="用户展示ID列表"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('exhibitionIds', {
                            rules: [
                                {
                                    pattern: /^(\d+,)*\d+$/,
                                    message: '格式不正确'
                                }],
                        })(
                            <TextArea row={3} disabled={sendAll} placeholder="请输入用户展示ID列表,以英文逗号分隔"/>
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};
const mapDispatchToProps = ({
    sendAppNotices
});
AppNoticePushModal.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AppNoticePushModal)));
