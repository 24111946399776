import React, { Component } from 'react';
import {
    Button, Table, Card, notification,
} from 'antd';
import { getComment,deleteComment,updateCommentStatus} from "../../modules/dynamic/action";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";

class DynamicCommentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
            pageSize: 10,

        };
    }
    componentDidMount() {
        const {pageNo, pageSize} = this.state;
        let {getComment,dynamicId} = this.props;
        let commentType = "dynamic";
        getComment(pageNo, pageSize,dynamicId,commentType);

    }

    componentWillReceiveProps(nextProps,nextState) {
        const {pageNo, pageSize} = this.state;
        let {getComment,dynamicId} = this.props;
        let commentType = "dynamic";
        console.log(dynamicId)
        if(nextProps.dynamicId!==this.props.dynamicId){
            getComment(pageNo, pageSize,nextProps.dynamicId,commentType);
        }
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }

    //分页事件
    onPageChangeEvent = (current, pageSize) => {
        let {getComment,dynamicId} = this.props;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        let commentType = "dynamic";
        getComment(current, pageSize,dynamicId,commentType);
    }

    //删除评论的方法
    onDeleteCommentEvent = async (adminId) => {
        const { deleteComment, getComment,dynamicId } = this.props;
        const { page, size } = this.state;
        console.log(adminId)
        try {
            await deleteComment(adminId);
            notification.success({ message: '屏蔽成功' });
            let commentType = "dynamic";

            getComment(page, size,dynamicId,commentType);
        } catch (e) {
            console.log(e);
        }
    };

    //恢复评论的方法
    onRestoreCommentEvent = async (adminId) => {
        const { updateCommentStatus, getComment,dynamicId } = this.props;
        const { page, size } = this.state;
        try {
            await updateCommentStatus(adminId);
            notification.success({ message: '恢复成功' });
            let commentType = "dynamic";

            getComment(page, size,dynamicId,commentType);
        } catch (e) {
            console.log(e);
        }
    };

    render() {


        console.log(this.props)
        const {
            comments, loading
        } = this.props;
        const {pageSize,pageNo} = this.state;

        return (
            <Card>
                <Table

                    columns={
                        [
                            {
                                title: '用户ID',
                                dataIndex: 'criticsId',
                                key: 'criticsId',
                                align: 'center',
                            },
                            {
                                title: '用户名',
                                dataIndex: 'criticsName',
                                key: 'criticsName',
                                align: 'center',
                            },
                            {
                                title: '内容',
                                dataIndex: 'content',
                                key: 'content',
                                align: 'center',
                            },
                            {
                                title: '时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                align: 'center',
                            },
                            {
                                title: '操作',
                                dataIndex: '' +
                                    'operate',
                                key: 'operate',
                                align: 'center',
                                render: (id, text) => {

                                    console.log(text)
                                    return(<div>
                                        {text.commentStatus == "0" ? <Button type="danger" onClick={() =>  this.onDeleteCommentEvent(text.id)}>屏蔽</Button> : <Button type="primary" onClick={() => this.onRestoreCommentEvent(text.id)}>恢复</Button>}

                                    </div>)

                                },
                            },
                        ]
                    }
                    dataSource={comments.data ? comments.data : []}
                    bordered
                    loading={loading}
                    pagination={{
                        total: comments ? comments.total : 0,
                        current: pageNo,
                        pageSize: pageSize,
                        onChange: this.onPageChangeEvent,
                        showTotal:(total)=>{
                            return `总计 ${total}条数据`;
                        }
                    }}
                />

            </Card>
        );
    }
}

const mapStateToProps = (state => {
        console.log(state.viewDynamic)
        return {
            ...state.viewDynamic
        }

    }

);

const mapDispatchToProps = {
    getComment,deleteComment,updateCommentStatus
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(DynamicCommentForm)));
