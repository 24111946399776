import React, {Component} from 'react';
import {
    Form, Input, Button, Select, notification, Cascader
} from 'antd';
import moment from "moment";
import { areaData } from '../../dictionary/area';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 15},
};

class AreaSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypeList: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            innerJumpType: this.props.innerJumpType,
        };
    }

    componentWillMount(){
        
    }

    render() {               
        return (
            <Cascader
                options={areaData}
                {...this.props}
            />
        );
    }
}

export default Form.create()(AreaSelect);
