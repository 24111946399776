import createReducer from 'utils/createReducer';
import {
    GET_LADDER_TEMPLATE_LIST,
    GET_LADDER_TEMPLATE_DICTIONARIES,
    GET_LADDER_TEMPLATE_INFO
} from './actionTypes';

const defaultState = () => ({
    ladderTemplateList: {
        data: [],
        total: 0,
    }, // 天梯四排赛-赛事模板列表
    ladderTemplateDictionaries: [],
    ladderTemplate: {},
});

const onGetLadderTemplateListSuccess = (state, {payload}) => ({
    ...state,
    ladderTemplateList: payload,
});

const onGetLadderTemplateDictionariesSuccess = (state, {payload}) => ({
    ...state,
    ladderTemplateDictionaries: payload,
});

const onGetLadderTemplateInfoSuccess = (state, {payload}) => ({
    ...state,
    ladderTemplate: payload,
});

export default createReducer(defaultState, {
    [`${GET_LADDER_TEMPLATE_LIST}_SUCCESS`]: onGetLadderTemplateListSuccess,
    [`${GET_LADDER_TEMPLATE_DICTIONARIES}_SUCCESS`]: onGetLadderTemplateDictionariesSuccess,
    [`${GET_LADDER_TEMPLATE_INFO}_SUCCESS`]: onGetLadderTemplateInfoSuccess,
});
