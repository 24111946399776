import React from 'react';
import {Button, Card, Col, message, Modal, Row, Table, Tabs,} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/certification/enums';
import {getGameList} from "../../modules/team/action";
import {
    addTeamCertifiacation,
    getTeamCertificationInfo,
    getUserCredentials,
    updateTeamCertifiacation,
    checkCredentials, updateUserCertification
} from "../../modules/certification/action";
import CertificationInfoModel from "./components/CertificationInfoModel";
import CertificationFilter from "./components/CertificationFilter";
import AddCertificationModel from "./components/AddCertificationModel";
import UpdateCertificationModel from "./components/UpdateCertificationModel";

const {confirm} = Modal;
const {TabPane} = Tabs;

class Certification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            previewVisible: false,
            previewImage: '',
            operationType: operationTypeValue.INCREASE,
            isShowCertificationInfoModel: false, // 战队信息
            isShowAddCertificationModel: false,
            isShowUpdateCertificationInfoModel: false,
            seletionValue: null,
            page: 1,
            size: 10,
            game: '',
            exhibitionId: '',
            gameCharacterNum: '',
            state: 'REVIEW',
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.userCredentials !== this.props.userCredentials) {
            this.setState({
                loading: false
            })
        }
    }

    //刷新界面数据
    onGetTableData = () => {
        const {getUserCredentials, getGameList} = this.props;
        const {page, size, state} = this.state;
        this.setState({
            loading: true
        })
        getUserCredentials({
            page: page, size: size,
            game: this.state.game ? this.state.game : null,
            exhibitionId: this.state.exhibitionId ? this.state.exhibitionId : null,
            state: this.state.state ? this.state.state : null
        });
        getGameList();
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getUserCredentials} = this.props;
        getUserCredentials({
            page: current, size: pageSize,
            game: this.state.game ? this.state.game : null,
            exhibitionId: this.state.exhibitionId ? this.state.exhibitionId : null,
            state: this.state.state ? this.state.state : null
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    onFormSubmitConfirm = (value) => {
        let _this = this;
        confirm({
            content: '确认' + (value.state === 'SUCCESS' ? '通过' : '拒绝') + '该游戏实力认证吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onFormSubmit(value)
            },
            onCancel() {
            },

        });
    }

    onAddFormSubmitConfirm = (value) => {
        let _this = this;
        confirm({
            content: '确认要添加该参赛认证吗?',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onAddFormSubmit(value);
            },
            onCancel() {
            },

        });
    }

    onUpdateFormSubmitConfirm = (value) => {
        let {seletionValue} =this.state;
        let _this = this;
        confirm({
            content: `确认要${seletionValue ? '修改' : '添加'}该游戏实力认证吗?`,
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUpdateFormSubmit(value);
            },
            onCancel() {
            },

        });
    }


    onAddFormSubmit = async (values) => {
        const {isShowAddCertificationModel} = this.state
        const {addTeamCertifiacation} = this.props
        await addTeamCertifiacation(values);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success('添加成功');
        this.setState({isShowAddCertificationModel: !isShowAddCertificationModel});
    }


    onUpdateFormSubmit = async (values) => {
        console.log(values)
        const {isShowUpdateCertificationInfoModel, seletionValue} = this.state
        const {updateUserCertification} = this.props
        await updateUserCertification(values.exhibitionId, values);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success(`${seletionValue ? '修改' : '添加'}成功`);
        this.setState({
            isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
            seletionValue: null
        });
    }


    onFormSubmit = async (values) => {
        const {isShowCertificationInfoModel, seletionValue} = this.state
        const {checkCredentials} = this.props
        const params = values;
        await checkCredentials(seletionValue.id, params);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success('审核成功');
        this.setState({isShowCertificationInfoModel: !isShowCertificationInfoModel});
    }


    //条件查找动态方法
    onFilterCertificationEvent = async (game, exhibitionId, gameCharacterNum) => {
        const {size, state} = this.state;
        const {getUserCredentials} = this.props;
        this.setState({
            loading: true
        })
        await getUserCredentials({
            page: 1, size: size,
            game: game ? game : null,
            exhibitionId: exhibitionId ? exhibitionId : null,
            gameCharacterNum: gameCharacterNum ? gameCharacterNum : null,
            state: state
        });
        this.setState({
            game: game ? game : null,
            exhibitionId: exhibitionId ? exhibitionId : null,
            gameCharacterNum: gameCharacterNum ? gameCharacterNum : null,
            page: 1
        })
    }

    changeTabPane = async (key) => {
        const {size,} = this.state;
        const {getUserCredentials} = this.props;
        this.setState({
            state: key ? key : null,
            page: 1,
            game: null,
            exhibitionId: null,
            loading: true
        })
        await getUserCredentials({
            page: 1, size: size,
            state: key ? key : null
        });
    }

    renderTableTitle = () => {
        return (
            <Row type="flex" justify="end">
                <Col>
                    <Button type="primary"
                            onClick={() => this.setState({isShowUpdateCertificationInfoModel: true})}>新增</Button>
                </Col>
            </Row>
        )
    }

    renderTabPane = () => {
        const {
            userCredentials, games
        } = this.props;
        const {
            isShowCertificationInfoModel, isShowUpdateCertificationInfoModel, isShowAddCertificationModel, seletionValue, page, size, state, loading, previewVisible, previewImage,
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CertificationFilter value={state} onCommit={this.onFilterCertificationEvent}/>
                </Card>

                <Card>
                    <Table
                        dataSource={userCredentials ? userCredentials.data : []}
                        columns={this.getTableColumns()}
                        title={this.state.state === "SUCCESS" ? this.renderTableTitle : null}
                        bordered
                        pagination={{
                            // total: teams ? teams.total : 0,
                            total: userCredentials.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowCertificationInfoModel ? <CertificationInfoModel
                        value={seletionValue}
                        onSuccess={this.onFormSubmitConfirm}
                        visible={isShowCertificationInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowCertificationInfoModel: !isShowCertificationInfoModel,
                                seletionValue: null
                            });
                        }}
                    /> : null}
                    {isShowAddCertificationModel ? <AddCertificationModel
                        onSuccess={this.onAddFormSubmitConfirm}
                        visible={isShowAddCertificationModel}
                        onClose={() => {
                            this.setState({
                                isShowAddCertificationModel: !isShowAddCertificationModel,
                            });
                        }}
                    /> : null}
                    {isShowUpdateCertificationInfoModel ? <UpdateCertificationModel
                        value={seletionValue}
                        onSuccess={this.onUpdateFormSubmitConfirm}
                        visible={isShowUpdateCertificationInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
                                seletionValue: null
                            });
                        }}
                    /> : null}
                </Card>

                {previewVisible ?
                    <Modal width={'80%'} visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <img alt="example" src={previewImage}/>
                        </div>
                    </Modal> : null}
            </div>
        )
    }

    render() {
        return (
            <div>
                <Tabs onChange={this.changeTabPane} type="card">
                    <TabPane tab="游戏实力认证认证列表(审核中)" key="REVIEW">
                        {this.state.state === "REVIEW" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="游戏实力认证认证列表(通过)" key="SUCCESS">
                        {this.state.state === "SUCCESS" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="游戏实力认证认证列表(拒绝)" key="FAIL">
                        {this.state.state === "FAIL" ? this.renderTabPane() : null}
                    </TabPane>
                </Tabs>
            </div>
        );
    }

    onGetCertificationInfoEvent = async (value) => {
        const {isShowCertificationInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowCertificationInfoModel: !isShowCertificationInfoModel,
        });
    }

    onUpdateCertificationInfoEvent = async (value) => {
        const {isShowUpdateCertificationInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
        });
    }


    handleCancel = () => this.setState({previewVisible: false});
    showPreviewImage = (value) => {
        this.setState({previewImage: value, previewVisible: true});
    }

    getTableColumns = () => [
        {
        title: '用户ID',
        dataIndex: 'userId',
        align: 'center',
        key: 'userId',
        },
        {
        title: '用户展示ID',
        dataIndex: 'exhibitionId',
        align: 'center',
        key: 'exhibitionId',
        },
        {
        title: '用户名',
        dataIndex: 'username',
        align: 'center',
        key: 'username',
        },
        {
        title: '游戏角色编号',
        dataIndex: 'gameCharacterNum',
        align: 'center',
        key: 'gameCharacterNum',
        },
        {
            title: '游戏昵称',
            dataIndex: 'playerName',
            align: 'center',
            key: 'playerName',
        },
        {
            title: '备用游戏昵称1',
            dataIndex: 'playerName1',
            align: 'center',
            key: 'playerName1',
        },
        {
            title: '备用游戏昵称2',
            dataIndex: 'playerName2',
            align: 'center',
            key: 'playerName2',
        },
        {
            title: '当前段位',
            dataIndex: 'currentLevel',
            align: 'center',
            key: 'currentLevel',
        }, {
            title: '当前KDA',
            dataIndex: 'currentKda',
            align: 'center',
            key: 'currentKda',
        }, {
            title: '最高段位',
            dataIndex: 'highestLevel',
            align: 'center',
            key: 'highestLevel',
        }, {
            title: '最高KDA',
            dataIndex: 'highestKda',
            align: 'center',
            key: 'highestKda',
        }, {
            title: '游戏截图',
            dataIndex: 'imageUrl',
            align: 'center',
            key: 'imageUrl',
            render: (row, text) => (
                <div>
                    <img id="myImage" alt={""}
                         src={text.imageUrl ? text.imageUrl + '?x-oss-process=image/resize,h_100' : ""} height="100"
                         onClick={() => this.showPreviewImage(text.imageUrl ? text.imageUrl : "")}/>
                </div>
            ),
        }, {
            title: '状态',
            dataIndex: 'state',
            align: 'center',
            key: 'state',
            render: (row, text) => (
                <span>{text.state == "SUCCESS" ? "通过" : text.state == "FAIL" ? "拒绝" : text.state == "REVIEW" ? "审核中" : "审核中"}</span>
            ),
        },
        {
            title: '操作',
            dataIndex: 'details',
            key: 'details',
            align: 'center',
            render: (row, text) => (
                <div>
                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                            onClick={() => this.onGetCertificationInfoEvent(text)}>审核</Button>

                    <Button type="primary" style={{marginLeft: 10, marginBottom: 10}}
                            onClick={() => this.onUpdateCertificationInfoEvent(text)}>{"编辑"}</Button>
                </div>
            ),
        }
    ]
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        ...state.Team,
        ...state.Certification
    }
};

const mapDispatchToProps = ({
    getGameList,
    getUserCredentials, addTeamCertifiacation,
    getTeamCertificationInfo,
    checkCredentials, updateTeamCertifiacation,
    updateUserCertification

});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Certification));
