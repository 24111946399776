import React, {Component} from "react";
import {Button, Card, DatePicker, Form, Input, message, Tag} from "antd";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import moment from "moment";
import {getMoney} from "../../modules/pay/action";
const FormItem = Form.Item;
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class MoneyFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {userTeamsData:[],isShowUserTeamsData: false}
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;

        const {getMoney} = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {dateRange} = values;

                console.log(values)

                getMoney(dateRange);
            }
        });

    }



    setUserTeamsData = () =>{
        const {userTeams} = this.props;
        this.setState({
            userTeamsData: userTeams.map(function (v) {
                return v.gameType+"-"+v.teamShowId
            }).join(','),
            isShowUserTeamsData: true
        })
    }

    onChangeGamerShowId = () => {
        this.setState({
            userTeamsData: [],
            isShowUserTeamsData: false
        })
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {isShowUserTeamsData,userTeamsData} = this.state;
        const {money} = this.props;
        console.log(money)
        return (
            <div>
                <Form onSubmit={this.handleSubmit} layout="inline" style={{display: 'inline'}}>
                    <FormItem label="发布时间区间：">
                        {getFieldDecorator('dateRange', {
                            initialValue: [moment().subtract('7','day'), moment().subtract('0','day')]
                        })(<RangePicker
                            allowClear
                            format={dateFormat}
                        />)}
                    </FormItem>

                    <FormItem>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                            总额查询
                        </Button>
                    </FormItem>

                <FormItem>
                    <Tag>总额：{money.actualMoney}</Tag>
                    <Tag>苹果渠道金额：{money.actualAppleMoney}</Tag>
                    <Tag>支付宝渠道金额：{money.actualAliMoney}</Tag>
                    <Tag>小程序渠道金额：{money.actualWxLiteMoney}</Tag>
                    <Tag>微信渠道金额：{money.actualWxMoney}</Tag>
                    <Tag>浪币商品总额：{money.actualRechargeMoney}</Tag>
                    <Tag>vip商品金额：{money.actualVipMoney}</Tag>


                </FormItem>
            </Form>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state.Payments)
    return {
        ...state.Payments,
    }
};
const mapDispatchToProps = ({getMoney});
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(MoneyFilter)));