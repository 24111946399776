import React, { Component } from "react";
import { Button, Col, DatePicker, Form, Select, Modal,notification } from "antd";
import connect from "react-redux/es/connect/connect";
import { injectIntl } from "react-intl-context";
import {
    createFourSomeRankDataConfig,
    getPelLeagueDictionaries,
    getPelTeamRankIntegral,
    getFourSomeRankBaseDataConfig,
    getFourSomeRankDataConfig,
    getFourSomeRankDataConfigBrands,
    deleteFourSomeRankDataConfig,
    updateFourSomeRankDataConfig
} from "../../../modules/training/action";
import moment, { now } from "moment";
import RankDataConfigModel from "./RankDataConfigModel";
import { operationTypeValue } from "../../../modules/training/enums";

const { confirm } = Modal;
const FormItem = Form.Item;
const Option = Select.Option;

const { WeekPicker } = DatePicker;

class TrainingDataIntegralFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yqlBrandType: null,
            cycle: null,
            isShowRankDataConfigModel: false,
            operationType: null,
        };
    }

    componentWillMount() {
        const { yqlBrandType, getFourSomeRankDataConfigBrands } = this.props;
        console.log(yqlBrandType);
        getFourSomeRankDataConfigBrands()
            .then((data) => {
                console.log("getFourSomeRankDataConfigBrands",data);
                const startDate = moment(now()).day(1).format("YYYYMMDD"); // 周一日期
                const endDate = moment(now()).day(7).format("YYYYMMDD"); // 周日日期
                this.setState({
                    cycle: `${startDate}-${endDate}`,
                    yqlBrandType: data[0].id,
                });
            })
            .catch((error) => { });
    }

    onChangeWeek = (weekData, dateString) => {
        // console.log(weekData, dateString);
        // const startDate = moment(weekData).day(1).format('YYYY/MM/DD (dddd)'); // 周一日期
        // const endDate = moment(weekData).day(7).format('YYYY/MM/DD (dddd)'); // 周日日期
        const startDate = moment(weekData).day(1).format("YYYYMMDD"); // 周一日期
        const endDate = moment(weekData).day(7).format("YYYYMMDD"); // 周日日期
        let cycle = `${startDate}-${endDate}`;
        this.setState({
            cycle: cycle,
        });
        let { onCommit } = this.props;
        onCommit && onCommit(cycle, this.state.yqlBrandType);
    };

    onChangeBrandType = (e) => {
        console.log(e);
        this.setState({
            yqlBrandType: e,
        });
        let { onCommit } = this.props;
        onCommit && onCommit(this.state.cycle, e);
    };

    onGetRankDataConfigEvent = async (type) => {
        const { isShowRankDataConfigModel, yqlBrandType } = this.state;
        const {
            getFourSomeRankBaseDataConfig,
            getFourSomeRankDataConfig,
            rankFourSomeDataConfigBrands,
        } = this.props;
        let currentBrand =
            rankFourSomeDataConfigBrands.find((item) => item.id == yqlBrandType) || {};
        if (type === operationTypeValue.INCREASE) {
            await getFourSomeRankBaseDataConfig();
        } else {
            if (!currentBrand.id) {
                return notification.error({
                    message: '提示',
                    description:
                        '请选中一条榜单',
                });
            }
            await getFourSomeRankDataConfig(currentBrand && currentBrand.id);
        }
        await this.setState({
            operationType: type,
            isShowRankDataConfigModel: !isShowRankDataConfigModel,
        });
    };
    onFormSubmit = async (params) => {
        const {
            isShowRankDataConfigModel,
            operationType,
            yqlBrandType,
        } = this.state;
        const {
            createFourSomeRankDataConfig,
            rankFourSomeDataConfig,
            getFourSomeRankDataConfigBrands,
            updateFourSomeRankDataConfig,
        } = this.props;
        console.log('rankFourSomeDataConfig',rankFourSomeDataConfig);
        if (operationType === operationTypeValue.UPDATE) {
            
            params.id = rankFourSomeDataConfig.id;
        }
        params.userExhibitionIds = params.userExhibitionIds ? params.userExhibitionIds.split(",") : [];
        params.cycleType = params.cycleType.join(',')
        if(operationType === operationTypeValue.UPDATE){
            await updateFourSomeRankDataConfig(params);
        }else{
            await createFourSomeRankDataConfig(params);
        }
        
        getFourSomeRankDataConfigBrands()
            .then((data) => {
                const startDate = moment(now()).day(1).format("YYYYMMDD"); // 周一日期
                const endDate = moment(now()).day(7).format("YYYYMMDD"); // 周日日期
                this.setState({
                    cycle: `${startDate}-${endDate}`,
                    yqlBrandType: data[0].id,
                });
            })
            .catch((error) => { });

        await this.setState({
            isShowRankDataConfigModel: !isShowRankDataConfigModel,
        });
    };
    onDelete = async (id) => {
        const { isShowRankDataConfigModel, yqlBrandType } = this.state;
        const {
            rankFourSomeDataConfigBrands,
            deleteFourSomeRankDataConfig,
            getFourSomeRankDataConfigBrands
        } = this.props;
        confirm({
            content: '确认要删除这条榜单记录?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk: async () => {
                await deleteFourSomeRankDataConfig(id);
                const { resetFields } = this.props.form;
                await getFourSomeRankDataConfigBrands();
                resetFields && resetFields()
                await this.setState({
                    operationType: operationTypeValue.DELETE,
                    isShowRankDataConfigModel: !isShowRankDataConfigModel,
                });
            },
            onCancel() {
            },

        });

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { cycle, isShowRankDataConfigModel, operationType } = this.state;
        const { rankFourSomeDataConfigBrands, getFourSomeRankDataConfig } = this.props;
        console.log("rankFourSomeDataConfigBrands",rankFourSomeDataConfigBrands);
        return (
            <div>
                <Col span={20}>
                    <Form onSubmit={this.handleSubmit} layout="inline">
                        <FormItem label="赛事阶段">
                            {getFieldDecorator("year", {
                                initialValue: moment(),
                            })(
                                <div>
                                    <WeekPicker
                                        defaultValue={moment()}
                                        onChange={this.onChangeWeek}
                                    />
                                    <span>{cycle}</span>
                                </div>
                            )}
                        </FormItem>
                        <FormItem label="训练赛类型">
                            {getFieldDecorator("yqlBrandType", {
                                initialValue: rankFourSomeDataConfigBrands && rankFourSomeDataConfigBrands.length > 0
                                    ? rankFourSomeDataConfigBrands[0]["id"]
                                    : "",
                            })(
                                <Select
                                    style={{ width: "300px" }}
                                    onChange={this.onChangeBrandType}
                                >
                                    {rankFourSomeDataConfigBrands &&
                                        rankFourSomeDataConfigBrands.map((item) => {
                                            return <Option value={item.id}>{item.name}</Option>;
                                        })}
                                </Select>
                            )}
                        </FormItem>
                        {/*<FormItem>*/}
                        {/*    <Button type="primary" htmlType="submit" style={{width: '100%'}}>*/}
                        {/*        查询*/}
                        {/*    </Button>*/}
                        {/*</FormItem>*/}
                    </Form>
                </Col>
                <Col span={4}>
                    <span>配置数据榜：</span>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                            this.onGetRankDataConfigEvent(operationTypeValue.INCREASE)
                        }
                    >
                        新增
          </Button>
                    <Button
                        type="primary"
                        style={{}}
                        onClick={() =>
                            this.onGetRankDataConfigEvent(operationTypeValue.UPDATE)
                        }
                    >
                        编辑
          </Button>
                </Col>
                {isShowRankDataConfigModel ? (
                    <RankDataConfigModel
                        operationType={operationType}
                        onSuccess={this.onFormSubmit}
                        onDelete={this.onDelete}
                        visible={isShowRankDataConfigModel}
                        onClose={() => {
                            this.setState({
                                isShowRankDataConfigModel: false,
                            });
                        }}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    };
};
const mapDispatchToProps = {
    getPelLeagueDictionaries,
    getPelTeamRankIntegral,
    getFourSomeRankBaseDataConfig,
    createFourSomeRankDataConfig,
    updateFourSomeRankDataConfig,
    getFourSomeRankDataConfig,
    deleteFourSomeRankDataConfig,
    getFourSomeRankDataConfigBrands,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Form.create()(TrainingDataIntegralFilter)));
