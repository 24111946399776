import api from 'utils/api';

class LadderTrainingRequest {
    /**
     * 天梯四排赛-获取训练赛列表
     * @param {Object} params 请求参数
     */
    static async getLadderTrainingList(params) {
        return api.get(`/admin/mixed_team/training/admin`, params);
    }

    /**
     * 天梯四排赛-创建训练赛
     * @param params
     */
    static async createLadderTraining(params) {
        return api.post(`/admin/mixed_team/training`, params);
    }

    /**
     * 天梯四排赛-修改训练赛信息
     * @param id
     * @param params
     */
    static async updateLadderTraining(params) {
        return api.put(`/admin/mixed_team/training/${params.trainingId}`, params);
    }

    /**
     * 天梯四排赛-删除训练赛
     */
     static async deleteLadderTraining(trainingId) {
        return api.delete(`/admin/mixed_team/training/${trainingId}`);
    }

    /**
     * 天梯四排赛-训练赛置顶
     */
     static async updateLadderTrainingTop(trainingId) {
        return api.put(`/admin/mixed_team/training/${trainingId}/top`);
    }

    /**
     * 天梯四排赛-配置回放视频
     * @param {Number} id
     */
     static async updateLadderVideoConfig(trainingId, params) {
        return api.post(`/admin/mixed_team/training/${trainingId}/video`, params);
    }

    /**
     * 天梯四排赛-获取参与详情列表（报名选手列表）
     */
     static async getLadderJoinPlayerList(params) {
        return api.get(`/admin/mixed_team/training/player`, params);
    }

    /**
     * 天梯四排赛-（批量）添加报名选手
     */
     static async addLadderJoinPlayer(params){
        return api.post(`/admin/mixed_team/training/fullSign`, params);
    }

    /**
     * 天梯四排赛-将选手设为替补（踢出当前队伍）
     */
     static async updateLadderPlayerSubstitute(params){
        return api.put(`/admin/mixed_team/team/player/substitute`, params);
    }

    /**
     * 天梯四排赛-变更选手次序值
     */
     static async updateLadderPlayerOrder(params){
        return api.put(`/admin/mixed_team/team/player/order`, params);
    }

    /**
     * 天梯四排赛-修改选手坑位号（指派队伍）
     */
     static async updateLadderPlayerAssign(params){
        return api.put(`/admin/mixed_team/team/player/assign`, params);
    }

    /**
     * 天梯四排赛-移除报名
     */
     static async deleteLadderPlayerSignUp(params) {
        return api.delete(`/admin/mixed_team/training/signUp?signUpIds=${params.signUpIds}&trainingId=${params.trainingId}`);
    }

    /**
     * 天梯四排赛-推送消息给选手
     */
     static async ladderTrainingNotice(params){
        return api.post(`/admin/mixed_team/training/notice`, params);
    }

    /**
     * 天梯四排赛-获取队伍战绩列表
     */
    static async getLadderTeamGrade(trainingId, params) {
        return api.get(`/admin/mixed_team/training/${trainingId}/grade`, params);
    }

    /**
     * 天梯四排赛-获取个人战绩列表
     */
    static async getLadderPersonGrade(trainingId, params) {
        return api.get(`/admin/mixed_team/training/${trainingId}/personal/grade`, params);
    }
}

export default LadderTrainingRequest;
