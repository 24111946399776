import React, {Component} from 'react';
import {
    Form, Input, Button, Select
} from 'antd';
import UpLoad from "../../components/upload";
import api from "../../utils/api";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class BannerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypes: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            jumpType: this.props.isOuter,
            innerJumpType:this.props.innerJumpType
        };
    }

    componentDidMount() {
        this.requestJumpType()
            .then(r => {
                this.setState({
                    innerJumpTypes: r
                })
            });
        this.changeDes(this.state.innerJumpType);
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onSubmitEvent} = this.props;
                if(values && values.imageUrl){
                    values.imageUrl=values.imageUrl.split("?")[0]
                }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    requestJumpType = () => {
        return api.get("/admins/banners/inner_jump_type");
    }

    changeType = (value) => {
        this.state.jumpType = value;
        if (this.state.jumpType === "INNER") {
            this.setState({
                isOuter: true
            })
        }
        if (this.state.jumpType === "OUTER") {
            this.setState({
                isOuter: false
            })
        }
    }
    changeDescription = value => {
        let innerJumpTypes = this.state.innerJumpTypes;
        let des = "";
        innerJumpTypes.map((item) => {
            if (item.value === value) {
                des = item.description;
            }
            return null
        });
        this.setState({
            jumpDescription: des
        })
    }
    componentWillMount(){
        let {innerJumpTypes,bannerType} = this.props;
        let des = "";
        if (innerJumpTypes!==null && innerJumpTypes!=null){
            innerJumpTypes.map((item) => {
                if (item.value === bannerType) {
                    des = item.description;
                }
                return null;
            });
            console.log(innerJumpTypes,bannerType)
            this.setState({
                jumpDescription: des
            })
        }

    }

    changeDes = (value) => {
        let innerJumpTypes = this.state.innerJumpTypes;
        let des = "";
        innerJumpTypes.map((item) => {
            if (item.value === value) {
                des = item.description;
            }
            return null
        });
        this.setState({
            jumpDescription: des
        })
    }



    render() {
        let {
            imageUrl,
            title,
            description,
            outerUrl,
            enable,
            jumpType,
            innerJumpType,
            attach,
            ordinal,
            pageType,
            jumpOutside
        } = this.props;
        console.log(this.props.jumpOutside)
        const {getFieldDecorator} = this.props.form;
        const {TextArea} = Input;
        let {innerJumpTypes, jumpDescription, isOuter} = this.state;
        return (
            <Form>
                <FormItem
                    label="标题"
                    {...formItemLayout}
                >
                    {getFieldDecorator('title', {
                        rules: [{required: true, message: '请输入标题', pattern: /^\S+$/}],
                        initialValue: title,
                    })(
                        <Input placeholder="请输入标题"/>,
                    )}
                </FormItem>
                <FormItem
                    label="图片(690*240)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('imageUrl', {
                        initialValue: imageUrl ? imageUrl+'?x-oss-process=image/resize,w_86' : '',
                    })(
                        (<UpLoad
                            double={false}
                            //图片格式限制
                            // width = {690}
                            // height = {240}
                            size={251}
                        />),
                    )}
                </FormItem>
                <FormItem
                    label="序号"
                    {...formItemLayout}>
                    {getFieldDecorator('ordinal', {
                        rules: [{required: true, message: '请选择序号', pattern: /^\S+$/}],
                        initialValue: ordinal,
                    })(
                        <Select style={{width: '150px'}}>
                            <Option key={1} value='1'>1</Option>
                            <Option key={2} value='2'>2</Option>
                            <Option key={3} value='3'>3</Option>
                            <Option key={4} value='4'>4</Option>
                            <Option key={5} value='5'>5</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem
                label="所属页面"
                {...formItemLayout}>
                    {getFieldDecorator('pageType', {
                        rules: [{required: true, message: '请选择所属页面', pattern: /^\S+$/}],
                        initialValue: pageType,
                    })(
                        <Select style={{width: '150px'}}>
                            <Option key={1} value="INDEX">首页</Option>
                            <Option key={2} value="TRAINING">战队赛</Option>
                            <Option key={3} value="PERSONAL_TRAINING">单排赛</Option>
                            <Option key={3} value="MIXED_TEAM">四排赛</Option>
                            <Option key={4} value="TRAIL">试训</Option>
                            <Option key={5} value="CIRCLE">圈子推广图</Option>
                            <Option key={6} value="SENSITIVITY">灵敏度H5页面</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label ="说明："{...formItemLayout} >
                    若选择圈子页面则只显示序号最前的第一张图片
                </FormItem>
                <FormItem
                    label="链接类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('jumpType', {
                        rules: [{required: true, message: '请选择链接类型', pattern: /^\S+$/}],
                        initialValue: jumpType,
                    })(
                        (
                            <Select style={{width: '150px'}} defaultValue="OUTER" onChange={this.changeType}>
                                <Option value='INNER'>内链</Option>
                                <Option value='OUTER'>外链</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem
                    label="外链链接"
                    {...formItemLayout}
                >
                    {getFieldDecorator('outerUrl', {
                        initialValue: outerUrl,
                    })(
                        <Input disabled={isOuter} placeholder="请输入外链"/>,
                    )}
                </FormItem>
                <FormItem
                    label="外链跳外部"
                    {...formItemLayout}
                >
                    {getFieldDecorator('jumpOutside', {
                        initialValue: jumpOutside,
                    })(
                        <Select style={{width: '150px'}} defaultValue="OUTER" onChange={this.changeType} disabled={isOuter}>
                            <Option value={true}>是</Option>
                            <Option value={false}>否</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="内链跳转类型"
                    {...formItemLayout}
                >
                    {getFieldDecorator('innerJumpType', {
                        initialValue: innerJumpType,
                    })(
                        (
                            <Select style={{width: '300px'}} disabled={!isOuter} onChange={this.changeDescription}>
                                {innerJumpTypes.map((item, index) => {
                                    return (
                                        <Option key={item.name} value={item.value}>{item.name}</Option>
                                    )
                                })
                                }
                            </Select>

                        )
                    )}
                </FormItem>
                <FormItem
                    label="说明"
                    {...formItemLayout}>
                    {jumpDescription}
                </FormItem>
                <FormItem
                    label="Attach"
                    {...formItemLayout}
                >
                    {getFieldDecorator('attach', {
                        initialValue: attach,
                    })(
                        <TextArea row={3} disabled={!isOuter}/>
                    )}
                </FormItem>
                <FormItem
                    label="描述"
                    {...formItemLayout}
                >
                    {getFieldDecorator('description', {
                        initialValue: description,
                    })(
                        <Input placeholder="请输入描述（选填）"/>,
                    )}
                </FormItem>
                <FormItem
                    label="是否启用"
                    {...formItemLayout}
                >
                    {getFieldDecorator('enable', {
                        rules: [{required: true, message: '请选择是否启用', pattern: /^\S+$/}],
                        initialValue: enable,
                    })(
                        (
                            <Select style={{width: '150px'}}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(BannerForm);
