import api from '../utils/api';
import {map} from 'lodash/fp';

class PayRequest {

    /**
     * 获取支付订单列表
     * @returns {*}
     */
    static getPayment(page,size,userId,status,dateRange,attachType,orderId,payChannel) {
        console.log(dateRange)
        let endTime = null,startTime = null;
        if (dateRange !== undefined && dateRange.length !== 0) {
            console.log(dateRange)
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0];
            endTime = dateRange[1] == undefined ? '' : dateRange[1];
        }
        return api.get('/admin/accounts/payments',{page,size,userId,status,startTime,endTime,attachType,orderId,payChannel});
    }

    /**
     * 获取列表统计金额
     * @returns {*}
     */
    static getMoney(dateRange){
        let endTime = null,startTime = null;
        if (dateRange !== undefined && dateRange.length !== 0) {
            console.log(dateRange)
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0] +" 00:00:00";
            endTime = dateRange[1] == undefined ? '' : dateRange[1] +" 23:59:59";
        }
        return api.get('/admin/accounts/statisticalMoney',{startTime,endTime})
    }
}
export default PayRequest;