
import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_LADDER_TRAINING_LIST,
    CREATE_LADDER_TRAINING,
    UPDATE_LADDER_TRAINING,
    DELETE_LADDER_TRAINING,
    UPDATE_LADDER_TRAINING_TOP,
    UPDATE_LADDER_VIDEO_CONFIG,
    GET_LADDER_JOIN_PLAYER,
    ADD_LADDER_JOIN_PLAYER,
    UPDATE_LADDER_PLAYER_SUBSTITUTE,
    UPDATE_LADDER_PLAYER_ORDER,
    UPDATE_LADDER_PLAYER_ASSIGN,
    DELETE_LADDER_PLAYER_SIGN_UP,
    LADDER_TRAINING_NOTICE,
    GET_LADDER_TEAM_GRADE,
    GET_LADDER_PERSON_GRADE,
    GET_LADDER_PERSON_GRADE_DETAIL
} from './actionTypes';
import LadderTrainingRequest from "../../request/LadderTrainingRequest";

// 天梯四排赛-获取训练赛列表
export const getLadderTrainingList = (params) => (createAsyncAction(GET_LADDER_TRAINING_LIST, () => (LadderTrainingRequest.getLadderTrainingList(params))));

// 天梯四排赛-创建训练赛
export const createLadderTraining = (params) => (createAsyncAction(CREATE_LADDER_TRAINING, () => (LadderTrainingRequest.createLadderTraining(params))));

// 天梯四排赛-修改训练赛信息
export const updateLadderTraining = (params) => (createAsyncAction(UPDATE_LADDER_TRAINING, () => (LadderTrainingRequest.updateLadderTraining(params))));

// 天梯四排赛-删除训练赛
export const deleteLadderTraining = (trainingId) => (createAsyncAction(DELETE_LADDER_TRAINING, () => (LadderTrainingRequest.deleteLadderTraining(trainingId))));

// 天梯四排赛-训练赛置顶
export const updateLadderTrainingTop = (trainingId) => (createAsyncAction(UPDATE_LADDER_TRAINING_TOP, () => (LadderTrainingRequest.updateLadderTrainingTop(trainingId))));

// 天梯四排赛-配置回放视频
export const updateLadderVideoConfig=(trainingId, params)=>(createAsyncAction(UPDATE_LADDER_VIDEO_CONFIG, () => (LadderTrainingRequest.updateLadderVideoConfig(trainingId, params))));

// 天梯四排赛-获取参与详情列表（报名选手列表）
export const getLadderJoinPlayerList = (params) => (createAsyncAction(GET_LADDER_JOIN_PLAYER, () => (LadderTrainingRequest.getLadderJoinPlayerList(params))));

// 天梯四排赛-（批量）添加报名选手
export const addLadderJoinPlayer = (params) => (createAsyncAction(ADD_LADDER_JOIN_PLAYER, () => (LadderTrainingRequest.addLadderJoinPlayer(params))));

// 天梯四排赛-将选手设为替补（踢出当前队伍）
export const updateLadderPlayerSubstitute = (params) => (createAsyncAction(UPDATE_LADDER_PLAYER_SUBSTITUTE, () => (LadderTrainingRequest.updateLadderPlayerSubstitute(params))));

// 天梯四排赛-变更选手次序值
export const updateLadderPlayerOrder = (params) => (createAsyncAction(UPDATE_LADDER_PLAYER_ORDER, () => (LadderTrainingRequest.updateLadderPlayerOrder(params))));

// 天梯四排赛-修改选手坑位号（指派队伍）
export const updateLadderPlayerAssign = (params) => (createAsyncAction(UPDATE_LADDER_PLAYER_ASSIGN, () => (LadderTrainingRequest.updateLadderPlayerAssign(params))));

// 天梯四排赛-移除报名
export const deleteLadderPlayerSignUp = (params) => (createAsyncAction(DELETE_LADDER_PLAYER_SIGN_UP, () => (LadderTrainingRequest.deleteLadderPlayerSignUp(params))));

// 天梯四排赛-推送通知给选手
export const ladderTrainingNotice=(params)=>(createAsyncAction(LADDER_TRAINING_NOTICE, () => (LadderTrainingRequest.ladderTrainingNotice(params))));

// 天梯四排赛-获取队伍战绩列表
export const getLadderTeamGrade = (trainingId, params) => (createAsyncAction(GET_LADDER_TEAM_GRADE, () => (LadderTrainingRequest.getLadderTeamGrade(trainingId, params))));

// 天梯四排赛-获取个人战绩列表
export const getLadderPersonGrade = (trainingId, params) => (createAsyncAction(GET_LADDER_PERSON_GRADE, () => (LadderTrainingRequest.getLadderPersonGrade(trainingId, params))));

// 天梯四排赛-获取个人战绩列表
export const getLadderPersonGradeDetail = (trainingId, params) => (createAsyncAction(GET_LADDER_PERSON_GRADE_DETAIL, () => (LadderTrainingRequest.getLadderPersonGrade(trainingId, params))));
