import React from "react";
import {Button, Table, Upload, message, Card, Modal} from "antd";
import "./index.scss"
import {
    updateGrade,
    getTraining
} from "../../modules/training/action";
import {
    getLadderTeamGrade
} from "../../modules/ladder-training/action";
import {
    getGameList
} from "../../modules/team/action";
import {connect} from "react-redux";
import {injectIntl} from "react-intl-context";
import Cookie from "js-cookie";
import { ACCESS_TOKEN } from 'modules/authentication/constants';
import TrainingFilter from "../training-management/components/TrainingFilter";
import TrainingManagement from "../training-management";
const getAuthorizationHeader = (headers) => {
    try {
        let accesstokenJSONStr = Cookie.get(ACCESS_TOKEN);
        const accessToken = accesstokenJSONStr ? JSON.parse(accesstokenJSONStr) : null;
        if (headers && accessToken) {
            headers.Authorization = accessToken.token;
        }
        return headers;
    } catch (error) {
        return '';
    }
};

var color = ["#ffebcd", "#d1eeee", "#bdbdbd", "#5f9ea0", "#9f79ee"];

class GradeManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            size: 100,
            editState:false,
            changeObj:{},
            grades:[],
            show:false
        }
        this.header={}
    }


    componentWillMount() {
        let { getGameList } = this.props;

        this.header = getAuthorizationHeader({});
        this.requestGrade();
        getGameList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.editGradeStatus!==this.props.editGradeStatus){
            this.requestGrade();
            this.showEdit(false)
            this.setState({grades:[]})
        }
    }

    requestGrade = () => {
        let {trainingId} = this.props.match.params;
        let {page, size} = this.state;
        let {getLadderTeamGrade} = this.props;

        getLadderTeamGrade(window.atob(trainingId), {page, size});
    }

    onReturn =(page) =>{
        let param = {}
        param["page"] = page;
        param["size"] = 5;
        param["isManager"] = true;
        getTraining(param)
        // this.setState({
        //     show : true
        // })
    }

    render() {
        let {ladderTeamGrade} = this.props;
        let {gameNum} = this.props.match.params;
        let {page} = this.props.match.params;
        let game = [...new Array(parseInt(window.atob(gameNum))).keys()]
        console.log(game)
        let gradeData = ladderTeamGrade.data ? ladderTeamGrade.data.map((v, i) => {
            let key = {};
            v.performanceData.map((item, index) => {
                Object.keys(item).map(val => {
                    console.log(item[val] + ",aaaa")
                    key[val + item.gameSequenceNo] = item[val];
                    return null
                });
                return null
            });
            return (
                {
                    id: i + 1,
                    totalIntegral: v.totalIntegral,
                    totalKill: v.totalKill,
                    chickens:v.chickens,
                    teamId: v.teamId,
                    name: v.name,
                    showId:v.showId,
                    game: v.game,
                    joinNum: v.joinNum,
                    totalPosition: v.totalPosition,
                    receivePoints: v.receivePoints,
                    ...key
                }
            )
        }) : []
        return (
            <div>
                <Table
                    columns={this.getTeamColumns().concat(...game.map((v, i) => {
                        return (
                            this.getBoColumns(i + 1)
                        )
                    }))}
                    dataSource={gradeData}
                    bordered
                    pagination={false}
                    scroll={{x: 600 * game.length}}
                />
            </div>
        )
    }

    showEdit=(type)=>{
        this.setState({
            edit:type
        });
    }

    // submitChange=(trainingId)=>{
    //     let {grades}  =this.state;
    //     let {updateGrade} = this.props;
    //     updateGrade({trainingId,teamGradeItemCommands:grades});
    // }
    //
    // changeTotalValue=(e,type,record)=>{
    //     let val = e.currentTarget.value;
    //     let {grades} = this.state;
    //     let curGrades = grades.filter(item=>item.teamId===record.teamId);
    //     if(curGrades.length>0){
    //         curGrades[0][type]=val;
    //     }else{
    //         grades.push({
    //             teamId:record.teamId,
    //             [type]:val
    //         });
    //     }
    //     console.log(grades)
    //     this.setState({grades})
    // }
    //
    // changeValue=(e,num,type,record)=>{
    //     let atr = type.split(num)[0];
    //     let val = e.currentTarget.value;
    //     console.log(val)
    //     let {grades} = this.state;
    //     let curGrades = grades.filter(item=>item.teamId===record.teamId);
    //     if(curGrades.length>0){
    //         let performanceData = curGrades[0].performanceData;
    //         if(performanceData){
    //             let performance = performanceData.filter(v=>v.gameSequenceNo === num);
    //             if(performance.length>0){
    //                 performance[0][atr] = val;
    //             }else{
    //                 curGrades[0].performanceData.push({gameSequenceNo:num,[atr]:val})
    //             }
    //         }else{
    //             curGrades[0]["performanceData"] = [{gameSequenceNo:num,[atr]:val}]
    //         }
    //     }else{
    //         grades.push({
    //             teamId:record.teamId,
    //             performanceData:[
    //                 {
    //                     gameSequenceNo:num,
    //                     [atr]:val
    //                 }
    //             ]
    //         });
    //     }
    //    this.setState({grades});
    // }

    getTeamColumns = (item) => {
        let {games} = this.props;
        let {trainingId,trainingName,page} = this.props.match.params;

        return (
            [{
                title: 
                <div>
                    <span className="trainingTitle">赛事ID：{window.atob(trainingId)}</span>
                    <span className="trainingTitle">赛事名称：{trainingName}</span>
                        {/*<Button type="primary" disabled={this.state.grades.length===0} onClick={()=>{this.submitChange(trainingId)}}>提交</Button>*/}
                    <Button 
                        type="primary" 
                        style={{marginLeft: 30}} 
                        onClick={() => {
                            window.open(`/api/admin/mixed_team/training/export/${window.atob(trainingId)}`)
                        }}
                    >下载成绩模板</Button>
                    <Upload
                        action="/api/admin/mixed_team/training/team/file"
                        listType="text"
                        headers={this.header}
                        name = 'excelFile'
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        data = {{ trainingId: window.atob(trainingId) }}
                        onChange={this.handleChange}
                    >
                        <Button type="primary" style={{marginLeft: 30}}>上传战绩</Button>
                    </Upload>
                </div>,
                className:"bo1",
                children:
                    [
                        {
                            title: '名次',
                            dataIndex: 'id',
                            key: 'id',
                            width: 150,
                        },
                        {
                            title: '队伍/ID',
                            dataIndex: 'teamId',
                            key: 'teamId',
                            width: 300,
                            render: (text, record, index) => {
                                console.log(record)
                                return record.name + "/" + record.teamId
                            }
                        },
                        {
                            title: '赛事类型',
                            dataIndex: 'game',
                            key: 'game',
                            width: 150,
                            render: (text, record, index) => {
                                return games.filter((item) => item.code == record.game).length > 0 ? games.filter((item) => item.code == record.game)[0].gameName : ""
                            }
                        },
                        {
                            title: '总积分',
                            dataIndex: 'totalIntegral',
                            key: 'totalIntegral',
                            width: 150,
                            render: (text, record, index) => {
                                return (text)
                            }
                        },
                        {
                            title: '总淘汰',
                            dataIndex: 'totalKill',
                            key: 'totalKill',
                            width: 150,
                            render: (text, record, index) => {
                                return (text)
                            }
                        }
                        ,
                        {
                            title: '蛋糕数',
                            dataIndex: 'chickens',
                            key: 'chickens',
                            width: 150,
                            render: (text, record, index) => {
                                return (text)
                            }
                        }
                        ,
                        {
                            title: '参赛场次',
                            dataIndex: 'joinNum',
                            key: 'joinNum',
                            width: 150,
                            render: (text, record, index) => {
                                return (text)
                            }
                        }
                        ,
                        {
                            title: '总排名分',
                            dataIndex: 'totalPosition',
                            key: 'totalPosition',
                            width: 150,
                            render: (text, record, index) => {
                                return (text)
                            }
                        }
                        ,
                        {
                            title: '获得加分',
                            dataIndex: 'receivePoints',
                            key: 'receivePoints',
                            width: 150,
                            render: (text, record, index) => {
                                return (text)
                            }
                        }
                    ]
            }]
        )
    }

    handleChange=(e)=>{
        if(e.file.status==='done'){
            console.log(e)
            message.success('上传成功');
            this.showEdit(false)
            this.requestGrade()
        }else if(e.file.status==='error'){
            console.log(e)
            message.error(e.file.response.msg);
        }
    }

    getBoColumns = (num) => {
        let {trainingId,page} = this.props.match.params;
        var paramData = {trainingId: window.atob(trainingId)};
        return (
            [{
                className:"bo1",
                children: [
                    {
                        title: `第${num}局名次`,
                        dataIndex: 'ranking' + num,
                        key: 'ranking' + num,
                        width: 150,
                        render: (text, record, index) => {
                            return (text)
                        },
                        onCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        },
                        onHeaderCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        }
                    },
                    {
                        title: `第${num}局淘汰`,
                        dataIndex: 'teamKill' + num,
                        key: 'teamKill' + num,
                        width: 150,
                        render: (text, record, index) => {
                            return text
                        },
                        onCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        },
                        onHeaderCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        }
                    },
                    {
                        title: `第${num}局加分`,
                        dataIndex: 'bonusPoints' + num,
                        key: 'bonusPoints' + num,
                        width: 150,
                        render: (text, record, index) => {
                            return text
                        },
                        onCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        },
                        onHeaderCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        }
                    },
                    {
                        title: `第${num}局淘汰分`,
                        dataIndex: 'killIntegral' + num,
                        key: 'killIntegral' + num,
                        width: 150,
                        render: (text, record, index) => {
                            return text
                        },
                        onCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        },
                        onHeaderCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        }
                    },
                    {
                        title: `第${num}局排名分`,
                        dataIndex: 'rankingIntegral' + num,
                        key: 'rankingIntegral' + num,
                        width: 150,
                        render: (text, record, index) => {
                            return text
                        },
                        onCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        },
                        onHeaderCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        }
                    },
                    {
                        title: `第${num}局积分`,
                        dataIndex: 'integral' + num,
                        key: 'integral' + num,
                        width: 150,
                        render: (text, record, index) => {
                            return text
                        },
                        onCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        },
                        onHeaderCell: () => {
                            return {
                                style: {
                                    backgroundColor: color[num % 5]
                                }
                            }
                        }
                    }],
            }
            ]
        )
    }

}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
        ...state.Team,
        ...state.LadderTraining
    }
};

const mapDispatchToProps = ({
    getLadderTeamGrade,getGameList,
    updateGrade,
    getTraining
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(GradeManagement));
