import createReducer from 'utils/createReducer';
import {
    GET_LADDER_TRAINING_LIST,
    GET_LADDER_JOIN_PLAYER,
    GET_LADDER_TEAM_GRADE,
    GET_LADDER_PERSON_GRADE,
    GET_LADDER_PERSON_GRADE_DETAIL
} from './actionTypes';

const defaultState = () => ({
    ladderTrainingList: {
        data: [],
        total: 0,
    }, // 天梯四排赛-训练赛列表
    ladderJoinPlayer: {
        data: [],
        total: 0,
    }, // 天梯四排赛-参与详情列表
    ladderTeamGrade: {
        data: [],
        total: 0,
    }, // 天梯四排赛-队伍战绩列表
    ladderPersonGrade: {
        data: [],
        total: 0,
    }, // 天梯四排赛-个人战绩列表
});

const onGetLadderTrainingListSuccess = (state, {payload}) => ({
    ...state,
    ladderTrainingList: payload,
});

const onGetLadderJoinPlayerSuccess = (state, {payload}) => ({
    ...state,
    ladderJoinPlayer: payload,
});

const onGetLadderTeamGradeSuccess = (state, {payload}) => ({
    ...state,
    ladderTeamGrade: payload,
});

const onGetLadderPersonGradeSuccess = (state, {payload}) => ({
    ...state,
    ladderPersonGrade: payload,
});

const onGetLadderPersonGradeDetailSuccess = (state, { payload }) => ({
    ...state,
    ladderPersonGrade: {
      data: state.ladderPersonGrade.data.map((v) => {
        let gamerIdArr = payload.data.map((v) => v.gamerId);
        if (gamerIdArr.indexOf(v.gamerId) !== -1) {
          v["grades"] = payload.data.map((item) => ({
            gameKill: item.gameKill,
            dieNums: item.dieNums,
            killIntegral: item.killIntegral,
            rankingIntegral: item.rankingIntegral,
            gameSequenceNo: item.gameSequenceNo,
            isEatChicken: item.isEatChicken,
            position: item.position,
          }));
        }
        return v;
      }),
      total: state.ladderPersonGrade.total,
    },
  });

export default createReducer(defaultState, {
    [`${GET_LADDER_TRAINING_LIST}_SUCCESS`]: onGetLadderTrainingListSuccess,
    [`${GET_LADDER_JOIN_PLAYER}_SUCCESS`]: onGetLadderJoinPlayerSuccess,
    [`${GET_LADDER_TEAM_GRADE}_SUCCESS`]: onGetLadderTeamGradeSuccess,
    [`${GET_LADDER_PERSON_GRADE}_SUCCESS`]: onGetLadderPersonGradeSuccess,
    [`${GET_LADDER_PERSON_GRADE_DETAIL}_SUCCESS`]: onGetLadderPersonGradeDetailSuccess,
});
