import React from 'react';
import {Button, Card, Col, Form, message, Modal, Row, Table} from 'antd';
import {
    addTeamMember,
    getTeamMemberList,
    quiteTeamMember,
    updateTeamLeader,
    updateTeamMemberType,
    updateTeamMemberPosition
} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import TeamMemberFilter from "./TeamMemberFilter";
import AddTeamMemberModel from "./AddTeamMemberModel";
import TeamPositionModel from "./TeamPositionModel";
import {TeamMemberType, enumToArray} from "../../../dictionary/dictionary";

const {confirm} = Modal;

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class TeamMembersModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 1,
            size: 10,
            exhibitionId: '',
            username: '',
            memberType: '',
            isShowAddTeamMemberModel: false,
            isShowSetTeamPosition: false,
            currentMemberId: '',
            floorPosition: '',
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.teamMembers !== this.props.teamMembers) {
            this.setState({
                loading: false
            })
        }
    }


    //刷新界面数据
    onGetTableData = async () => {
        const {value, getTeamMemberList} = this.props;
        const {page, size} = this.state;
        this.setState({
            loading: true
        })
        await getTeamMemberList(value.id, {page, size});
    }

    onPageChangeEvent = async (current, pageSize) => {
        const {value, getTeamMemberList} = this.props;
        await getTeamMemberList(value.id, {
            page: current, size: pageSize, exhibitionId: this.state.exhibitionId ? this.state.exhibitionId : null,
            username: this.state.username ? this.state.username : null,
            memberType: this.state.memberType ? this.state.memberType : null
        });
        this.setState({
            page: current,
            size: pageSize,
        });
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.visible !== this.props.visible) {
    //         console.log(nextProps.value)
    //         this.onGetTableData();
    //     }
    // }

    confirmQuitTeam = (id) => {
        let _this = this;
        confirm({
            content: '确认要踢出战队吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onQuitTeamEvent(id)
            },
            onCancel() {
            },

        });
    }

    onQuitTeamEvent = async (id) => {
        const {value, quiteTeamMember} = this.props;
        await quiteTeamMember(value.id, id);
        await this.onGetTableData();
    }

    comfirmChangeMemberType = (id, params) => {
        let _this = this;
        confirm({
            content: '确认要修改队员职位吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onChangeMemberType(id, params)
            },
            onCancel() {
            },

        });
    }

    comfirmChangeTeamLeader = (id) => {
        let _this = this;
        confirm({
            content: '确认要修改战队队长吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onChangeTeamLeader(id)
            },
            onCancel() {
            },

        });
    }

    onChangeTeamLeader = async (id) => {
        const {updateTeamLeader} = this.props;
        await updateTeamLeader(id);
        await this.onGetTableData();
    }


    onChangeMemberType = async (id, params) => {
        const {updateTeamMemberType} = this.props;
        await updateTeamMemberType(id, params);
        await this.onGetTableData();
    }

    onDisBandTeam = async (id) => {
        let _this = this;
        confirm({
            content: '确认要解散战队吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                const {onSuccess} = _this.props;
                onSuccess && onSuccess(id);
            },
            onCancel() {
            },

        });
    }


    //条件查找动态方法
    onFilterTeamMemberEvent = async (exhibitionId, username, memberType) => {
        const {value, getTeamMemberList} = this.props;
        const {size} = this.state;
        await getTeamMemberList(value.id, {
            page: 1,
            size: size,
            exhibitionId: exhibitionId ? exhibitionId : null,
            username: username ? username : null,
            memberType: memberType ? memberType : null
        });

        this.setState({
                page: 1,
                exhibitionId: exhibitionId,
                username: username,
                memberType: memberType
            }
        )
    }

    onAddTeamMember = async (exhibitionId)=>{
        const {value, addTeamMember} = this.props;
        const {isShowAddTeamMemberModel} =this.state;
        await addTeamMember(value.id, exhibitionId);
        await this.onGetTableData();
        message.success('添加成员成功');
        this.setState({isShowAddTeamMemberModel: !isShowAddTeamMemberModel});
    }

    setTeamPosition=(memberId, floorPosition)=>{
        this.setState({
            isShowSetTeamPosition: true,
            currentMemberId:memberId,
            floorPosition
        });
    }

    onSetTeamPosition = async (floorPosition)=>{
        let params={
            ...floorPosition,
            memberId:this.state.currentMemberId
        }
       // alert(JSON.stringify(params))
        const{updateTeamMemberPosition}=this.props;
        const {isShowSetTeamPosition} = this.state;
        await updateTeamMemberPosition(params);
        await this.onGetTableData();
        message.success('设置场上位置成功');
        this.setState({isShowSetTeamPosition: !isShowSetTeamPosition})
    }

    renderTableTitle = () => {
        const {value} = this.props;
        return (
            <Row type="flex" justify="end">
                <Col>
                    <Button style={{marginRight: 10}} type="primary"
                            onClick={() => this.setState({isShowAddTeamMemberModel: true})}>添加成员</Button>
                    <Button style={{marginRight: 10}} type="danger"
                            onClick={() => this.onDisBandTeam(value.id)}>解散队伍</Button>
                </Col>
            </Row>
        )
    }
    

    render() {
        let {visible, teamMembers} = this.props;
        console.log('11111111',JSON.stringify(teamMembers.data))
        const { page, size, isShowAddTeamMemberModel, isShowSetTeamPosition, floorPosition } = this.state;
        return (
            <div>
                <Modal
                    width={"60%"}
                    visible={visible}
                    title="查看成员"
                    closable={false}
                    footer={<Button onClick={this.props.onClose}>关闭</Button>}
                >

                    <div>
                        <Card style={{marginBottom: 30}}>
                            <TeamMemberFilter dataSource={{}} onCommit={this.onFilterTeamMemberEvent}/>
                        </Card>
                        <Card>
                            <Table
                                title={this.renderTableTitle}
                                dataSource={teamMembers ? teamMembers.data : []}
                                columns={this.getTableColumns()}
                                bordered
                                pagination={{
                                    total: teamMembers ? teamMembers.total : 0,
                                    current: page,
                                    pageSize: size,
                                    onChange: this.onPageChangeEvent,
                                    showTotal:(total)=>{
                                        return `总计 ${total}条数据`;
                                    }
                                }}
                            />
                        </Card>
                    </div>
                </Modal>
                {isShowAddTeamMemberModel ? <AddTeamMemberModel
                    visible={isShowAddTeamMemberModel}
                    onSuccess={this.onAddTeamMember}
                    onClose={() => {
                        this.setState({
                            isShowAddTeamMemberModel: false,
                        });
                    }}
                />: null}

                {isShowSetTeamPosition && <TeamPositionModel
                    visible={isShowSetTeamPosition}
                    floorPosition={floorPosition}
                    onSuccess={this.onSetTeamPosition}
                    onClose={()=>{
                        this.setState({
                            isShowSetTeamPosition:false,
                        })
                    }}
                />}
            </div>
        );
    }

    getTableColumns = () => [{
        title: '类型',
        dataIndex: 'jobType',
        align: 'center',
        key: 'jobType',
        render: (row, text) => (
            <span>{text && TeamMemberType[text.jobType]}</span>
        )
    }, {
        title: '用户ID',
        dataIndex: 'gamerId',
        align: 'center',
        key: 'gamerId',
    },  {
        title: '用户展示ID',
        dataIndex: 'showGamerId',
        align: 'center',
        key: 'showGamerId',
    }, {
        title: '用户名',
        dataIndex: 'gamerName',
        align: 'center',
        key: 'gamerName',
    }, {
        title: '操作',
        dataIndex: 'details',
        align: 'center',
        key: 'details',
        render: (row, text) => (
            <div>
                {/*{text.jobType == "LEADER_ALTERNATE" ?*/}
                {/*    <span><Button style={{marginRight: 5}} type="primary"*/}
                {/*                  onClick={() => this.comfirmChangeMemberType(text.teamMemberId, {jobType: "FORMAL"})}*/}
                {/*                  color="blue">转为正式</Button></span>*/}
                {/*        :  text.jobType == "LEADER" ?*/}
                {/*        <span>*/}
                {/*        <Button style={{marginRight: 5}} type="primary"*/}
                {/*                onClick={() => this.comfirmChangeMemberType(text.teamMemberId, {jobType: "ALTERNATE"})}*/}
                {/*                color="blue">转为替补</Button></span>*/}
                {/*    :*/}
                {/*    <span><Button style={{marginRight: 5}} type="primary"*/}
                {/*                  onClick={() => this.comfirmChangeTeamLeader(text.teamMemberId)}*/}
                {/*                  color="blue">设为队长</Button>*/}
                {/*        <Button style={{marginRight: 5}} type="primary"*/}
                {/*                onClick={() => this.comfirmChangeMemberType(text.teamMemberId, {jobType: text.jobType == "FORMAL" ? "ALTERNATE" : text.jobType == "ALTERNATE" ? "FORMAL" : ""})}*/}
                {/*                color="blue">{text.jobType == "FORMAL" ? "转为替补" : text.jobType == "ALTERNATE" ? "转为正式" : ""}</Button></span>}*/}
                {
                    !text.teamManager&&
                    <Button style={{marginRight: 5, marginBottom: 5}} type={text.floorPosition?"dashed":"primary"}
                        onClick={() => this.setTeamPosition(text.teamMemberId, text.floorPosition)} >{text.floorPosition?text.floorPosition:'设置场上位置'}</Button>
                }
                {
                    enumToArray(TeamMemberType).filter(item=>item.value!=text.jobType).map(item => {
                      return  <Button style={{marginRight: 5, marginBottom: 5}} type="primary"
                                      onClick={() => this.comfirmChangeMemberType(text.teamMemberId, {jobType: item.value})}
                                     color="blue">设为{item.name}</Button>
                    })
                }
                <Button style={{marginRight: 5}} type="danger"
                        onClick={() => this.confirmQuitTeam(text.teamMemberId)}
                        color="red">踢出战队</Button>
            </div>
        ),
    },]
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
    }
};
const mapDispatchToProps = ({
    getTeamMemberList,
    updateTeamMemberType,
    addTeamMember,
    updateTeamLeader,
    quiteTeamMember,
    updateTeamMemberPosition
});
TeamMembersModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(TeamMembersModel)));
