import React from 'react';
import {Cascader, DatePicker, Form, Input, Modal, Select} from 'antd';
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../components/upload";
import moment from "moment";
import AreaSelect from '../../components/area';

const {confirm} = Modal;
const FormItem = Form.Item;
const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 16},
};
const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class AddUserModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                confirm({
                    content: '确认要新增用户吗?',
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        Object.keys(values).map(item => {
                            if (values[item] != undefined) {
                                if (item === "createTime") {
                                    values[item] = values[item].format('YYYY-MM-DDTHH:mm:00');
                                    return null;
                                }
                            }
                            return null;
                        });
                        onSuccess && onSuccess(values)
                    },
                    onCancel() {
                    },

                });
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible} = this.props;

        return (
            <Modal
                visible={visible}
                title="选手推荐配置"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem
                        label="推荐标题"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('recommendationTitle', {
                            rules: [{required: true, message: '请填写推荐标题'}],
                        })(
                            <Input placeholder="请填写推荐标题"/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="用户ID"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('exhibitionId', {
                            rules: [{required: true, message: '请填写用户展示ID'}],
                        })(
                            <Input placeholder="请填写用户展示ID"/>,
                        )}
                    </FormItem>

                    <FormItem label="选手评分"{...formItemLayout}>
                        {getFieldDecorator('playerRating', {
                            rules: [{
                                required: true,
                                message: '选手评分必须为两位小数的数字',
                                pattern:  /^\+?(\d*\.\d{2})$/
                            }],
                            initialValue: 0.00
                        })(
                            <Input placeholder="请填写选手评分"/>,
                        )}
                    </FormItem>

                    <FormItem label="推荐权重"{...formItemLayout}>
                        {getFieldDecorator('recommendationWeight', {
                            rules: [{
                                required: true,
                                message: '选推荐权重必须为数字',
                                pattern: /^\d+$/
                            }],
                            initialValue: 0
                        })(
                            <Input placeholder="请填写推荐权重"/>,
                        )}
                    </FormItem>
                </Form>
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.users,
    }
};
const mapDispatchToProps = ({});
AddUserModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(AddUserModel)));
