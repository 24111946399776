/**
 * 查找训练赛
 * @type {string}
 */
export const GET_TRAINING　= "GET_TRAINING";

/**
 * 查找品牌赛事
 * @type {string}
 */
export const GET_BRAND = "GET_BRAND";

/**
 * 查找品牌赛事
 * @type {string}
 */
 export const GET_FOURSOME_BRAND = "GET_FOURSOME_BRAND";

/**
 *
 * 修改赛事信息
 * @type {string}
 */
export const EDIT_TRAINING = "EDIT_TRAINING";

/**
 * 创建训练赛
 * @type {string}
 */
export const CREATE_TRAINING = "CREATE_TRAINING"

/**
 * 删除训练赛
 * @type {string}
 */
export const DELETE_TRAINING = "DELETE_TRAINING"

/**
 * 战绩
 * @type {string}
 */
export const GET_GRADE = "GET_GRADE";

/**
 * 更新战绩
 * @type {string}
 */
export const UPDATE_GRADE = "UPDATE_GRADE";

export const GET_ADDTEAM = "GET_ADDTEAM";

export const CHECK_SIGN_UP="CHECK_SIGN_UP";

export const DELETE_SIGN_UP="DELETE_SIGN_UP";

export const GET_JOIN_TEAM="GET_JOIN_TEAM";

export const ADD_JOIN_TEAMS="ADD_JOIN_TEAMS";

export const TRAINING_NOTICE="TRAINING_NOTICE";

export const UPDATE_TRAINING_SIGN_UP_POSITION="UPDATE_TRAINING_SIGN_UP_POSITION";

export const GET_PERSONAL_GRADE = "GET_PERSONAL_GRADE";

export const GET_PERSONAL_GRADE_DETAIL = "GET_PERSONAL_GRADE_DETAIL";

export const GET_GAME_MAPS = "GET_GAME_MAPS";

export const ADD_VIDEO_CONFIG = "ADD_VIDEO_CONFIG";

export const GET_VIDEO_CONFIG = "GET_VIDEO_CONFIG";

export const UPDATE_VIDEO = "UPDATE_VIDEO";

export const QUERY_VIDEO_LIST = "QUERY_VIDEO_LIST";

export const GET_PLAY_AUTH = "GET_PLAY_AUTH";

export const DELETE_VIDEO = "DELETE_VIDEO";

export const GET_TRAINING_TEMPLATE = "GET_TRAINING_TEMPLATE";

export const GET_TRAINING_TEMPLATE_DICTIONARIES = "GET_TRAINING_TEMPLATE_DICTIONARIES";

export const GET_TRAINING_TEMPLATE_LIST = "GET_TRAINING_TEMPLATE_LIST";

export const ADD_TRAINING_TEMPLATE = "ADD_TRAINING_TEMPLATE";

export const MODIFY_TRAINING_TEMPLATE = "MODIFY_TRAINING_TEMPLATE";

export const DELETE_TRAINING_TEMPLATE = "DELETE_TRAINING_TEMPLATE";

export const UPDATE_TRAINING_TOP = "UPDATE_TRAINING_TOP";

export const LIFT_GROUP = "LIFT_GROUP";

export const GET_PEL_LEAGUE_DIC = "GET_PEL_LEAGUE_DIC";

export const GET_PEL_TEAM_INTEGRAL = "GET_PEL_TEAM_INTEGRAL";

export const UPDATE_PEL_TEAM_INTEGRAL = "UPDATE_PEL_TEAM_INTEGRAL";

export const GET_PEL_TEAM_WEEKLY_INTEGRAL = "GET_PEL_TEAM_WEEKLY_INTEGRAL";
export const UPDATE_PEL_TEAM_WEEKLY_INTEGRAL = "UPDATE_PEL_TEAM_WEEKLY_INTEGRAL";
export const GET_SORT_FIELD_DIC = "GET_SORT_FIELD_DIC";
export const GET_PEL_PLAYER_RANK = "GET_PEL_PLAYER_RANK";
export const UPDATE_PEL_PLAYER_RANK = "UPDATE_PEL_PLAYER_RANK";
export const GET_PEL_TEAM_RANK = "GET_PEL_TEAM_RANK";
export const GET_TRAINING_TEAM_RANK = "GET_TRAINING_TEAM_RANK";
export const UPDATE_TRAINING_TEAM_INTEGRAL = "UPDATE_TRAINING_TEAM_INTEGRAL";
export const GET_TRAINING_PLAYER_RANK = "GET_TRAINING_PLAYER_RANK";
export const UPDATE_TRAINING_PLAYER_RANK = "UPDATE_TRAINING_PLAYER_RANK";
export const GET_RANK_BASE_DATA_CONFIG = "GET_RANK_BASE_DATA_CONFIG";
export const CREATE_RAND_DATA_CONFIG = "CREATE_RAND_DATA_CONFIG";
export const GET_RANK_DATA_CONFIG = "GET_RANK_DATA_CONFIG";
export const GET_RANK_DATA_CONFIG_BRANDS = "GET_RANK_DATA_CONFIG_BRANDS";

export const DELETE_RANK_DATA_CONFIG = 'DELETE_RANK_DATA_CONFIG'

export const UPDATE_RAND_DATA_CONFIG ='UPDATE_RAND_DATA_CONFIG'

/**
  * 天梯单排赛-获取训练赛列表
  * @type {string}
*/
export const GET_PERSON_TRAINING　= "GET_PERSON_TRAINING";

/**
  * 天梯单排赛-创建训练赛
  * @type {string}
*/
export const CREATE_PERSON_TRAINING = "CREATE_PERSON_TRAINING";

/**
  * 天梯单排赛-修改训练赛信息
  * @type {string}
*/
export const EDIT_PERSON_TRAINING = "EDIT_PERSON_TRAINING";

/**
  * 天梯单排赛-删除训练赛
  * @type {string}
*/
export const DELETE_PERSON_TRAINING = "DELETE_PERSON_TRAINING";

/**
  * 天梯单排赛-训练赛置顶
  * @type {string}
*/
export const UPDATE_PERSON_TRAINING_TOP = "UPDATE_PERSON_TRAINING_TOP";

/**
  * 天梯单排赛-获取参与详情列表（报名选手列表）
  * @type {string}
*/
export const GET_JOIN_PLAYER = "GET_JOIN_PLAYER";

/**
  * 天梯单排赛-（批量）添加报名选手
  * @type {string}
*/
export const ADD_JOIN_PLAYER = "ADD_JOIN_PLAYER";

/**
  * 天梯单排赛-根据状态 获取参与详情列表（报名选手列表）
  * @type {string}
*/
export const GET_JOIN_STATE_PLAYER = "GET_JOIN_STATE_PLAYER";

/**
  * 天梯单排赛-通过选手报名
  * @type {string}
*/
export const CHECK_PERSON_SIGN_UP = "CHECK_PERSON_SIGN_UP";

/**
  * 天梯单排赛-修改报名位置
  * @type {string}
*/
export const UPDATE_PERSON_SIGN_UP_POSITION = "UPDATE_PERSON_SIGN_UP_POSITION";

/**
  * 天梯单排赛-移除选手报名
  * @type {string}
*/
export const DELETE_PERSON_SIGN_UP = "DELETE_PERSON_SIGN_UP";

/**
  * 天梯单排赛-推送消息给选手
  * @type {string}
*/
export const PERSON_TRAINING_NOTICE = "PERSON_TRAINING_NOTICE";

/**
  * 天梯单排赛-获取个人战绩
  * @type {string}
*/
export const GET_PERSON_TRAINING_GRADE = "GET_PERSON_TRAINING_GRADE";

export const GET_PERSON_TRAINING_GRADE_DETAIL = "GET_PERSON_TRAINING_GRADE_DETAIL";

/**
  * 天梯单排赛-配置回放视频
  * @type {string}
*/
export const ADD_PERSON_VIDEO_CONFIG = "ADD_PERSON_VIDEO_CONFIG";

/**
  * 天梯单排赛-获取回放视频列表
  * @type {string}
*/
export const QUERY_PERSON_VIDEO_LIST = "QUERY_PERSON_VIDEO_LIST";

/**
  * 天梯单排赛-编辑回放视频
  * @type {string}
*/
export const UPDATE_PERSON_VIDEO = "UPDATE_PERSON_VIDEO";

/**
  * 天梯单排赛-删除回放视频
  * @type {string}
*/
export const DELETE_PERSON_VIDEO = "DELETE_PERSON_VIDEO";

/**
  * 天梯单排赛-获取品牌列表
  * @type {string}
*/
export const GET_PERSON_BRAND = "GET_PERSON_BRAND";

/**
  * 天梯单排赛-获取赛事模板列表
  * @type {string}
*/
export const GET_PERSON_TRAINING_TEMPLATE_LIST = "GET_PERSON_TRAINING_TEMPLATE_LIST";

/**
  * 天梯单排赛-新增赛事模板
  * @type {string}
*/
export const ADD_PERSON_TRAINING_TEMPLATE = "ADD_PERSON_TRAINING_TEMPLATE";

/**
  * 天梯单排赛-修改赛事模板
  * @type {string}
*/
export const UPDATE_PERSON_TRAINING_TEMPLATE = "UPDATE_PERSON_TRAINING_TEMPLATE";

/**
  * 天梯单排赛-删除赛事模板
  * @type {string}
*/
export const DELETE_PERSON_TRAINING_TEMPLATE = "DELETE_PERSON_TRAINING_TEMPLATE";

/**
  * 天梯单排赛-获取赛事模板字典
  * @type {string}
*/
export const GET_PERSON_TRAINING_TEMPLATE_DICTIONARIES = "GET_PERSON_TRAINING_TEMPLATE_DICTIONARIES";

/**
  * 天梯单排赛-获取赛事模板信息
  * @type {string}
*/
export const GET_PERSON_TRAINING_TEMPLATE = "GET_PERSON_TRAINING_TEMPLATE";

export const UPDATE_KICK_PERMISSIONS ='UPDATE_KICK_PERMISSIONS'

export const UPDATE_LIFT_PERMISSIONS ='UPDATE_LIFT_PERMISSIONS'

export const GET_PERSONAL_RANK_DATA_CONFIG_BRANDS = "GET_PERSONAL_RANK_DATA_CONFIG_BRANDS";
export const CREATE_PERSONAL_RAND_DATA_CONFIG ="CREATE_PERSONAL_RAND_DATA_CONFIG";
export const GET_PERSONAL_RANK_BASE_DATA_CONFIG="GET_PERSONAL_RANK_BASE_DATA_CONFIG";
export const GET_PERSONAL_RANK_DATA_CONFIG="GET_PERSONAL_RANK_DATA_CONFIG";
export const DELETE_PERSONAL_RANK_DATA_CONFIG="DELETE_PERSONAL_RANK_DATA_CONFIG";
export const UPDATE_PERSONAL_RAND_DATA_CONFIG ="UPDATE_PERSONAL_RAND_DATA_CONFIG";

export const GET_PERSONAL_SORT_FIELD_DIC="GET_PERSONAL_SORT_FIELD_DIC";
export const GET_PERSONAL_TRAINING_PLAYER_RANK="GET_PERSONAL_TRAINING_PLAYER_RANK";
export const UPDATE_PERSONAL_TRAINING_PLAYER_RANK="UPDATE_PERSONAL_TRAINING_PLAYER_RANK";

export const GET_FOURSOME_RANK_DATA_CONFIG_BRANDS = "GET_FOURSOME_RANK_DATA_CONFIG_BRANDS";
export const CREATE_FOURSOME_RANK_DATA_CONFIG ="CREATE_FOURSOME_RANK_DATA_CONFIG";
export const GET_FOURSOME_RANK_BASE_DATA_CONFIG="GET_FOURSOME_RANK_BASE_DATA_CONFIG";
export const GET_FOURSOME_RANK_DATA_CONFIG="GET_FOURSOME_RANK_DATA_CONFIG";
export const DELETE_FOURSOME_RANK_DATA_CONFIG="DELETE_FOURSOME_RANK_DATA_CONFIG";
export const UPDATE_FOURSOME_RANK_DATA_CONFIG ="UPDATE_FOURSOME_RANK_DATA_CONFIG";

export const GET_FOURSOME_SORT_FIELD_DIC="GET_FOURSOME_SORT_FIELD_DIC";
export const GET_FOURSOME_TRAINING_PLAYER_RANK="GET_FOURSOME_TRAINING_PLAYER_RANK";
export const UPDATE_FOURSOME_TRAINING_PLAYER_RANK="UPDATE_FOURSOME_TRAINING_PLAYER_RANK";