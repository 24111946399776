
import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_BRAND_INFO,
    GET_BRAND_LIST,
    CREATE_BRAND,
    UPDATE_BRAND,
    AUTHORIZATION_BRAND,
    GET_PERSON_BRAND_LIST,
    CREATE_PERSON_BRAND,
    UPDATE_PERSON_BRAND,
    AUTHORIZATION_PERSON_BRAND,
    GET_LADDER_BRAND_LIST,
    CREATE_LADDER_BRAND,
    UPDATE_LADDER_BRAND,
    AUTHORIZATION_LADDER_BRAND
} from './actionTypes';
import BrandRequest from "../../request/BrandRequest";

//获取品牌赛事信息
export const getBrandInfo = (id) => (createAsyncAction(GET_BRAND_INFO, () => (BrandRequest.getBrand(id))));

//获取品牌赛事列表
export const getBrandList = (params) => (createAsyncAction(GET_BRAND_LIST, () => (BrandRequest.getBrands(params))));

//创建品牌赛事
export const createBrand = (params) => (createAsyncAction(CREATE_BRAND, () => (BrandRequest.createBrand(params))));

//修改品牌赛事
export const updateBrand = (id, params) => (createAsyncAction(UPDATE_BRAND, () => (BrandRequest.updateBrand(id, params))));

//授权品牌赛事
export const authorizationBrand = (id, adminIds) => (createAsyncAction(AUTHORIZATION_BRAND, () => BrandRequest.authorizationBrand(id,adminIds)));

//天梯单排赛-获取品牌赛事列表
export const getPersonBrandList = (params) => (createAsyncAction(GET_PERSON_BRAND_LIST, () => (BrandRequest.getPersonBrandList(params))));

//天梯单排赛-创建品牌赛事
export const createPersonBrand = (params) => (createAsyncAction(CREATE_PERSON_BRAND, () => (BrandRequest.createPersonBrand(params))));

//天梯单排赛-修改品牌赛事
export const updatePersonBrand = (id, params) => (createAsyncAction(UPDATE_PERSON_BRAND, () => (BrandRequest.updatePersonBrand(id, params))));

//天梯单排赛-授权品牌赛事
export const authorizationPersonBrand = (id, adminIds) => (createAsyncAction(AUTHORIZATION_PERSON_BRAND, () => BrandRequest.authorizationPersonBrand(id,adminIds)));

//天梯四排赛-获取品牌赛事列表
export const getLadderBrandList = (params) => (createAsyncAction(GET_LADDER_BRAND_LIST, () => (BrandRequest.getLadderBrandList(params))));

//天梯四排赛-创建品牌赛事
export const createLadderBrand = (params) => (createAsyncAction(CREATE_LADDER_BRAND, () => (BrandRequest.createLadderBrand(params))));

//天梯四排赛-修改品牌赛事
export const updateLadderBrand = (id, params) => (createAsyncAction(UPDATE_LADDER_BRAND, () => (BrandRequest.updateLadderBrand(id, params))));

//天梯四排赛-授权品牌赛事
export const authorizationLadderBrand = (id, adminIds) => (createAsyncAction(AUTHORIZATION_LADDER_BRAND, () => BrandRequest.authorizationLadderBrand(id,adminIds)));
