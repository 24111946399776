
/**
 * 战队成员类型
 */
export enum TeamMemberType {
    MANAGER = '经理',
    TRAINING_DIRECTOR= '赛训总监',
    LEADER = "教练",
    CHIEF_LEADER = "领队",
    FORMAL = '正式成员',
    ALTERNATE = '替补成员',
}

/**
 * 场上位置
 */
 export enum FloorPosition {
    COMMANDER = '指挥位',
    FREE_MAN= '自由位',
    ASSAULTER = '突击位',
    SNIPER = '架枪位',
    ALL_POSITION = '全位置',
    TRAINER = '试训者',
}

/**
 * 试训状态
 */
 export enum TrialState {
    REJECT = '用户拒绝预约',
    CREATE = '已创建',
    CONFIRM_RESERVE = '用户确认预约',
    RESERVE = '管理员预约试训',
    END_TRAIL = '结束试训',
    CONFIRM_END_TRAIL = '确认结束试训',
    COMPLETE = '试训已完成',
    CANCEL = '已取消'
    // APPROVED= '通过',
    // SUBMIT = '未审核',
    // START_TRAIL = '开始试训',
    // CONFIRM_START_TRAIL = '确认开始试训',
    // REJECT = '已驳回',
}

/**
 * 枚举转数组
 * @param enums
 */
export const enumToArray = function (objs: any) {
    try {
        let arr = [];
        for (let value in objs) {
            arr.push({
                value,
                name: objs[value]
            });
        }
        return arr;
    } catch (e) {
        return [];
    }
};
