import createReducer from 'utils/createReducer';

import {
    GET_REPORTS,GET_REPORT
} from './actionTypes';

const defaultState = () => ({
    reports: [],
    reportsTotal: 0,
    reportsLoading: false,
    reportInfo: {},
    adminRoles: [], // 用户所拥有的角色
});


const ongetAdminsRequest = (state,{payload}) => {
    console.log(payload)
    return {
        ...state,
        reports:payload

    }

};


const ongetReportsuccess = (state, { payload }) => {
    console.log(payload)
    return{
        ...state,
        reportInfo: payload,
    }

};


export default createReducer(defaultState, {
    [`${GET_REPORTS}_SUCCESS`]: ongetAdminsRequest,
    [`${GET_REPORT}_SUCCESS`]: ongetReportsuccess,
});
