import createReducer from 'utils/createReducer';

import {
    GET_CLUB,
    GET_TEAM,GET_TRAILS,
    GET_CLUB_DETAIL,
    GET_GAME,
    GET_EVENT,
    GET_TRAIL_PLAN_BRAND,
    GET_SIGNBOARD,
    GET_TRIALPLAN_NEW
} from './actionTypes';

const defaultState = () => ({
    clubList: [],
    eventList: [],
    clubInfo:{},
    clubDetail:{
        address:{
            province:'',
            city:'',
            string:''
        }
    },
    teamList:{
        data:[],
    },
    trialList:{
        data:[],
    },
    signboardList:{
        data:[]
    },
    trialPlanList:{
        data:[]
    },
    gameList:[],
    adminRoles: [], // 用户所拥有的角色
    trailPlanBrands:[]
});


const onGetClubDetailRequest = (state,{payload}) => {
    console.log(payload)

    return {
        ...state,
        clubDetail:payload,
    }

};

const onGetClubRequest = (state,{payload}) => {
    return {
        ...state,
        clubList:payload,
    }

};
const onGetTeamRequest = (state,{payload}) => {
    return {
        ...state,
        teamList:payload,
    }
};

const onGetTrailRequest = (state,{payload}) => {
    return {
        ...state,
        trialList:payload,
    }
};

const onGetSignboardRequest = (state,{payload}) => {
    return {
        ...state,
        signboardList:payload,
    }
};
const onGetTrialPlanListRequest = (state,{payload}) => {
    return {
        ...state,
        trialPlanList:payload,
    }
};

const onGetGameRequest = (state,{payload}) => {
    return {
        ...state,
        gameList:payload,
    }

};

const onGetEventRequest = (state,{payload}) => {
    return {
        ...state,
        eventList:payload,
    }

};

const onGetTrailPlanBrandRequest = (state,{payload}) => {
    return {
        ...state,
        trailPlanBrands:payload,
    }

};

export default createReducer(defaultState, {
    [`${GET_CLUB}_SUCCESS`]: onGetClubRequest,
    [`${GET_TEAM}_SUCCESS`]: onGetTeamRequest,
    [`${GET_TRAILS}_SUCCESS`]: onGetTrailRequest,
    [`${GET_SIGNBOARD}_SUCCESS`]: onGetSignboardRequest,
    [`${GET_TRIALPLAN_NEW}_SUCCESS`]:onGetTrialPlanListRequest,
    [`${GET_CLUB_DETAIL}_SUCCESS`] :onGetClubDetailRequest,
    [`${GET_GAME}_SUCCESS`] :onGetGameRequest,
    [`${GET_EVENT}_SUCCESS`] :onGetEventRequest,
    [`${GET_TRAIL_PLAN_BRAND}_SUCCESS`] :onGetTrailPlanBrandRequest,
});
