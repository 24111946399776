import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table, Tag, Popconfirm, Carousel} from 'antd';
import {connect} from 'react-redux';
import ReportFilter from "../report-management/ReportFilter";
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    getReports,
    getReport,
    deleteReport,
    assign
} from "../../modules/report/action";
import {operationTypeValue} from "../../modules/report/reportEnum";

class ReportManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            imageUrl: [],
            isShowAssignAdminRoleModal: false,
            isShowImageModal: false,
            currentAdminId: null,
            type: null,
            reports: [],
            result: null
        };
    }

    handleOk = e => {
        this.setState({
            isShowImageModal: false,
        })
    };

    handleCancel = e => {
        this.setState({
            isShowImageModal: false,
        })
    }

    componentDidMount() {

        const {pageNo, pageSize, type, result} = this.state;
        let {getReports} = this.props;
        getReports(pageNo, pageSize, type, result);
    }

    showImageUrl = (imageUrl) => {
        console.log(imageUrl);
        if (imageUrl === null) {
            this.setState({
                isShowImageModal: true,
                imageUrl:[]
            });
        } else {
            this.setState({
                isShowImageModal: true,
                imageUrl: imageUrl
            });
        }
    }

assign = async (id, result1) => {
    const {pageNo, pageSize, type, result} = this.state;
    try {
        const {assign, getReports} = this.props;
        await assign(id, result1);

        getReports(pageNo, pageSize, type, result);
    } catch (e) {
        console.log(e);
    }
}

//删除举报的方法
onDeleteReportEvent = async (adminId) => {
    const {deleteReport, getReports} = this.props;
    const {pageNo, pageSize, type, result} = this.state;
    try {
        await deleteReport(adminId);
        notification.success({message: '删除成功'});
        console.log(pageNo)

        getReports(pageNo, pageSize, type, result);
    } catch (e) {
        console.log(e);
    }
}

onFilterReportEvent = async (type, result) => {
    let {getReports} = this.props;
    const {pageNo, pageSize} = this.state;
    getReports(1, pageSize, type, result);
    this.setState({
        pageNo: 1,
        type: type,
        result: result
    })
}

onPageChangeEvent = (current, pageSize) => {
    let {getReports} = this.props;
    let {type, result} = this.state;
    this.setState({
        pageNo: current,
        pageSize: pageSize,
    });
    getReports(current, pageSize, type, result);
}


    render() {
        console.log(this.props.reports)
        console.log(this.state.operationType)
        const {
            reports, loading
        } = this.props;
        const {
            pageNo, pageSize
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <ReportFilter dataSource={{}} onCommit={this.onFilterReportEvent}/>
                </Card>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '反馈人',
                                    dataIndex: 'prosecutor',
                                    key: 'prosecutor',
                                    align: 'center',
                                },
                                {
                                    title: '类型',
                                    dataIndex: 'type',
                                    key: 'type',
                                    align: 'center',
                                },
                                {
                                    title: '被举报人/战队',
                                    dataIndex: 'violator',
                                    key: 'violator',
                                    align: 'center',
                                    width:'150px',
                                },
                                {
                                    title: '电话',
                                    dataIndex: 'phone',
                                    key: 'phone',
                                    align: 'center',
                                    width:'150px',
                                },
                                {
                                    title: '举报内容',
                                    dataIndex: 'reportDetail',
                                    key: 'reportDetail',
                                    align: 'center',
                                    width:'400px',
                                },
                                {
                                    title: '图片',
                                    dataIndex: 'imageUrl',
                                    key: 'imageUrl',
                                    align: 'center',
                                    render: (imageUrl, record) => {
                                        return (imageUrl && imageUrl.length >0) ? <Button type="primary" color="blue"
                                                       onClick={() => this.showImageUrl(imageUrl)}>查看</Button> : '无';
                                    }
                                },
                                {
                                    title: '举报时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '处理情况',
                                    dataIndex: 'resultInt',
                                    key: 'resultInt',
                                    align: 'center',
                                    render: (resultInt, record) => {
                                        if (resultInt === 0) {
                                            return <Tag color={"red"}>未处理</Tag>
                                        } else {
                                            if (resultInt === 1) {
                                                return <Tag>不予处理</Tag>
                                            }
                                            if (resultInt === 2) {
                                                return <Tag color={"green"}>已处理</Tag>
                                            }
                                        }
                                    }
                                },
                                {
                                    title: '处理时间',
                                    dataIndex: 'operationDate',
                                    key: 'operationDate',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, record) => {
                                        if (record.resultInt === 0) {
                                            return <div><Popconfirm title={`请选择处理结果`}
                                                                    onConfirm={() => this.assign(record.id, "COMPLETE")}
                                                                    onCancel={() => this.assign(record.id, "CLOSE")}
                                                                    okText="已处理" cancelText="不予处理">
                                                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} color={"#2db7f5"}>处理</Button>
                                            </Popconfirm>
                                                <Popconfirm title={`确定要删除该记录吗？`}
                                                            onConfirm={() => this.onDeleteReportEvent(record.id)}
                                                            okText="确认" cancelText="取消">
                                                    <Button style={{marginLeft: 10, marginBottom: 10}} type="danger">删除</Button>
                                                </Popconfirm></div>;
                                        } else {
                                            return <div>
                                                <Popconfirm title={`确定要删除该记录吗？`}
                                                            onConfirm={() => this.onDeleteReportEvent(record.id)}
                                                            okText="确认" cancelText="取消">
                                                    <Button style={{marginLeft: 10, marginBottom: 10}} type="danger">删除</Button>
                                                </Popconfirm></div>;
                                        }
                                    }
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={reports.data ? reports.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: reports ? reports.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="图片查看"
                    visible={this.state.isShowImageModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={"600px"}
                    footer={
                        [] // 设置footer为空，去掉 取消 确定默认按钮
                    }

                >
                    <Carousel >
                        {this.state.imageUrl.map((image) => {
                            return  <img align={"center"} alt={""} width={"400px"} src={image ? image+'?x-oss-process=image/resize,w_400' : ''}/>
                        })}
                    </Carousel>
                </Modal>
            </div>
        )
            ;

    }

// --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Report)
        return {
            ...state.Report
        }

    }

);

const mapDispatchToProps = {
    getReports,
    getReport,
    deleteReport,
    assign
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(ReportManagement)));