import authentication from 'modules/authentication';
import viewPermission from 'modules/view-permission';
import admin from 'modules/admin';
import viewRole from 'modules/role';
import viewClub from "modules/club"
import viewTraining from "../../modules/training/index"
import Banner from "modules/banner"
import Article from "modules/article"
import viewDynamic from "../../modules/dynamic";
import Comments from "../../modules/comment";
import vip from "../../modules/vip";
import viewCoin from "../../modules/coin";
import Payments from "../../modules/pay";
import viewCircle from "../../modules/circle";
import user from "modules/user";
import account from "modules/account";
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Team from 'modules/team';
import Qrcode from 'modules/qrcode';
import AppContact from '../../modules/app-contact';
import Brand from 'modules/brand';
import Certification from 'modules/certification';
import config from "../../modules/config";
import Report from "../../modules/report"
import viewTeam from 'modules/team';
import sensitivityPlan from '../../modules/small-plan';
import AppStart from '../../modules/startConfig'
import viewBrand from 'modules/brand';
import withdraw from '../../modules/withdraw';
import resume from 'modules/resume';
import models from '../../modules/small'
import AppNotice from '../../modules/appNotice'
import MsgNotice from '../../modules/msgNotice'
import trailPlan from '../../modules/trail-plan'
import adminOperationLog from '../../modules/admin-operation-log'
import PartnerTraining from '../../modules/partner-training'
import PartnerOrder from "../../modules/pratner-order";
import ProfessionalPlayer from "modules/player";
import TrainingTemplate from "../../modules/training-template";
import LadderTraining from "../../modules/ladder-training";
import TrainingVideo from "../../modules/training-video";

export default (history) => combineReducers({
    router: connectRouter(history),
    authentication,
    viewRole,
    viewPermission,
    admin,
    viewCircle,
    Banner,
    Article,
    viewDynamic,
    viewClub,
    viewTeam,
    viewBrand,
    Report,
    config,
    withdraw,
    Team,
    Brand,
    vip,
    viewCoin,
        Certification,
    user,
    AppNotice,
    Payments,
    account,
    resume,
    trailPlan,
    viewTraining,
    MsgNotice,
    Comments,
    adminOperationLog,
    Qrcode,
    sensitivityPlan,
    models,
    AppContact,
    AppStart,
    PartnerTraining,
    PartnerOrder,
    ProfessionalPlayer,
    TrainingTemplate,
    LadderTraining,
    TrainingVideo
});

