import React, {Component} from 'react';
import {
    Form, Button, Select
} from 'antd';
import UpLoad from "../../components/upload";
const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};


class ValuationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            innerJumpTypes: [],
            jumpDescription: "",
            isOuter: this.props.isOuter,
            jumpType: this.props.isOuter,
            innerJumpType:this.props.innerJumpType,
            state : this.props.state,

        };
    }


    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let { onSubmitEvent } = this.props;
                if(values && values.imageUrl){
                    values.imageUrl=values.imageUrl.split("?")[0]
                }
                onSubmitEvent && onSubmitEvent(values);
            }
        });
    }



    render() {
        console.log(this.props)
        let {imageUrl,enable,pageType} = this.props
        const {getFieldDecorator} = this.props.form;
        return (
            <Form>

                <FormItem
                    label="所属页面"
                    {...formItemLayout}>
                    {getFieldDecorator('pageType', {
                        rules: [{required: true, message: '请选择所属页面', pattern: /^\S+$/}],
                        initialValue: pageType,
                    })(
                        <Select style={{width: '150px'}} onChange={this.onChangeEvent}>
                            <Option key={1} value="VALUATION">身价排行榜</Option>
                            <Option key={2} value="OFFICIAL">圈子官方公告</Option>
                            <Option key={3} value="TRAINING_REPLAY">训练赛回放图</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem
                    label="图片(690*240)"
                    {...formItemLayout}
                >
                    {getFieldDecorator('imageUrl', {
                        initialValue: imageUrl  ? imageUrl+'?x-oss-process=image/resize,w_86' : '',
                    })(
                        (<UpLoad
                            double={false}
                            //图片格式限制
                            // width = {690}
                            // height = {240}
                            size={251}
                        />),
                    )}
                </FormItem>
                <FormItem
                    label="是否启用"
                    {...formItemLayout}
                >
                    {getFieldDecorator('enable', {
                        rules: [{required: true, message: '请选择是否启用', pattern: /^\S+$/}],
                        initialValue: enable,
                    })(
                        (
                            <Select style={{width: '150px'}}>
                                <Option value={true}>是</Option>
                                <Option value={false}>否</Option>
                            </Select>
                        ),
                    )}
                </FormItem>
                <FormItem>
                    <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onSubmitEvent} type="primary">提交</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export default Form.create()(ValuationForm);
