import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
import {getGame} from '../../../../src/modules/club/action'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import { TrialState, enumToArray } from '../../../dictionary/dictionary';


const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const {RangePicker} = DatePicker;
const { Option } = Select;

class TrialFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        let {getGame} = this.props
        getGame()
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;

                onCommit && onCommit(values);

            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        let trialStates = enumToArray(TrialState); //试训状态枚举

        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="俱乐部名称">
                    {getFieldDecorator('clubName', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户名">
                        {getFieldDecorator('userName', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="用户展示ID">
                    {getFieldDecorator('userId', {

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="试训时间区间：">
                    {getFieldDecorator('dateRange', {
                        initialValue: ""
                    })(<RangePicker
                        format={dateFormat}
                        placeholder={['开始时间', '结束时间']}
                    />)}
                </FormItem>
                <FormItem label="试训状态:">
                         {getFieldDecorator('state', {
                            initialValue:"",
                        })(
                            <Select style={{width: 150}}>
                                <Option value="">全部</Option>
                                {trialStates.map(item => {
                                    return (
                                        <Option value={item.value}>{item.name}</Option>
                                    );
                                })}
                            </Select>
                        )}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



const mapStateToProps = (state=>{
        return {
            ...state.viewClub,

        }}
);

const mapDispatchToProps = {
    getGame
};

//const TeamModel = Form.create(TeamModelForm)
export default connect(
    mapStateToProps,
    mapDispatchToProps,
) (Form.create()(withRouter(injectIntl(TrialFilter))));

