import React, {Component} from 'react';
import {Avatar, Button, Descriptions, Modal, notification, Table, Tabs} from 'antd';
import {stateType} from 'modules/user/enums';
import {spendType} from 'modules/account/enums';
import {codeType, vipStateType} from '../../modules/vip/enums';
import {connect} from 'react-redux';
import {getAccount, getAccountLogs, getSpendLogs} from 'modules/account/action';
import {changeAdditionSs, getUserResumes} from '../../modules/resume/action';
import {updateAccount} from '../../modules/user/action';
import {getVip, rechargeVipDays} from 'modules/vip/action';
import RechargeVipForm from "./RechargeVipForm";
import OperationAccountForm from "./OperationAccountForm"
import UpdateCretificationModal from "./UpdateCretificationModal";
import AdditionSsForm from "./AdditionSsForm";
import whetherButtonPermission from 'modules/authentication/whetherButtonPermission';
import buttonType from './buttonType';
import {getUser,updateUserCertification} from "../../modules/certification/action"



class UserDetail extends Component {

    state = {
        accountLogsPage: 1,
        accountLogsSize: 10,
        spendLogsPage: 1,
        spendLogsSize: 10,
        isVip: false,
        isShowRechargeVipModal: false,
        isShowAdditionModal: false,
        isShowAccountModal: false,
        isShowCertificationModel: false,
        userId : ""
    }


    render() {

        const {pageButtons} = this.props;
        const {exhibitionId, username, state, telephone, createTime, imageUrl, mailbox, signature, id} = this.props.info;
        const {coin, drawableAmount} = this.props.account;
        const {accountLogsPage, accountLogsSize, spendLogsPage, spendLogsSize, isVip, isShowRechargeVipModal, isShowAdditionModal, isShowAccountModal, isShowCertificationModel} = this.state;
        const {
            accountLogs, accountLogsLoading, accountLogsTotal,
            spendLogs, spendLogsLoading, spendLogsTotal, userResumes
        } = this.props;
        console.log(userResumes)
        console.log(accountLogs)

        return <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="用户详细资料" key="1">

                {/*<Button type="primary" onClick={this.clickAccount}>操作可提现金额</Button>*/}
                &nbsp;
                <Modal title="操作金额数量" visible={isShowAccountModal} footer={null} destroyOnClose
                       onCancel={this.onCloseAccountModal}>
                    <OperationAccountForm onSubmit={this.onOperationAccount}/>
                </Modal>

                {/*<Button type="primary" onClick={this.clickCertification}>编辑认证资料</Button>*/}
                {/*<Modal title="修改认证资料" visible={isShowCertificationModel} footer={null}*/}
                {/*       onCancel={this.onCloseAccountModal}>*/}
                {/*    <UpdateCretificationModal {...this.props} onSubmit={this.onOperationCertification}*/}
                {/*    />*/}
                {/*</Modal>*/}

                <Descriptions title={
                    <div>
                        <Avatar src={imageUrl ? imageUrl + '?x-oss-process=image/resize,w_32' : "/avatar.png"}/>
                    </div>
                } bordered>
                    <Descriptions.Item label="用户ID">{id}</Descriptions.Item>
                    <Descriptions.Item label="用户展示ID">{exhibitionId}</Descriptions.Item>
                    <Descriptions.Item label="用户名">{username}</Descriptions.Item>
                    <Descriptions.Item label="用户状态">
                        {!state ? null : stateType[state].label}
                    </Descriptions.Item>
                    {/*<Descriptions.Item label="浪币余额">{coin}</Descriptions.Item>*/}
                    {/*<Descriptions.Item label="可提现余额">*/}
                    {/*    {drawableAmount}*/}
                    {/*</Descriptions.Item>*/}
                    <Descriptions.Item label="手机号码">{telephone}</Descriptions.Item>
                    <Descriptions.Item label="绑定邮箱">{mailbox}</Descriptions.Item>
                    <Descriptions.Item label="注册时间">{createTime}</Descriptions.Item>
                    <Descriptions.Item label="个人签名">{signature}</Descriptions.Item>
                </Descriptions>
            </Tabs.TabPane>
            {/*<Tabs.TabPane tab="账户变动日志" key="2">*/}
            {/*    <Table*/}
            {/*        columns={*/}
            {/*            [*/}
            {/*                {*/}
            {/*                    title: '消费类型',*/}
            {/*                    dataIndex: 'typeName',*/}
            {/*                    key: 'typeName',*/}
            {/*                    align: 'center',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    title: '消费金额',*/}
            {/*                    dataIndex: 'changeAmount',*/}
            {/*                    key: 'changeAmount',*/}
            {/*                    align: 'center',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    title: '消费时间',*/}
            {/*                    dataIndex: 'changeTime',*/}
            {/*                    key: 'changeTime',*/}
            {/*                    align: 'center',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    title: '备注',*/}
            {/*                    dataIndex: 'remark',*/}
            {/*                    key: 'remark',*/}
            {/*                    align: 'center',*/}
            {/*                },*/}
            {/*            ]*/}
            {/*        }*/}
            {/*        dataSource={accountLogs}*/}
            {/*        loading={accountLogsLoading}*/}
            {/*        pagination={{*/}
            {/*            total: accountLogsTotal,*/}
            {/*            current: accountLogsPage,*/}
            {/*            pageSize: accountLogsSize,*/}
            {/*            onChange: this.onAccountLogPageChange,*/}
            {/*            showTotal: (total) => {*/}
            {/*                return `总计 ${total}条数据`;*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Tabs.TabPane>*/}
            {/*<Tabs.TabPane tab="用户消费日志" key="3">*/}
            {/*    <Table*/}
            {/*        columns={*/}
            {/*            [*/}
            {/*                {*/}
            {/*                    title: '消费类型',*/}
            {/*                    dataIndex: 'type',*/}
            {/*                    key: 'type',*/}
            {/*                    align: 'center',*/}
            {/*                    render: (text) => (!text ? '-' : spendType[text].label)*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    title: '充值金额',*/}
            {/*                    dataIndex: 'amount',*/}
            {/*                    key: 'amount',*/}
            {/*                    align: 'center',*/}
            {/*                    render: (amount, record) => {*/}
            {/*                        return amount / 100;*/}
            {/*                    }*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    title: '充值浪币数量',*/}
            {/*                    dataIndex: 'coin',*/}
            {/*                    key: 'coin',*/}
            {/*                    align: 'center',*/}
            {/*                },*/}
            {/*                {*/}
            {/*                    title: '充值时间',*/}
            {/*                    dataIndex: 'createTime',*/}
            {/*                    key: 'createTime',*/}
            {/*                    align: 'center',*/}
            {/*                },*/}
            {/*            ]*/}
            {/*        }*/}
            {/*        dataSource={spendLogs}*/}
            {/*        loading={spendLogsLoading}*/}
            {/*        pagination={{*/}
            {/*            total: spendLogsTotal,*/}
            {/*            current: spendLogsPage,*/}
            {/*            pageSize: spendLogsSize,*/}
            {/*            onChange: this.onSpendLogPageChange,*/}
            {/*            showTotal: (total) => {*/}
            {/*                return `总计 ${total}条数据`;*/}
            {/*            }*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Tabs.TabPane>*/}
            {/*<Tabs.TabPane tab="会员详情" key="4">*/}
            {/*    {*/}
            {/*        whetherButtonPermission(buttonType.OPEN_VIP_DAYS.value, pageButtons) &&*/}
            {/*        <Button type="primary" style={{marginBottom: '15px'}}*/}
            {/*                onClick={this.onOpenRechargeVipModal}>{buttonType.OPEN_VIP_DAYS.label}</Button>*/}
            {/*    }*/}
            {/*    <Modal title="开会员天数" visible={isShowRechargeVipModal} footer={null} destroyOnClose*/}
            {/*           onCancel={this.onCloseRechargeVipModal}>*/}
            {/*        <RechargeVipForm onSubmit={this.onRechargeVip}/>*/}
            {/*    </Modal>*/}
            {/*    {*/}
            {/*        isVip && this.props.vip ?*/}
            {/*            <Descriptions title="" bordered>*/}
            {/*                <Descriptions.Item label="展示ID">{this.props.vip.exhibitionId}</Descriptions.Item>*/}
            {/*                <Descriptions.Item*/}
            {/*                    label="状态">{this.props.vip.vipState ? vipStateType[this.props.vip.vipState].label : null}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="等级">{this.props.vip.level}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="总经验值">{this.props.vip.exp}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="下一级经验值条长度">{this.props.vip.nextLevelExp}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="升到下一级所需经验值">{this.props.vip.nextExp}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="连续签到日期">{this.props.vip.continuousCheckInDay}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="最后签到时间">{this.props.vip.lastCheckInDate}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="会员开始时间">{this.props.vip.startDate}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="会员过期时间">{this.props.vip.expiredDate}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="会员总时长">{this.props.vip.totalVIPDay}</Descriptions.Item>*/}
            {/*                <Descriptions.Item label="训练赛总场次">{this.props.vip.totalGameNum}</Descriptions.Item>*/}
            {/*            </Descriptions>*/}
            {/*            :*/}
            {/*            <div>*/}
            {/*                用户还未开通VIP*/}
            {/*            </div>*/}
            {/*    }*/}
            {/*</Tabs.TabPane>*/}
            <Tabs.TabPane tab="用户履历" key="5">
                <Button type="primary" style={{marginBottom: '15px'}}
                        onClick={this.onOpenAdditionalSocialStatusModal}>自定义附加身价</Button>
                <Modal title="自定义附加身价" visible={isShowAdditionModal} footer={null} destroyOnClose
                       onCancel={this.onCloseAdditionalSocialStatusModal}>
                    <AdditionSsForm {...this.props} onSubmit={this.onChangeAdditionalSs}/>
                </Modal>
                <Table
                    columns={
                        [
                            {
                                title: '游戏名称',
                                dataIndex: 'gameName',
                                key: 'gameName',
                                align: 'center',
                            },
                            {
                                title: '游戏账号',
                                dataIndex: 'gameAccount',
                                key: 'gameAccount',
                                align: 'center',
                            },
                            {
                                title: '游戏年限',
                                dataIndex: 'gameLife',
                                key: 'gameLife',
                                align: 'center',
                            },
                            {
                                title: '游戏角色',
                                dataIndex: 'gameRole',
                                key: 'gameRole',
                                align: 'center',
                            },
                            {
                                title: 'KDA',
                                dataIndex: 'kda',
                                key: 'kda',
                                align: 'center',
                            },
                            {
                                title: '身价',
                                dataIndex: 'socialStatus',
                                key: 'socialStatus',
                                align: 'center',
                            },
                            {
                                title: '自定义附加身价',
                                dataIndex: 'additionalSocialStatus',
                                key: 'additionalSocialStatus',
                                align: 'center',
                            },
                            {
                                title: '个人签名',
                                dataIndex: 'signature',
                                key: 'signature',
                                align: 'center',
                            },
                        ]
                    }
                    dataSource={userResumes}
                />
            </Tabs.TabPane>
        </Tabs>
    }


    // --------页面事件开始---------

    componentDidMount() {
        const {id} = this.props.info;
        const {getAccountLogs, getSpendLogs, getUserResumes, getVip} = this.props;
        const {accountLogsPage, accountLogsSize, spendLogsPage, spendLogsSize} = this.state;
        getAccountLogs(id, accountLogsPage, accountLogsSize);
        getSpendLogs(id, spendLogsPage, spendLogsSize);
        getUserResumes(id);
        try {
            getVip(id);
            this.setState({
                isVip: true
            });
        } catch ({code, msg}) {
            if (code != codeType.VIP_NOT_EXIST.value) {
                notification.error({
                    message: '提示',
                    description: msg,
                });
                this.setState({
                    isVip: false
                })
            }
        }

    }


    /**
     * 账户日志分页事件监听
     */
    onAccountLogPageChange = (current, pageSize) => {
        const {id} = this.props.info;
        const {getAccountLogs} = this.props;
        this.setState({
            accountLogsPage: current,
            accountLogsSize: pageSize,
        });
        getAccountLogs(id, current, pageSize);
    }

    /**
     * 用户充值分页事件监听
     */
    onSpendLogPageChange = (current, pageSize) => {
        const {id} = this.props.info;
        const {getSpendLogs} = this.props;
        this.setState({
            spendLogsPage: current,
            spendLogsSize: pageSize,
        });
        getSpendLogs(id, current, pageSize)
    }


    onOpenRechargeVipModal = () => {
        this.setState({isShowRechargeVipModal: true});
    }


    onCloseRechargeVipModal = () => {
        this.setState({isShowRechargeVipModal: false});
    }
    onCloseAccountModal = () => {
        this.setState({isShowAccountModal: false,isShowCertificationModel : false});
    }

    clickAccount = () => {

        this.setState({
            isShowAccountModal: true
        })
    }

    clickCertification = () => {

        const {id} = this.props.info;

        this.setState({
            userId : id,
            isShowCertificationModel: true
        })
    }


    onOpenAdditionalSocialStatusModal = () => {
        this.setState({isShowAdditionModal: true})
    }

    onCloseAdditionalSocialStatusModal = () => {
        this.setState({isShowAdditionModal: false})
    }

    onChangeAdditionalSs = async (additionalSocialStatus, game) => {
        const {id} = this.props.info;
        const {changeAdditionSs, getUserResumes} = this.props;
        await changeAdditionSs(id, game, additionalSocialStatus);
        console.log(id)
        try {
            await getUserResumes(id);
        } catch ({code, msg}) {
            notification.error({
                message: '提示',
                description: msg,
            });

        }
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({isShowAdditionModal: false})
    }

    onOperationAccount = async (cashOut) => {
        const {id} = this.props.info;
        const {updateAccount, getAccount, accountLogsPage, getAccountLogs} = this.props;
        const {accountLogsSize} = this.state;
        await updateAccount(id, cashOut);
        getAccount(id);
        getAccountLogs(id, accountLogsPage, accountLogsSize);
        this.setState({isShowAccountModal: false})


    }

    onOperationCertification = async (id,params) => {
        const {userId} = this.state;
        const {updateUserCertification} = this.props;

        await updateUserCertification(id, params);
        notification['success']({
            message: '修改成功',
            description: '',
        });
        this.setState({isShowCertificationModel: false})


    }

    onRechargeVip = async (vipdays) => {
        const {id} = this.props.info;
        const {rechargeVipDays, getVip} = this.props;
        await rechargeVipDays(id, vipdays);
        notification['success']({
            message: '操作成功',
            description: '',
        });
        this.setState({isShowRechargeVipModal: false})
        try {
            getVip(id);
            this.setState({
                isVip: true
            });
        } catch ({code, msg}) {
            if (code != codeType.VIP_NOT_EXIST.value) {
                notification.error({
                    message: '提示',
                    description: msg,
                });
                this.setState({
                    isVip: false
                })
            }
        }
    }
}

const mapStateToProps = ({
                             account: {
                                 accountLogs,
                                 accountLogsLoading,
                                 accountLogsTotal,
                                 spendLogs,
                                 spendLogsLoading,
                                 spendLogsTotal,
                             },
                             resume: {
                                 userResumes
                             },
                             vip: {
                                 vip
                             },
                             authentication: {pageButtons}
                         }) => ({
    accountLogs, accountLogsLoading, accountLogsTotal
    , spendLogs, spendLogsLoading, spendLogsTotal, userResumes, vip, pageButtons
});

const mapDispatchToProps = {
    getAccountLogs, getSpendLogs, getUserResumes, getVip, rechargeVipDays, changeAdditionSs, updateAccount, getAccount,updateUserCertification
};


export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
