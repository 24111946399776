import createReducer from 'utils/createReducer';
import {
    GET_LADDER_VIDEO_LIST
} from './actionTypes';

const defaultState = () => ({
    ladderVideoList: {
        data: [],
        total: 0,
    }, // 天梯四排赛-回放视频列表
});

const onGetLadderVideoListSuccess = (state, {payload}) => ({
    ...state,
    ladderVideoList: payload,
});

export default createReducer(defaultState, {
    [`${GET_LADDER_VIDEO_LIST}_SUCCESS`]: onGetLadderVideoListSuccess,
});
