import createReducer from 'utils/createReducer';
import {
  GET_USERS,GET_USER
} from './actionTypes';

const defaultState = () => ({
  users: [],
  usersTotal: 0,
  usersLoading: false,
  userInfo: {},
});

const onGetUserRequest = (state) => ({
  ...state,
  usersLoading: true,
});

const onGetUsersError = (state) => ({
  ...state,
  usersLoading: false,
});

const onGetUsersSuccess = (state, { payload: { data, total } }) => ({
  ...state,
  users: data,
  usersTotal: total,
  usersLoading: false,
});

const onGetUserSuccess = (state, { payload }) => ({
  ...state,
  userInfo:payload
});

export default createReducer(defaultState, {
  [`${GET_USERS}_REQUEST`]: onGetUserRequest,
  [`${GET_USERS}_SUCCESS`]: onGetUsersSuccess,
  [`${GET_USERS}_ERROR`]: onGetUsersError,
  [`${GET_USER}_SUCCESS`]: onGetUserSuccess,
});
