
import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_LADDER_VIDEO_LIST,
    UPDATE_LADDER_VIDEO,
    DELETE_LADDER_VIDEO
} from './actionTypes';
import TrainingVideoRequest from "../../request/TrainingVideoRequest";

// 天梯四排赛-获取回放视频列表
export const getLadderVideoList = (id) => (createAsyncAction(GET_LADDER_VIDEO_LIST, () => (TrainingVideoRequest.getLadderVideoList(id))));

// 天梯四排赛-修改回放视频
export const updateLadderVideo = (id, params) => (createAsyncAction(UPDATE_LADDER_VIDEO, () => (TrainingVideoRequest.updateLadderVideo(id, params))));

// 天梯四排赛-删除回放视频
export const deleteLadderVideo = (id) => (createAsyncAction(DELETE_LADDER_VIDEO, () => (TrainingVideoRequest.deleteLadderVideo(id))));
