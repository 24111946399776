import Http from 'request/UserRequest';
import createAsyncAction from 'utils/createAsyncAction';
import {
  GET_USERS,GET_USER,UPDATE_USER_STATE,UPDATE_ACCOUNT, UNBAN_USER_CLAIM, ADD_USER,UPDATE_USER
} from './actionTypes';
import {DELETE_CIRCLE} from "../circle/actionTypes";
import CircleRequest from "../../request/CircleRequest";

const getUsers = (exhibitionId, username, telephone, state, registerBeginTime, registerEndTime, page, size) => (
  createAsyncAction(GET_USERS, () => (Http.getUsers(exhibitionId, username, telephone,
      state, registerBeginTime, registerEndTime, page, size)))
);

const getRecommendUsers = (exhibitionId, username, page, size) => (
    createAsyncAction(GET_USERS, () => (Http.getRecommendUsers(exhibitionId, username, page, size)))
);

const getUser = (userId) => (
    createAsyncAction(GET_USER, () => (Http.getUser(userId)))
);


const updateAccount = (userId,cashOut) => (
    createAsyncAction(UPDATE_ACCOUNT, () => (Http.updateAccount(userId,cashOut)))
);

const updateUserState = (userId,params) => (
    createAsyncAction(UPDATE_USER_STATE, () => (
        Http.updateUserStatus(userId,params)
    ))
)

const updateUser = (userId,params) => (
    createAsyncAction(UPDATE_USER, () => (
        Http.updateUser(userId,params)
    ))
)

const updateRecommendUser = (userId,params) => (
    createAsyncAction(UPDATE_USER, () => (
        Http.updateRecommendUser(userId,params)
    ))
)

const addUser = (params) => (
    createAsyncAction(ADD_USER, () => (
        Http.addUser(params)
    ))
)

const addRecommendUser = (params) => (
    createAsyncAction(ADD_USER, () => (
        Http.addRecommendUser(params)
    ))
)
const unBanUserClaim = (userId,type) => (
    createAsyncAction(UNBAN_USER_CLAIM, () => (
        Http.unBanUserClaim(userId,type)
    ))
)

const deleteRecommendUser = (id) => (createAsyncAction(DELETE_CIRCLE,
    () => (Http.deleteRecommendUser(id))));

export {
  getUsers,getUser,updateUserState,updateAccount,unBanUserClaim,addUser,updateUser,getRecommendUsers,addRecommendUser,updateRecommendUser,deleteRecommendUser
};
