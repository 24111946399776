import React, {Component} from 'react';
import {
    Form, Input, Button, Select, DatePicker
} from 'antd';
import {stateType,} from 'modules/user/enums';

const {RangePicker} = DatePicker;


class SearchForm extends Component {

    render() {

        const {getFieldDecorator} = this.props.form;

        return <Form layout='inline'>
            <Form.Item label='用户展示ID'>
                {
                    getFieldDecorator('exhibitionId')(<Input/>)
                }
            </Form.Item>
            <Form.Item label='用户名'>
                {
                    getFieldDecorator('username')(<Input/>)
                }
            </Form.Item>
            <Form.Item label='手机号'>
                {
                    getFieldDecorator('telephone')(<Input/>)
                }
            </Form.Item>
            <Form.Item label='用户状态'>
                {
                    getFieldDecorator('state', {initialValue: 'ALL'})(
                        <Select style={{width: '80px'}}>
                            <Select.Option value="ALL">所有</Select.Option>
                            {
                                Object.keys(stateType).map(key => (
                                    <Select.Option value={stateType[key].value}>{stateType[key].label}</Select.Option>
                                ))
                            }
                        </Select>
                    )
                }
            </Form.Item>
            {/*<Form.Item label='YTC'>*/}
            {/*    {*/}
            {/*        getFieldDecorator('ytc', {initialValue: ''})(*/}
            {/*            <Select style={{width: '100px'}}>*/}
            {/*                <Select.Option value="">所有</Select.Option>*/}
            {/*                <Select.Option value='true'>YTC会员</Select.Option>*/}
            {/*                <Select.Option value='false'>普通用户</Select.Option>*/}
            {/*            </Select>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</Form.Item>*/}
            <Form.Item label='注册时间'>
                {
                    getFieldDecorator('registerTime', {initialValue: null})(
                        <RangePicker/>
                    )
                }
            </Form.Item>
            <Button
                type='primary'
                icon="search"
                onClick={this.onSearch}
            >
                搜索
            </Button>
        </Form>
    }

    /**
     * 搜索事件
     */
    onSearch = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSearch} = this.props;
                onSearch && onSearch({
                    ...values,
                    state: values.state == 'ALL' ? null : values.state
                });
            }
        })
    }

}

export default Form.create()(SearchForm);
