
export const GET_TEAM_CERTIFICATION_INFO = 'GET_TEAM_CERTIFICATION_INFO'; // 获取参赛认证的队员信息

export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS'; // 获取队员信息

export const GET_TEAM_CERTIFICATION_LIST = 'GET_TEAM_CERTIFICATION_LIST'; // 查看参赛认证列表

export const VERIFY_TEAM_CERTIFICATION = 'VERIFY_TEAM_CERTIFICATION'; // 审核参赛认证

export const ADD_TEAM_CERTIFICATION = 'ADD_TEAM_CERTIFICATION'; // 添加参赛认证

export const UPDATE_TEAM_CERTIFICATION = 'UPDATE_TEAM_CERTIFICATION'; // 修改参赛认证

export const GET_USER_TEAM_LIST_BY_QQ = 'GET_USER_TEAM_LIST_BY_QQ'; // 修改参赛认证

export const GET_USER = 'GET_USER'; // 获取用户认证资料

export const GET_USER_CREDENTIALS = 'GET_USER_CREDENTIALS'; // 获取用户认证资料列表

export const UPDATE_USER_CERTIFICATION = 'UPDATE_USER_CERTIFICATION';// 修改用户认证资料

export const CHECK_CREDENTIALS = 'CHECK_CREDENTIALS';// 审核用户认证资料

export const GET_REAL_NAME_CREDENTIALS = 'GET_REAL_NAME_CREDENTIALS'; // 获取所有实名认证资料

export const CHECK_REAL_NAME_CREDENTIALS = 'CHECK_REAL_NAME_CREDENTIALS'; // 审核用户实名认证

export const UPDATE_REAL_NAME_CERTIFICATION = 'UPDATE_REAL_NAME_CERTIFICATION'; // 修改用户实名认证


export const GET_PERSON_CERTIFICATION_LIST = 'GET_PERSON_CERTIFICATION_LIST'; // 天梯单排赛-获取参赛认证列表

export const ADD_PERSON_CERTIFICATION = 'ADD_PERSON_CERTIFICATION'; // 天梯单排赛-新增参赛认证

export const UPDATE_PERSON_CERTIFICATION_UP_OR_DOWN = 'UPDATE_PERSON_CERTIFICATION_UP_OR_DOWN'; // 天梯单排赛-修改用户参赛认证升降级

export const UPDATE_PERSON_USER_SUSPEND = 'UPDATE_PERSON_USER_SUSPEND'; // 天梯单排赛-设置用户禁赛

export const DELETE_PERSON_CERTIFICATION = 'DELETE_PERSON_CERTIFICATION'; // 天梯单排赛-删除用户参赛认证


export const GET_LADDER_CERTIFICATION_LIST = 'GET_LADDER_CERTIFICATION_LIST'; // 天梯四排赛-获取参赛认证列表

export const ADD_LADDER_CERTIFICATION = 'ADD_LADDER_CERTIFICATION'; // 天梯四排赛-新增参赛认证

export const UPDATE_LADDER_CERTIFICATION_UP_OR_DOWN = 'UPDATE_LADDER_CERTIFICATION_UP_OR_DOWN'; // 天梯四排赛-修改用户参赛认证升降级

export const UPDATE_LADDER_USER_SUSPEND = 'UPDATE_LADDER_USER_SUSPEND'; // 天梯四排赛-设置用户禁赛

export const DELETE_LADDER_CERTIFICATION = 'DELETE_LADDER_CERTIFICATION'; // 天梯四排赛-删除用户参赛认证

export const UPDATE_LADDER_PLAYER_POSITION = 'UPDATE_LADDER_PLAYER_POSITION'; // 天梯四排赛-修改选手场上位置
