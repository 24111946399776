export const GET_DYNAMICS = 'GET_DYNAMICS';// 获取动态列表

export const DELETE_DYNAMIC = 'DELETE_DYNAMIC';

export const GET_COMMENTS = 'GET_COMMENTS';// 获取评论列表

export const UPDATE_DYNAMIC = 'UPDATE_DYNAMIC';// 恢复动态删除状态

export const DELETE_COMMENT = 'DELETE_COMMENT';  //删除评论

export const UPDATE_COMMENT = 'UPDATE_COMMENT';// 恢复评论删除状态

export const TOP_DYNAMIC = 'TOP_DYNAMIC';// 置顶动态