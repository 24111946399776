
import createAsyncAction from 'utils/createAsyncAction';
import {
    GET_LADDER_TEMPLATE_LIST,
    CREATE_LADDER_TEMPLATE,
    UPDATE_LADDER_TEMPLATE,
    DELETE_LADDER_TEMPLATE,
    GET_LADDER_TEMPLATE_DICTIONARIES,
    GET_LADDER_TEMPLATE_INFO
} from './actionTypes';
import TrainingTemplateRequest from "../../request/TrainingTemplateRequest";

// 天梯四排赛-获取赛事模板列表
export const getLadderTemplateList = (params) => (createAsyncAction(GET_LADDER_TEMPLATE_LIST, () => (TrainingTemplateRequest.getLadderTemplateList(params))));

// 天梯四排赛-创建赛事模板
export const createLadderTemplate = (params) => (createAsyncAction(CREATE_LADDER_TEMPLATE, () => (TrainingTemplateRequest.createLadderTemplate(params))));

// 天梯四排赛-编辑赛事模板
export const updateLadderTemplate = (id, params) => (createAsyncAction(UPDATE_LADDER_TEMPLATE, () => (TrainingTemplateRequest.updateLadderTemplate(id, params))));

// 天梯四排赛-删除赛事模板
export const deleteLadderTemplate = (id, adminIds) => (createAsyncAction(DELETE_LADDER_TEMPLATE, () => TrainingTemplateRequest.deleteLadderTemplate(id,adminIds)));

// 天梯四排赛-获取赛事模板字典
export const getLadderTemplateDictionaries = (params) => (createAsyncAction(GET_LADDER_TEMPLATE_DICTIONARIES, () => (TrainingTemplateRequest.getLadderTemplateDictionaries(params))));

// 天梯四排赛-获取赛事模板信息
export const getLadderTemplateInfo = (game, brandId, type) => (createAsyncAction(GET_LADDER_TEMPLATE_INFO, () => (TrainingTemplateRequest.getLadderTemplateInfo(game, brandId, type))));
