import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
import moment from "moment";
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class CommentFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {userId,dynamicId,commentContent,commentStatus,dateRange} = values;

                console.log(values)

                onCommit && onCommit(userId,dynamicId,commentContent,commentStatus,dateRange);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {userId,dynamicId,commentContent,commentStatus,dateRange} = values;

                console.log(values)

                onCommit && onCommit(userId,dynamicId,commentContent,commentStatus,dateRange);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {dataSource} = this.props;
        const {userId,dynamicId,commentContent} = dataSource;
        return (
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="评论人展示Id">
                    {getFieldDecorator('userId', {
                            initialValue: userId

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>

                <FormItem label="动态Id">
                    {getFieldDecorator('dynamicId', {
                            initialValue: dynamicId
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="评论内容">
                    {getFieldDecorator('commentContent', {
                            initialValue: commentContent
                        }
                    )(
                        <Input style={{width: '150px'}} allowClear />
                    )}
                </FormItem>
                <FormItem label="状态">
                    {getFieldDecorator('commentStatus', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value="">全部</Option>
                            <Option value="0">正常</Option>
                            <Option value="1">已屏蔽</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="发布时间区间：">
                    {getFieldDecorator('dateRange', {
                        initialValue: [moment().subtract('7','day'), moment().subtract('0','day')]
                    })(<RangePicker
                        allowClear
                        format={dateFormat}
                        placeholder={['开始时间', '结束时间']}
                    />)}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>
        );
    }
}



export default Form.create()(CommentFilter);