import api from 'utils/api';

class TrainingRequest {
    static async getGrade(parmas) {
        let {trainingId} = parmas;
        return api.get(`/admin/training/${trainingId}/grade`, parmas);
    }
    static async getTraining(params){
        return api.get(`/admin/training/admin`, params);
    }

    static async getBrand(params){
        return api.get(`/admin/brand/admin`, params);
    }

    static async getBrandAll(params){
        return api.get(`/admin/brand`, params);
    }

    static async editTraining(params){
        let {trainingId} = params
        return api.put(`/admin/training/${trainingId}`,params)
    }

    static async createTraining(params){
        return api.post(`/admin/training`,params)
    }

    static async deleteTraining(params){
        let {trainingId} = params
        return api.delete(`/admin/training/${trainingId}`)
    }

    static async updateGrade(params){
        let {trainingId} = params
        return api.put(`/admin/training/grade/${trainingId}`,{teamGradeItemCommands:params.teamGradeItemCommands})
    }

    static async getAddTeams(params){
        let {trainingId} = params;
        return api.get(`/admin/training/${trainingId}/teams`,params)
    }

    static async downModel(params){
        let {trainingId} = params;
        return api.get(`/admin/training/export/${trainingId}`)
    }

    static async checkSignUp(params){
        return api.put(`/admin/training/verify`,params)
    }

    static async deleteSignUp(params){
        return api.delete(`/admin/training/signUp?signUpIds=${params.signUpIds}&trainingId=${params.trainingId}`);
    }

    static async addJoinTeam(params){
        let {trainingId,teamIds} = params;
        return api.post(`/admin/training/${trainingId}/fullSign?teamIds=`+teamIds)
    }

    static async trainingNotice(params){
        return api.post(`/admin/training/notice`, params)
    }

    static async updateTrainingSignUpPosition (params){
        return api.put(`/admin/training/position`, params)
    }

    static async getPersonalGrade(params){
        let {trainingId} = params;
        return api.get(`/admin/training/${trainingId}/personal/grade`,params)
    }

    static async deleteGrade(params){
        let {trainingId,gradeType} = params;
        return api.delete(`/admin/training/${trainingId}/clear?gradeType=${gradeType}`);
    }

    /**
     * 获取所有游戏地图
     * @param {Number} id
     */
    static async getGameMaps() {
        return api.get(`/admin/training/game-maps`);
    }

    /**
     * 配置回放视频
     * @param {Number} id
     */
    static async addVideoConfig(trainingId, params) {
        return api.post(`/admin/training/${trainingId}/video`, params);
    }


    /**
     * 获取回放视频
     * @param {Number} id
     */
    static async getVideoConfig(trainingId) {
        return api.get(`/admin/training/${trainingId}/video`);
    }


    /**
     * 获取回放视频
     * @param {Number} id
     */
    static async queryVideoList(params) {
        return api.get(`/admin/training/video`,params);
    }


    /**
     * 修改回放视频
     * @param {Number} id
     */
    static async updateVideo(id, params) {
        return api.put(`/admin/training/video/${id}`,params);
    }

    /**
     * 删除回放视频
     * @param {Number} id
     */
    static async deleteVideo(id){
        return api.delete(`/admin/training/video/${id}`);
    }


    /**
     * 修改回放视频
     * @param {Number} id
     */
    static async getPlayAuth(videoId) {
        return api.get(`/admin/vod/video/play-auth`,videoId);
    }


    /**
     * 查询训练赛模版
     */
    static async getTrainingTemplate(game, brandId, type) {
        return api.get(`/admin/training/template/${game}/${brandId}/${type}`);
    }


    /**
     * 查询训练赛模版list
     */
    static async getTrainingTemplateList(params) {
        return api.get(`/admin/training/template`, params);
    }


    /**
     * 添加训练赛模版
     */
    static async addTrainingTemplate(params) {
        return api.post(`/admin/training/template`, params);
    }


    /**
     * 修改训练赛模版
     */
    static async modifyTrainingTemplate(id, parmas) {
        return api.put(`/admin/training/template/${id}`, parmas);
    }


    /**
     * 删除训练赛模版
     */
    static async deleteTrainingTemplate(id) {
        return api.delete(`/admin/training/template/${id}`);
    }

    /**
     * 查询训练赛模版字典
     */
    static async getTrainingTemplateDictionaries(){
        return api.get(`/admin/training/template/dictionaries`);
    }

    /**
     * 查询训练赛模版字典
     */
    static async updateTrainingTop(id){
        return api.put(`/admin/training/${id}/top`);
    }

    /**
     * 升降训练赛组别
     */
    static async liftGroup(params){
        return api.post(`/admin/training/group`, params);
    }

    /**
     * 战队配置开放踢人权限
     */
      static async updateKickPermissions(params){
        return api.put(`/admin/teams/kick/permissions`, params);
    }

    /**
     * 战队配置选手的升降流转权限
     */
    static async updateLiftPermissions(params){
        return api.put(`/admin/teams/members/lift/permissions`, params);
    }

    /**
     * PEL赛事字典
     */
    static async getPelLeagueDictionaries(){
        return api.get(`/admin/training/pel/league/dictionaries`);
    }

    /**
     * PEL战队积分榜总积分排行
     */
    static async getPelTeamRankIntegral(params){
        return api.get(`/admin/training/pel/team_rank/integral`, params);
    }

    /**
     * PEL战队积分榜总积分排行数据修改
     */
    static async updatePelTeamRankIntegral(params){
        return api.put(`/admin/training/pel/team_rank/integral`, params);
    }

    /**
     * PEL战队积分榜周积分排行
     */
    static async getPelTeamRankWeeklyIntegral(params){
        return api.get(`/admin/training/pel/team_rank_weekly/integral`, params);
    }

    /**
     * PEL战队积分榜周积分排行数据修改
     */
    static async updatePelTeamRankWeeklyIntegral(params){
        return api.put(`/admin/training/pel/team_rank_weekly/integral`, params);
    }

    /**
     * 榜单字典
     */
    static async getSortFieldDictionaries(params){
        return api.get(`/admin/training/sort_field/dictionaries`, params);
    }

    /**
     * PEL选手榜数据排行
     */
    static async getPelPlayerRankList(params){
        return api.get(`/admin/training/pel/player_rank`, params);
    }

    /**
     * PEL选手榜数据排行数据批量修改
     */
    static async updatePelPlayerRank(params){
        return api.put(`/admin/training/pel/player_rank`, params);
    }

    /**
     * PEL战队榜数据排行
     */
    static async getPelTeamRankList(params){
        return api.get(`/admin/training/pel/team_rank`, params);
    }

    /**
     * 训练赛战队榜数据排行
     */
    static async getTrainingTeamRankList(params){
        return api.get(`/admin/training/team_rank`, params);
    }

    /**
     * 训练赛战队榜数据排行数据批量修改
     */
    static async updateTrainingTeamRankIntegral(params){
        return api.put(`/admin/training/team_rank`, params);
    }

    /**
     * 训练赛选手榜数据排行
     */
    static async getTrainingPlayerRankList(params){
        return api.get(`/admin/training/player_rank`, params);
    }

    /**
     * 训练赛选手榜数据排行数据批量修改
     */
    static async updateTrainingPlayerRank(params){
        return api.put(`/admin/training/player_rank`, params);
    }

    /**
     * 选手战队基础配置数据榜
     */
    static async getRankBaseDataConfig(){
        return api.get(`/admin/training/rank/base_data/config`);
    }

    /**
     * 创建配置数据榜
     */
    static async createRandDataConfig(params){
        return api.post(`/admin/training/rank/data/config`, params);
    }
    /**
     * 创建配置数据榜
     */
    static async updateRandDataConfig(params){
        return api.put(`/admin/training/rank/data/config`, params);
    }
    /**
     * 获取品牌配置数据榜
     */
    static async getRankDataConfig(id){
        return api.get(`/admin/training/rank/data/config/brands/${id}`);
    }

    /**
     * 获取品牌配置数据榜列表
     */
    static async getRankDataConfigBrands(){
        return api.get(`/admin/training/rank/data/config/brands`);
    }

    static async deleteRankDataConfig(id){
        return api.delete(`/admin/training/rank/data/config/${id}`);
    }

    /**
     * 天梯单排赛-获取训练赛列表
     */
    static async getPersonTraining(params){
        return api.get(`/admin/personal/training/admin`, params);
    }
    
    /**
     * 天梯单排赛-创建训练赛
     */
    static async createPersonTraining(params){
        return api.post(`/admin/personal/training`,params)
    }

    /**
     * 天梯单排赛-修改训练赛信息
     */
     static async updatePersonTraining(params){
        let {trainingId} = params
        return api.put(`/admin/personal/training/${trainingId}`, params)
    }

    /**
     * 天梯单排赛-删除训练赛
     */
    static async deletePersonTraining(params){
        let {trainingId} = params
        return api.delete(`/admin/personal/training/${trainingId}`)
    }

    /**
     * 天梯单排赛-训练赛置顶
     */
     static async updatePersonTrainingTop(trainingId){
        return api.put(`/admin/personal/training/${trainingId}/top`);
    }

    /**
     * 天梯单排赛-获取参与详情列表（报名选手列表）
     */
    static async getJoinPlayer(params){
        let {trainingId} = params;
        return api.get(`/admin/personal/training/${trainingId}/player`, params)
    }

    /**
     * 天梯单排赛-（批量）添加报名选手
     */
    static async addJoinPlayer(params){
        let {trainingId,userIds} = params;
        return api.post(`/admin/personal/training/${trainingId}/fullSign?userIds=` + userIds)
    }

    /**
     * 天梯单排赛-通过选手报名
     */
    static async checkPersonSignUp(params){
        return api.put(`/admin/personal/training/verify`, params)
    }

    /**
     * 天梯单排赛-修改报名位置
     */
    static async updatePersonSignUpPosition (params){
        return api.put(`/admin/personal/training/position`, params);
    }

    /**
     * 天梯单排赛-移除选手报名
     */
    static async deletePersonSignUp(params){
        return api.delete(`/admin/personal/training/signUp?signUpIds=${params.signUpIds}&trainingId=${params.trainingId}`);
    }

    /**
     * 天梯单排赛-推送消息给选手
     */
    static async personTrainingNotice(params){
        return api.post(`/admin/personal/training/notice`, params)
    }

    /**
     * 天梯单排赛-获取个人战绩
     */
    static async getPersonTrainingGrade(params){
        let {trainingId} = params;
        return api.get(`/admin/personal/training/${trainingId}/personal/grade`, params)
    }

    /**
     * 天梯单排赛-配置回放视频
     * @param {Number} id
     */
    static async addPersonVideoConfig(trainingId, params) {
        return api.post(`/admin/personal/training/${trainingId}/video`, params);
    }

    /**
     * 天梯单排赛-获取回放视频列表
     */
     static async queryPersonVideoList(params) {
        return api.get(`/admin/personal/training/video`, params);
    }

    /**
     * 天梯单排赛-编辑回放视频
     * @param {Number} videoId
     */
     static async updatePersonVideo(videoId, params) {
        return api.put(`/admin/personal/training/video/${videoId}`, params);
    }

    /**
     * 天梯单排赛-删除回放视频
     * @param {Number} id
     */
     static async deletePersonVideo(videoId){
        return api.delete(`/admin/personal/training/video/${videoId}`);
    }

    /**
     * 天梯单排赛-获取品牌列表
     */
    static async getPersonBrand(params){
        return api.get(`/admin/personal/brand/admin`, params);
    }

    /**
     * 天梯四排赛-获取品牌列表
     */
    static async getFourSomeBrand(params){
        return api.get(`/admin/mixed_team/brand/admin`, params);
    }
    
    /**
     * 天梯单排赛-获取赛事模板列表
     */
     static async getPersonTrainingTemplateList(params) {
        return api.get(`/admin/personal/training/template`, params);
    }
    
    /**
     * 天梯单排赛-新增赛事模板
     */
     static async addPersonTrainingTemplate(params) {
        return api.post(`/admin/personal/training/template`, params);
    }

    /**
     * 天梯单排赛-修改赛事模板
     */
     static async updatePersonTrainingTemplate(templateId, parmas) {
        return api.put(`/admin/personal/training/template/${templateId}`, parmas);
    }

    /**
     * 天梯单排赛-删除赛事模板
     */
     static async deletePersonTrainingTemplate(templateId) {
        return api.delete(`/admin/personal/training/template/${templateId}`);
    }

    /**
     * 天梯单排赛-获取赛事模板字典
     */
     static async getPersonTrainingTemplateDictionaries(){
        return api.get(`/admin/personal/training/template/dictionaries`);
    }

    /**
     * 天梯单排赛-获取赛事模板信息
     */
     static async getPersonTrainingTemplate(game, brandId, type) {
        return api.get(`/admin/personal/training/template/${game}/${brandId}/${type}`);
    }

    /**
     * 获取品牌配置数据榜列表(天梯单排赛)
     */
    static async getPersonalRankDataConfigBrands(){
        return api.get(`/admin/personal/training/rank/data/config/brands`);
    }      
    
    /**
     * 创建配置数据榜(天梯单排赛)
     */
    static async createPersonalRandDataConfig(params){
        return api.post(`/admin/personal/training/rank/data/config`, params);                         
    }

    /**
     * 选手战队基础配置数据榜(天梯单排赛)
     */
    static async getPersonalRankBaseDataConfig(){
        return api.get(`/admin/personal/training/rank/base_data/config`);
    }    

    /**
     * 获取品牌配置数据榜(天梯单排赛)
     */
    static async getPersonalRankDataConfig(id){
        return api.get(`/admin/personal/training/rank/data/config/brands/${id}`);
    }   

    static async deletePersonalRankDataConfig(id){
        return api.delete(`/admin/personal/training/rank/data/config/${id}`);
    }
        
    /**
     * 创建配置数据榜(天梯单排赛)
     */
    static async updatePersonalRandDataConfig(params){
        return api.put(`/admin/personal/training/rank/data/config`, params);
    }    

    /**
     * 榜单字典(天梯单排赛)
     */
     static async getPersonalSortFieldDictionaries(params){
        return api.get(`/admin/personal/training/sort_field/dictionaries`, params);
    }

    /**
     * 训练赛选手榜数据排行(天梯单排赛)
     */
     static async getPersonalTrainingPlayerRankList(params){
        return api.get(`/admin/personal/training/player_rank`, params);
    }

    /**
     * 训练赛选手榜数据排行数据批量修改(天梯单排赛)
     */
     static async updatePersonalTrainingPlayerRank(params){
        return api.put(`/admin/personal/training/player_rank`, params);
    }
    
    /**
     * 获取品牌配置数据榜列表(天梯四排赛)
     */
    static async getFourSomeRankDataConfigBrands(){
        return api.get(`/admin/mixed_team/training/rank/data/config/brands`);
    }

    /**
     * 创建配置数据榜(天梯四排赛)
     */
    static async createFourSomeRankDataConfig(params){
        return api.post(`/admin/mixed_team/training/rank/data/config`, params);                         
    }

    /**
     * 选手战队基础配置数据榜(天梯四排赛)
     */
    static async getFourSomeRankBaseDataConfig(){
        return api.get(`/admin/mixed_team/training/rank/base_data/config`);
    }    

    /**
     * 获取品牌配置数据榜(天梯四排赛)
     */
    static async getFourSomeRankDataConfig(id){
        return api.get(`/admin/mixed_team/training/rank/data/config/brands/${id}`);
    }   

    static async deleteFourSomeRankDataConfig(id){
        return api.delete(`/admin/mixed_team/training/rank/data/config/${id}`);
    }
        
    /**
     * 创建配置数据榜(天梯四排赛)
     */
    static async updateFourSomeRankDataConfig(params){
        return api.put(`/admin/mixed_team/training/rank/data/config`, params);
    }    

    /**
     * 榜单字典(天梯四排赛)
     */
    static async getFourSomeSortFieldDictionaries(params){
        return api.get(`/admin/mixed_team/training/sort_field/dictionaries`, params);
    }

    /**
     * 训练赛选手榜数据排行(天梯四排赛)
     */
    static async getFourSomeTrainingPlayerRankList(params){
        return api.get(`/admin/mixed_team/training/player_rank`, params);
    }

    /**
     * 训练赛选手榜数据排行数据批量修改(天梯四排赛)
     */
    static async updateFourSomeTrainingPlayerRank(params){
        return api.put(`/admin/mixed_team/training/player_rank`, params);
    }

}
export default TrainingRequest;
