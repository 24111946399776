
export const stateType = {
  NORMAL: {
    label: '正常',
    value:'NORMAL',
  },
  BAN: {
    label: '封禁',
    value:'BAN'
  },
};

export const genderType = {
  UNKNOWN: {
    label: '未知',
    value: 'UNKNOWN'
  },
  MALE: {
    label: '男',
    value: 'MALE'
  },
  FEMALE: {
    label: '女',
    value: 'FEMALE'
  },
}
