/**
 * Created by Administrator on 2019/12/4 0004.
 */
import React from "react";
import {Checkbox, Col, Form, Input, Modal, Row, Select, TimePicker,Divider} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl-context";
import { FloorPosition, enumToArray } from '../../../dictionary/dictionary';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 10},
    wrapperCol: {span: 14},
};
const formItemLayoutW = {
    labelCol: {span: 5},
    wrapperCol: {span: 19},
};

// const props = {
//     multiple: true,
//     onStart(file) {
//         console.log('onStart', file, file.name);
//     },
//     onSuccess(ret) {
//         alert(JSON.stringify(ret));
//         console.log('onSuccess', ret);
//     },
//     onError(err) {
//         console.log('onError', err);
//     },
//     beforeUpload(file, fileList) {
//         console.log(file, fileList);
//         return new Promise((resolve) => {
//             console.log('start check');
//             setTimeout(() => {
//                 console.log('check finshed');
//                 resolve(file);
//             }, 3000);
//         });
//     },
// };

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};
const options = [
    {label: '周一', value: 1},
    {label: '周二', value: 2},
    {label: '周三', value: 3},
    {label: '周四', value: 4},
    {label: '周五', value: 5},
    {label: '周六', value: 6},
    {label: '周日', value: 0},
];

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class ChangeTrailPlanModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: null,
            isChangeBrandId: false,
            selectedBrandId: null,
        };
    }


    componentDidMount() {
        
    }

    renderTitle = () => {
        const {showId} = this.props;
        if (showId) {
            return (
                <div>添加试训计划</div>
            )
        } else return (
            <div>修改试训计划</div>
        )
    }
    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess, defaultValue} = this.props;
                if(values.brandId == null) {
                    delete values.brandId;
                    values['trainingNumber'] = 0;
                    values['avgEliminate'] = 0;
                }
                let params = {...values,demand:"无其他俱乐部或经济合同在身的自由选手 服务俱乐部管理，无不良嗜好 拥有团队精神与意识，抗压能力强"}

                console.log('defaultValue',defaultValue)
                 console.log('params',params)
                
                onSuccess && onSuccess(params);
                // console.log("ooossss",onSuccess)
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {visible, defaultValue, trailPlanBrands} = this.props;
        let {isChangeBrandId, selectedBrandId} = this.state;
        let positions = enumToArray(FloorPosition).slice(0, 5); //场上位置枚举
        
        return (
            <Modal
                visible={visible}
                title={this.renderTitle()}
                // onCancel={this.props.onClose}
                onCancel={() => {
                    this.props.onClose();
                    this.setState({
                        isChangeBrandId: false,
                        selectedBrandId:null
                    })
                }}
                destroyOnClose={true}
                onOk={this.onSubmit}
                okText={"确认"}
                cancelText={"取消"}
                width={750}
            >
                <Form>
                <Row>
                        <Col span={12}>
                            <FormItem
                                label="俱乐部ID："
                                {...formItemLayout}
                            >
                                {getFieldDecorator('clubId', {
                                    rules: [{
                                        required: true,
                                        message: '请填写俱乐部ID',
                                    }],
                                    initialValue: defaultValue.clubId,
                                })(
                                    <Input placeholder="请填写俱乐部ID"/>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="试训管理员ID："
                                {...formItemLayout}
                            >
                                {getFieldDecorator('exhibitionId', {
                                    rules: [{required: true, message: '请填写试训管理员ID'}],
                                    initialValue: defaultValue.exhibitionId,
                                })(
                                    <Input placeholder="请填写试训管理员ID"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Divider />
                    
                    <Row>
                        
                        <Col span={12}>
                            <FormItem
                                label="试训位置"
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('floorPosition', {
                                    rules: [{required: true, message: '请选择试训位置'}],
                                    initialValue: defaultValue.floorPosition,
                                })(
                                    <Select style={{}}>
                                        {positions.map(item => {
                                        return (
                                            <Option value={item.value}>{item.name}</Option>
                                        );
                                })}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormItem
                                label="试训日期"
                                {...formItemLayoutW}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator("trailDate", {
                                    initialValue: defaultValue.trailDate,
                                    rules: [
                                        {
                                            required: true, message: '请选择试训日期'
                                        }
                                    ]
                                })(
                                    <CheckboxGroup options={options}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
            

                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="试训开始时间"
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('beginTime', {
                                    rules: [{required: true, message: '请填写试训开始时间'}],
                                    initialValue: defaultValue.beginTime ? moment(defaultValue.beginTime, 'HH:mm:ss') : '',
                                    // initialValue: showId ? '' : moment(defaultValue.beginTime, 'HH:mm:ss'),
                                })(
                                    <TimePicker size="large"
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="试训结束时间"
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('endTime', {
                                    rules: [{required: true, message: '请填写试训结束时间'}],
                                    initialValue: defaultValue.endTime ? moment(defaultValue.endTime, 'HH:mm:ss') : '',
                                    // initialValue: showId ? '' : moment(defaultValue.endTime, 'HH:mm:ss'),
                                })(
                                    <TimePicker size="large"
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="试训比赛安排："
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('trainGamePlan', {
                                    rules: [{
                                        required: true,
                                        message: '请选择试训比赛安排'
                                    }],
                                    initialValue: defaultValue.trainGamePlan ? defaultValue.trainGamePlan : null,
                                })(
                                    <Select style={{}}>
                                    <Select.Option value="官训T1组训练赛">官训T1组训练赛</Select.Option>
                                    <Select.Option value='官训T2组训练赛'>官训T2组训练赛</Select.Option>
                                    <Select.Option value='官训T3组训练赛'>官训T3组训练赛</Select.Option>
                                    <Select.Option value='官训T4组训练赛'>官训T4组训练赛</Select.Option>
                                    <Select.Option value='官训T5组训练赛'>官训T5组训练赛</Select.Option>
                                </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem label="试训局数"{...formItemLayout}>
                                {getFieldDecorator('times', {
                                    rules: [{required: true, message: '试训局数需大于3', pattern: /^[3-9]|([1-9][0-9]+)$/}],
                                    initialValue: defaultValue.times
                                })(
                                    <Input placeholder="请填写试训局数"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Divider />

                    <Row>
                        {/*<Col span={12}>*/}
                        {/*    <FormItem*/}
                        {/*        label="价格(浪币):"*/}
                        {/*        {...formItemLayout}*/}
                        {/*    >*/}
                        {/*        {getFieldDecorator('price', {*/}
                        {/*            rules: [{required: true, message: '试训价格需大于0', pattern: /^\+?[1-9]\d*$/}],*/}
                        {/*            initialValue: defaultValue.price,*/}
                        {/*        })(*/}
                        {/*            <Input placeholder="请填写试训价格"/>,*/}
                        {/*        )}*/}
                        {/*    </FormItem>*/}
                        {/*</Col>*/}
                        <Col span={12}>
                            <FormItem
                                label="FPP模式段位要求："
                                {...formItemLayout}
                            >
                                {getFieldDecorator('fppPosition', {
                                    // rules: [{required: true, message: '试训价格需大于0'}],
                                    initialValue: defaultValue.fppPosition,
                                })(
                                    <Select style={{}}>
                                        {/*<Select.Option value="青铜">青铜</Select.Option>*/}
                                        {/*<Select.Option value='白银'>白银</Select.Option>*/}
                                        {/*<Select.Option value='黄金'>黄金</Select.Option>*/}
                                        {/*<Select.Option value='铂金'>铂金</Select.Option>*/}
                                        {/*<Select.Option value='星钻'>星钻</Select.Option>*/}
                                        {/*<Select.Option value='皇冠'>皇冠</Select.Option>*/}
                                        <Select.Option value='超级王牌'>超级王牌</Select.Option>
                                        <Select.Option value='无敌战神'>无敌战神</Select.Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="FPP模式KD要求："
                                {...formItemLayout}
                            >
                                {getFieldDecorator('fppKd', {
                                    // rules: [{required: true, message: '请填写FPP模式KD'}],
                                    initialValue: defaultValue.fppKd,
                                })(
                                    <Input placeholder="请填写FPP模式KD"/>,
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="训练赛等级要求："
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('brandId', {
                                    
                                    initialValue: defaultValue.brandId ? defaultValue.brandId : null,
                                })(
                                    <Select onChange={(e)=>{
                                        console.log(e)
                                        this.setState({selectedBrandId:e,isChangeBrandId:true})
                                    }}>
                                        {trailPlanBrands.map((item) => <Select.Option value={item.brandId} key={item.brandId}>{item.name}</Select.Option>)}
                                        <Select.Option value={null} key={null}>无赛训经历</Select.Option>                    
                                    </Select>
                                )}

                                
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="选手年龄要求："
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('age', {
                                    rules: [{
                                        required: true,
                                        message: '请填写试训要求年龄范围，格式：14-18',
                                        pattern: /^(?:[1-9]?\d|100)-(?:[1-9]?\d|100)$/
                                    }],
                                    initialValue: defaultValue.age,
                                })(
                                    <Input placeholder="请填写年龄范围，如14-18"/>
                                )}
                            </FormItem>
                        </Col>
                        
                    </Row>
                    {(isChangeBrandId?selectedBrandId:defaultValue.brandId)  &&
                    <Row>
                   
                        <Col span={12}>
                            <FormItem
                                label="训练赛场数要求："
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('trainingNumber', {
                                    rules: [{
                                        required: true,
                                        message: '请选择训练赛场数'
                                    }],
                                    initialValue: defaultValue.trainingNumber ? defaultValue.trainingNumber : '',
                                })(
                                    <Select style={{}}>
                                        <Select.Option value={500}>500</Select.Option>
                                        <Select.Option value={400}>400</Select.Option>
                                        <Select.Option value={300}>300</Select.Option>
                                        <Select.Option value={200}>200</Select.Option>
                                        <Select.Option value={150}>150</Select.Option>
                                        <Select.Option value={100}>100</Select.Option>
                                        <Select.Option value={50}>50</Select.Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label="训练赛场均淘汰要求："
                                {...formItemLayout}
                                style={{justifyContent: 'flex-start'}}
                            >
                                {getFieldDecorator('avgEliminate', {
                                    rules: [{
                                        required: true,
                                        message: '请选择训练赛场均淘汰'
                                    }],
                                    initialValue: defaultValue.avgEliminate ? defaultValue.avgEliminate : '',
                                })(
                                    <Select style={{}}>
                                        <Select.Option value={3.0}>3.0</Select.Option>
                                        <Select.Option value={2.5}>2.5</Select.Option>
                                        <Select.Option value={2.0}>2.0</Select.Option>
                                        <Select.Option value={1.5}>1.5</Select.Option>
                                        <Select.Option value={1.0}>1.0</Select.Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                        
                    </Row>
                    }
                    <Divider />
                    <Row>
                        <Col span={12}>
                            <FormItem
                                label="试训计划推荐权重"
                                {...formItemLayout}
                            >
                                {getFieldDecorator('priority', {
                                    // rules: [{
                                    //     required: true,
                                    //     message: '试训计划推荐权重范围未0-100',
                                    //     pattern: /^([1-9]?\d|100)$/
                                    // }],
                                    initialValue: defaultValue.priority
                                })(
                                    <Input placeholder="试训计划推荐权重范围0-100"/>,
                                )}
                            </FormItem>
                        </Col>
                        
                    </Row>
                </Form>
            </Modal>

        );
    }
}

ChangeTrailPlanModel.propTypes = propTypes;
ChangeTrailPlanModel.defaultProps = defaultProps;
const mapStateToProps = (state => {
        return {}
    }
);

const mapDispatchToProps = {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Form.create()(withRouter(injectIntl(ChangeTrailPlanModel))));

