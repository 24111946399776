import createAsyncAction from 'utils/createAsyncAction';
import CommentRequest from '../../request/CommentRequest';
import {GET_COMMENTS
} from "../comment/actionType";



const getComments = (pageNo, pageSize,dynamicId,userId,commentContent,commentStatus,dateRange) => (
    createAsyncAction(GET_COMMENTS, () => (CommentRequest.getComment(pageNo, pageSize,dynamicId,userId,commentContent,commentStatus,dateRange)))
);


export {
    getComments
};