import api from '../utils/api';

class TrailTrailPlanRequest {

    static getTrailPlans(page, size, trailPlanPageType, game) {
        console.log(page, size)
        return api.get('/admin/clubs/trail-plans', {page, size, trailPlanPageType, game});
    }

    static updateTrailPlan(clubId, {priority, points}) {
        return api.put(`/admin/clubs/${clubId}/trail-plans`, {priority, points});
    }

    static getTrailPlan(clubId) {
        return api.get(`/admin/clubs/${clubId}/trail-plans`);
    }

    //获取所有游戏
    static getGame() {
        return api.get('/admin/games');
    }
}

export default TrailTrailPlanRequest;