import React from 'react';
import {Button, Card, Col, message, Modal, Row, Table, Tabs,} from 'antd';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl-context';
import {operationTypeValue} from 'modules/certification/enums';
import {
    addLadderCertification,
    getLadderCertificationList, 
    onUpdateLadderCertificationUpOrDown,
    onUpdateLadderUserSuspend,
    onDeleteLadderCertification,
    onUpdateLadderPlayerPosition
} from "../../modules/certification/action";
import CertificationInfoModel from "./components/CertificationInfoModel";
import CertificationFilter from "./components/CertificationFilter";
import AddCertificationModel from "./components/AddCertificationModel";
import UpdateCertificationModel from "./components/UpdateCertificationModel";
import PlayerPositionModel from './components/PlayerPositionModel';
import { FloorPosition } from '../../dictionary/dictionary';

const {confirm} = Modal;
const {TabPane} = Tabs;

class Certification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            previewVisible: false,
            previewImage: '',
            operationType: operationTypeValue.INCREASE,
            isShowCertificationInfoModel: false,
            isShowAddCertificationModel: false,
            isShowUpdateCertificationInfoModel: false,
            showSetPlayerPositionModel: false,
            seletionValue: null,
            page: 1,
            size: 10,
            brandId: '',
            certId: null,
            floorPosition: '',
            status: 'SUCCESS', //显示所有参赛认证数据，不区分是否审核
        };
    }

    componentWillMount() {
        this.onGetTableData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.ladderCertifications !== this.props.ladderCertifications) {
            this.setState({
                loading: false
            })
        }
    }

    // 刷新界面数据
    onGetTableData = () => {
        const { getLadderCertificationList } = this.props;
        const {page, size, status} = this.state;
        this.setState({
            loading: true
        })
        getLadderCertificationList({
            page: page, size: size
        });
    }

    onPageChangeEvent = (current, pageSize) => {
        const {getLadderCertificationList} = this.props;
        getLadderCertificationList({
            page: current, size: pageSize
        });
        this.setState({
            page: current,
            size: pageSize,
            loading: true
        });
    }

    // 添加参赛认证
    onAddFormSubmit = async (values, personBrandData) => {
        const {isShowAddCertificationModel} = this.state;
        const {addLadderCertification} = this.props;
        let filterArr = personBrandData.data.filter(item => item.id === values.brandId);
        values['game'] = filterArr.length > 0 ? filterArr[0].game : 'HPJY';
        await addLadderCertification(values);
        this.setState({
            loading: true
        })
        await this.onGetTableData();
        message.success('新增成功');
        this.setState({isShowAddCertificationModel: !isShowAddCertificationModel});
    }

    // 修改参赛认证升降级
    onUpdateCertificationUpOrDown = (text, type) => {
        let _this = this;
        let tipText = type === 'UP' ? '升级' : '降级';
        let params = {
            brandId: text.brandId, 
            type, 
            userId: text.userId
        };
        
        confirm({
            content: '确认要' + tipText + '该用户的参赛认证吗？',
            okText: '确认',
            cancelText: '取消',
            onOk() {
                _this.onUpdateUpOrDownSubmit(params);
            },
            onCancel() {
            },
        });
    }

    onUpdateUpOrDownSubmit = async (params) => {
        const {onUpdateLadderCertificationUpOrDown} = this.props;
        let tipText = params.type === 'UP' ? '升级成功' : '降级成功';

        await onUpdateLadderCertificationUpOrDown(params);
        this.setState({
            loading: true
        });
        await this.onGetTableData();
        message.success(tipText);
    }

    // 设置用户禁赛或解禁
    onUpdateUserSuspend = (certId, suspend) => {
        let _this = this;
        let content = suspend ? '确认要为该用户解除禁赛吗？' : '确认要将该用户禁赛吗？';
        confirm({
            content: content,
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onUpdateUserSuspendSubmit({certId, suspend: !suspend});
            },
            onCancel() {
            },
        });
    }

    onUpdateUserSuspendSubmit = async (values) => {
        const {onUpdateLadderUserSuspend} = this.props;

        await onUpdateLadderUserSuspend(values);
        this.setState({
            loading: true
        });
        await this.onGetTableData();
        message.success("操作成功");
    }

    // 删除参赛认证
    onDeleteCertification = (certId) => {
        let _this = this;
        confirm({
            content: '确认要删除该用户的参赛认证吗？',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                _this.onDeleteCertificationSubmit(certId);
            },
            onCancel() {
            },
        });
    }

    onDeleteCertificationSubmit = async (certId) => {
        const {onDeleteLadderCertification} = this.props;

        await onDeleteLadderCertification(certId);
        this.setState({
            loading: true
        });
        await this.onGetTableData();
        message.success("删除成功");
    }
    
    // 设置场上位置
    setPlayerPosition = (certId, floorPosition) => {
        this.setState({
            showSetPlayerPositionModel: true,
            certId,
            floorPosition
        });
    }

    onSetPlayerPosition = async (values) => {
        const{ onUpdateLadderPlayerPosition } = this.props;
        let { certId } = this.state;
        let params = {
            certificationId: certId,
            floorPosition: values.floorPosition
        };

        await onUpdateLadderPlayerPosition(params);
        await this.onGetTableData();
        message.success('设置场上位置成功');
        this.setState({showSetPlayerPositionModel: false});
    }

    //条件查找动态方法
    onFilterCertificationEvent = async (values) => {
        const {size, status} = this.state;
        const {getLadderCertificationList} = this.props;
        this.setState({
            loading: true
        })
        await getLadderCertificationList({
            page: 1, size: size,
            ...values
        });
    }

    // changeTabPane = async (key) => {
    //     const {size,} = this.state;
    //     const {getLadderCertificationList} = this.props;
    //     this.setState({
    //         status: key ? key : null,
    //         page: 1,
    //         brandId: null,
    //         game: null,
    //         teamShowId: null,
    //         teamName: null,
    //         leaderQQ: null,
    //         loading: true
    //     })
    //     await getLadderCertificationList({
    //         page: 1, size: size,
    //         status: key ? key : null
    //     });
    // }

    renderTableTitle = () => {
        return (
            <Row type="flex" justify="end">
                <Col>
                    <Button type="primary"
                            onClick={() => this.setState({isShowAddCertificationModel: true})}>新增认证</Button>
                </Col>
            </Row>
        )
    }

    renderTabPane = () => {
        const { ladderCertifications } = this.props;
        const {
            isShowCertificationInfoModel, isShowUpdateCertificationInfoModel, isShowAddCertificationModel, showSetPlayerPositionModel,
            floorPosition, seletionValue, page, size, status, loading, previewVisible, previewImage,
        } = this.state;
        return (
            <div>
                <Card style={{marginBottom: 30}}>
                    <CertificationFilter value={status} onCommit={this.onFilterCertificationEvent}/>
                </Card>

                <Card>
                    <Table
                        dataSource={ladderCertifications ? ladderCertifications.data : []}
                        columns={this.getTableColumns()}
                        title={this.state.status === "SUCCESS" ? this.renderTableTitle : null}
                        bordered
                        pagination={{
                            // total: teams ? teams.total : 0,
                            total: ladderCertifications.total,
                            current: page,
                            pageSize: size,
                            onChange: this.onPageChangeEvent,
                            showTotal: (total) => {
                                return `总计 ${total}条数据`;
                            }
                        }}
                        loading={loading}
                    />
                    {isShowCertificationInfoModel ? <CertificationInfoModel
                        value={seletionValue}
                        onSuccess={this.onFormSubmitConfirm}
                        visible={isShowCertificationInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowCertificationInfoModel: !isShowCertificationInfoModel,
                            });
                        }}
                    /> : null}
                    {isShowAddCertificationModel ? <AddCertificationModel
                        onSuccess={this.onAddFormSubmit}
                        visible={isShowAddCertificationModel}
                        onClose={() => {
                            this.setState({
                                isShowAddCertificationModel: !isShowAddCertificationModel,
                            });
                        }}
                    /> : null}
                    {isShowUpdateCertificationInfoModel ? <UpdateCertificationModel
                        value={seletionValue}
                        onSuccess={this.onUpdateFormSubmitConfirm}
                        visible={isShowUpdateCertificationInfoModel}
                        onClose={() => {
                            this.setState({
                                isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
                            });
                        }}
                    /> : null}
                    {showSetPlayerPositionModel && <PlayerPositionModel
                        visible={showSetPlayerPositionModel}
                        floorPosition={floorPosition}
                        onSuccess={this.onSetPlayerPosition}
                        onClose={()=>{
                            this.setState({
                                showSetPlayerPositionModel: false
                            })
                        }}
                    />}
                </Card>
            </div>
        )
    }

    render() {
        return (
            <div>
                {/* <Tabs onChange={this.changeTabPane} type="card">
                    <TabPane tab="参赛认证列表(审核中)" key="REVIEW">
                        {this.state.status === "REVIEW" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="参赛认证列表(通过)" key="SUCCESS">
                        {this.state.status === "SUCCESS" ? this.renderTabPane() : null}
                    </TabPane>
                    <TabPane tab="参赛认证列表(拒绝)" key="FAIL">
                        {this.state.status === "FAIL" ? this.renderTabPane() : null}
                    </TabPane>
                </Tabs> */}
                {this.renderTabPane()}
            </div>
        );
    }

    onGetCertificationInfoEvent = async (value) => {
        const {isShowCertificationInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowCertificationInfoModel: !isShowCertificationInfoModel,
        });
    }

    onUpdateCertificationInfoEvent = async (value) => {
        const {isShowUpdateCertificationInfoModel} = this.state;
        this.setState({
            seletionValue: value,
            isShowUpdateCertificationInfoModel: !isShowUpdateCertificationInfoModel,
        });
    }

    getTableColumns = () => [{
        title: '品牌赛事名',
        dataIndex: 'brandName',
        align: 'center',
        key: 'brandName',
    }, {
        title: '用户名称',
        dataIndex: 'userName',
        align: 'center',
        key: 'userName',
    }, {
        title: '用户ID',
        dataIndex: 'exhibitionId',
        align: 'center',
        key: 'exhibitionId',
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        align: 'center',
        key: 'createTime',
    }, {
        title: '操作',
        dataIndex: 'details',
        key: 'details',
        align: 'center',
        render: (row, text) => (
            <div>
                <Button style={{marginRight: 5, marginBottom: 5}} type={text.floorPosition ? "dashed" : "primary"}
                    onClick={() => this.setPlayerPosition(text.id, text.floorPosition)} >{text.floorPosition ? FloorPosition[text.floorPosition] : '设置场上位置'}
                </Button>
                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onUpdateCertificationUpOrDown(text, 'UP')}>{"升级"}</Button>
                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onUpdateCertificationUpOrDown(text, 'DOWN')}>{"降级"}</Button>
                <Button type="primary" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onUpdateUserSuspend(text.id, text.suspend)}>{text.suspend ? "已禁赛" : "禁赛"}</Button>
                <Button type="danger" style={{marginLeft: 10, marginBottom: 10}} onClick={() => this.onDeleteCertification(text.id)}>{"删除"}</Button>
            </div>
        ),
    }]
}

const mapStateToProps = (state) => {
    return {
        ...state.Team,
        ...state.Certification
    }
};

const mapDispatchToProps = ({
    getLadderCertificationList, 
    addLadderCertification,
    onUpdateLadderCertificationUpOrDown,
    onUpdateLadderUserSuspend,
    onDeleteLadderCertification,
    onUpdateLadderPlayerPosition
});

export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Certification));
