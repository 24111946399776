import React, {Component} from 'react';
import {Form, Input, DatePicker,Modal,Select} from 'antd';
import PropTypes from 'prop-types';
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};



class AddCareerModal extends Component {
    componentWillMount() {
    }

    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) { 
                let {onSubmitEvent} = this.props;
                if(values &&!(Array.isArray(values.pelLeagueTypes))){
                    values.pelLeagueTypes = values.pelLeagueTypes.split();
                }
                if(values && values.joinDate){
                    values.joinDate = moment(values.joinDate).format('YYYY-MM-DD');
                }
                if(values && values.quirDate){
                    values.quirDate = moment(values.quirDate).format('YYYY-MM-DD');
                }
                onSubmitEvent && onSubmitEvent(values.joinDate, values.pelLeagueTypes, values.quirDate,values.teamShowId);
            }
        });
    }

    handleChange(value) {
        console.log(`selected---${value}`);
    }

    renderTitle = () => {
        const {addFlag} = this.props;
        if (addFlag) {
            return (
                <div>添加选手职业生涯</div>
            )
        } else return (
            <div>修改选手职业生涯</div>
        )
    }

    renderSelect = ()=>{
        const {pelLeagueType} = this.props;
        if(pelLeagueType&&pelLeagueType.length>0){
            return (
                <Select mode="multiple" style={{ width: '100%' }} placeholder="请选择服役赛季" onChange={this.handleChange}>
                        {/* <Option value=""></Option> */}
                        {pelLeagueType.map((item,index)=>{
                            return <Option value={item.value}>{item.value}</Option>
                        })}
                </Select>
            )
        }else{
            return (
                <Select mode="multiple" style={{ width: '100%' }} placeholder="请选择服役赛季" onChange={this.handleChange}>
                        <Option value=""></Option>
                </Select>
            )
        }
    }

    render() {
        const {visible, values} = this.props;
        const {getFieldDecorator} = this.props.form;
        const defaultValue = values || {};
        return (
            <Modal
                visible={visible}
                title={this.renderTitle()}
                onCancel={this.props.onCancel}
                onOk={this.onSubmitEvent}
                okText={"确认"}
                cancelText={"取消"}
                destroyOnClose={true}
                width={"30%"}
            >
            <Form>
                <FormItem
                    label="战队ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('teamShowId', {
                        rules: [{required: true, message: '请填写战队ID'}],
                        initialValue: values && values.teamShowId ? values.teamShowId : "",
                    })(
                        <Input placeholder="请填写战队ID"/>,
                    )}
                </FormItem>
                <FormItem
                    label="加入时间"
                    {...formItemLayout}
                >
                    {getFieldDecorator('joinDate', {
                        rules: [{required: true, message: '请填写加入时间'}],
                        initialValue:values&& values.joinDate ? moment(values.joinDate, "YYYY-MM-DD") : moment().subtract('0', 'day'),
                    })(
                        <DatePicker format="YYYY-MM-DD"/>
                    )}
                </FormItem>
                <FormItem
                    label="离开时间"
                    {...formItemLayout}
                >
                    {getFieldDecorator('quirDate', {
                        // rules: [{required: true, message: '请填写离开时间'}],
                        initialValue:values&& values.quitDate ? moment(values.quitDate, "YYYY-MM-DD") : null,
                    })(
                        <DatePicker format="YYYY-MM-DD"/>
                    )}
                </FormItem>
                <FormItem
                    label="服役赛季"
                    {...formItemLayout}
                >
                    {getFieldDecorator('pelLeagueTypes', {
                        rules: [{required: true, message: '请选择服役赛季'}],
                        initialValue: defaultValue.pelLeague
                    })(
                        this.renderSelect()
                    )}
                </FormItem>
            </Form>
            </Modal>
        );
    }
}

AddCareerModal.propTypes = propTypes;

export default Form.create()(AddCareerModal);
