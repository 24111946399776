import React from 'react';
import {Form, Input, Modal, Select, Radio, Checkbox, InputNumber, Button,Switch} from 'antd';
import {getGameList} from "../../../modules/team/action";
import connect from "react-redux/es/connect/connect";
import {injectIntl} from "react-intl-context";
import UpLoad from "../../../components/upload";
import {getBrand} from "../../../modules/training/action";
import {operationTypeValue} from "../../../modules/training/enums";

const {TextArea} = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const defaultProps = {
    value: {},
    visible: false,
    onClose: () => {
    },
};

class RankDataConfigModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentWillMount() {
        this.onInitData();
    }

    onInitData = (props = this.props) => {
        let {getBrand} = this.props;
        getBrand({page: 1, size: 100000});
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {onSuccess} = this.props;
                // eslint-disable-next-line no-unused-expressions
                onSuccess && onSuccess(values);
            }
        });
    }

    onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {visible, value, brandData, rankBaseDataConfig, rankDataConfig, operationType,onChangeBand} = this.props;
        rankDataConfig = operationType===operationTypeValue.INCREASE?rankBaseDataConfig:rankDataConfig
        console.log(this.props,'rankDataConfig.isPermissionOpen ')
        rankDataConfig = rankDataConfig || {}
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <Modal
                width={'50%'}
                visible={visible}
                title="配置数据榜"
                onCancel={this.props.onClose}
                cancelText="取消"
                onOk={this.onSubmit}
                okText="确认"
            >
                <Form>
                    <FormItem label="榜单名称"
                              {...formItemLayout}>
                        {getFieldDecorator('brandId', {
                                initialValue: operationType===operationTypeValue.INCREASE ? "" : rankDataConfig ? rankDataConfig.brandId : "",
                                rules: [{required: true, message: '请填写榜单名称!'}],
                            }
                        )(
                            <Select onChange={e => {
                                console.log(e)
                                onChangeBand && onChangeBand(e)
                                this.setState({brandId: e})
                            }} disabled={operationType===operationTypeValue.UPDATE}>
                                {brandData.data && brandData.data.map(item => {
                                    return (
                                        <Option
                                            value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </Select>
                        )}
                    </FormItem>

                    <FormItem
                        label="榜单周期"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('cycleType', {
                            initialValue: rankDataConfig && rankDataConfig.cycleType ? rankDataConfig.cycleType.split(','):[],
                            rules: [{required: true, message: '请选择榜单周期'}],
                        })(
                            // <Radio.Group onChange={this.onChange} value={'Week'}>
                            //     <Radio style={radioStyle} value={'Week'}>
                            //         按自然周排期
                            //     </Radio>
                            //     <Radio style={radioStyle} value={'Month'}>
                            //         按自然月排期
                            //     </Radio>
                            // </Radio.Group>
                            <Checkbox.Group>
                                    <Checkbox value="Week">按自然周排期</Checkbox>
                                    <Checkbox value="Month">按自然月排期</Checkbox>
                            </Checkbox.Group>
                            
                        )}
                    </FormItem>
                    <FormItem
                        label="选手榜-总数数据"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('checkPlayerTotals', {
                            initialValue: operationType===operationTypeValue.INCREASE ? [] : rankDataConfig ? rankDataConfig.checkPlayerTotalConfigs :[],
                            rules: [{required: true, message: '请选择选手榜总数据!'}],
                        })(
                            <Checkbox.Group options={rankDataConfig ? rankDataConfig.playerTotalConfigs : rankBaseDataConfig.playerTotalConfigs} onChange={this.onChange} />
                        )}
                    </FormItem>
                    <FormItem
                        label="选手榜-平均数据"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('checkPlayerAvgs', {
                            initialValue:operationType===operationTypeValue.INCREASE ? [] : rankDataConfig ? rankDataConfig.checkPlayerAvgConfigs :[],
                            rules: [{required: true, message: '请选择选手榜平均数据!'}],
                        })(
                            <Checkbox.Group options={rankDataConfig ? rankDataConfig.playerAvgConfigs : rankBaseDataConfig.playerAvgConfigs} onChange={this.onChange} />
                        )}
                    </FormItem>
                     <FormItem
                        label="是否开启权限"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('isPermissionOpen', {
                            initialValue:rankDataConfig.isPermissionOpen || false,
                        })(
                            <Radio.Group onChange={()=>{
                                const {setFieldsValue} = this.props.form;
                                setFieldsValue && setFieldsValue({userExhibitionIds:''})
                            }} >
                                <Radio value={true}>
                                    是
                                </Radio>
                                <Radio value={false}>
                                    否
                                </Radio>
                            </Radio.Group>
                            
                        )}
                    </FormItem>
                    
                    <FormItem
                        label="战队榜-总数数据"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('checkTeamTotals', {
                            initialValue:operationType===operationTypeValue.INCREASE ? [] : rankDataConfig ? rankDataConfig.checkTeamTotalConfigs :[],
                            rules: [{required: true, message: '请选择战队榜总数据！'}],
                        })(
                            <Checkbox.Group options={rankDataConfig ? rankDataConfig.teamTotalConfigs : rankBaseDataConfig.teamTotalConfigs} onChange={this.onChange} />
                        )}
                    </FormItem>
                    <FormItem
                        label="战队榜-平均数据"
                        {...formItemLayout}
                    >
                        {getFieldDecorator('checkTeamAvgs', {
                            initialValue:operationType===operationTypeValue.INCREASE ? [] : rankDataConfig ? rankDataConfig.checkTeamAvgConfigs :[],
                            rules: [{required: true, message: '请选择战队榜平均数据!'}],
                        })(
                            <Checkbox.Group options={rankDataConfig ? rankDataConfig.teamAvgConfigs : rankBaseDataConfig.teamAvgConfigs} onChange={this.onChange} />
                        )}
                    </FormItem>
                    <FormItem
                        label="榜单权重"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('randIndex', {
                            initialValue:operationType===operationTypeValue.INCREASE ? '' : rankDataConfig ? rankDataConfig.randIndex : '',
                            rules: [{required: true, message: '请填写榜单权重!'}],
                        })(
                            <InputNumber max={99} min={0}/>,
                        )}
                    </FormItem>
                    <FormItem
                        label="榜单用户展示ID"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formItemLayout}
                    >
                        {getFieldDecorator('userExhibitionIds', {
                            initialValue:operationType===operationTypeValue.INCREASE ? '' : rankDataConfig && rankDataConfig.userExhibitionIds ? rankDataConfig.userExhibitionIds.join(',') : '',
                        })(
                            <Input />,
                        )}
                    </FormItem>
                </Form>
                {operationType!=operationTypeValue.INCREASE?<Button style={{position:'absolute',bottom:'10px'}} type='danger' onClick={()=>{
                    this.props.onDelete && this.props.onDelete(rankDataConfig.id)
                }}>删除</Button>:null}
            </Modal>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.viewTraining,
    }
};
const mapDispatchToProps = ({
    getBrand,
});
RankDataConfigModel.defaultProps = defaultProps;
export default connect(
    mapStateToProps, mapDispatchToProps,
)(injectIntl(Form.create()(RankDataConfigModel)));
