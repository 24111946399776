import api from '../utils/api';
import {map} from 'lodash/fp';


class CircleRequest {
    /**
     * 获取动态列表
     * @param pageNo
     * @param pageSize
     * @param circleName
     * @param userId
     * @param dynamicContent
     * @param DynamicStatus
     * @param dateRange
     * @returns {*}
     */
    static getDynamic(page, size, circleName, userId, dynamicContent,DynamicStatus, dateRange) {
        console.log(page, size, circleName, userId, dynamicContent, dateRange)
        let startTime = null, endTime = null;
        if (dateRange !== undefined) {
            console.log(dateRange)
            dateRange = map(date => date.format('YYYY-MM-DD'))(dateRange);
            startTime = dateRange[0] == undefined ? '' : dateRange[0]+" 00:00:00";
            endTime = dateRange[1] == undefined ? '' : dateRange[1]+" 23:59:59";
        }

        return api.get('/admin/dynamics', {page, size, circleName, userId, dynamicContent, DynamicStatus,startTime, endTime});
    }

    /**
     * 删除动态
     * @param dynamicId
     * @returns {*}
     */
    static deleteDynamic(dynamicId) {

        return api.delete(`/admin/dynamics/${dynamicId}`);
    }

    /**
     * 获取动态评论
     * @param dynamicId
     * @param pageNo
     * @param pageSize
     * @returns {*}
     */
    static getComment(page,size,dynamicId,commentType) {

        return api.get(`/admin/comments`,{page,size,dynamicId,commentType});
    }

    /**
     * 恢复动态
     * @param dynamicId
     * @returns {*}
     */
    static updateDynamicStatus(dynamicId) {

        return api.put(`/admin/dynamics/restore/${dynamicId}`);
    }

    /**
     * 置顶动态
     * @param dynamicId
     * @param allMakeTop
     * @param circleMakeTop
     * @returns {*}
     */
    static topToDynamic(dynamicId,allMakeTop,circleMakeTop) {

        return api.post(`/admin/dynamics/dynamicTop`,{dynamicId,allMakeTop,circleMakeTop});
    }

    /**
     * 删除评论
     * @param commentId
     * @returns {*}
     */
    static deleteComment(commentId) {

        return api.delete(`/admin/comments/${commentId}`);
    }

    /**
     * 恢复评论
     * @param commentId
     * @returns {*}
     */
    static updateCommentStatus(commentId) {

        return api.post(`/admin/comments/restore/${commentId}`);
    }

}

export default CircleRequest;