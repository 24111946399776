import React, {Component} from 'react';
import {injectIntl} from 'react-intl-context';
import {Button, Card, Modal, notification, Table, Popconfirm} from 'antd';
import {connect} from 'react-redux';
import './index.scss';
import {withRouter} from 'react-router-dom';
import {
    getReports,
    getReport,
    deleteReport,
    assign
} from "../../modules/report/action";
import {operationTypeValue} from "../../modules/report/reportEnum";

class FeedbackManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operationType: operationTypeValue.INCREASE, // 默认操作类型为增加
            pageNo: 1,
            pageSize: 10,
            imageUrl: [],
            isShowAssignAdminRoleModal: false,
            isShowImageModal: false,
            currentAdminId: null,
            type: 'FEEDBACK',
            reports: []
        };
    }

    handleOk = e => {
        this.setState({
            isShowImageModal: false,
        })
    };

    handleCancel = e => {
        this.setState({
            isShowImageModal: false,
        })
    }

    componentDidMount() {

        const {pageNo, pageSize, type, result} = this.state;
        let {getReports} = this.props;
        getReports(pageNo, pageSize, type, result);
    }

    showImageUrl = (imageUrl) => {
        console.log(imageUrl);
        if (imageUrl === null) {
            this.setState({
                isShowImageModal: true,
                imageUrl: []
            });
        } else {
            this.setState({
                isShowImageModal: true,
                imageUrl: imageUrl
            });
        }
    }

//删除举报的方法
    onDeleteReportEvent = async (adminId) => {
        const {deleteReport, getReports} = this.props;
        const {pageNo, pageSize, type, result} = this.state;
        try {
            await deleteReport(adminId);
            notification.success({message: '删除成功'});
            console.log(pageNo)

            getReports(pageNo, pageSize, type, result);
        } catch (e) {
            console.log(e);
        }
    }

    onPageChangeEvent = (current, pageSize) => {
        let {getReports} = this.props;
        let {type, result} = this.state;
        this.setState({
            pageNo: current,
            pageSize: pageSize,
        });
        getReports(current, pageSize, type, result);
    }


    render() {
        console.log(this.props.reports)
        const {
            reports, loading
        } = this.props;
        const {
            pageNo, pageSize,
        } = this.state;
        return (
            <div>
                <Card>
                    <Table
                        columns={
                            [
                                {
                                    title: '反馈人',
                                    dataIndex: 'prosecutor',
                                    key: 'prosecutor',
                                    align: 'center',
                                },
                                {
                                    title: '手机号',
                                    dataIndex: 'phone',
                                    key: 'phone',
                                    width:'300px',
                                    align: 'center'
                                },
                                {
                                    title: '反馈内容',
                                    dataIndex: 'reportDetail',
                                    key: 'reportDetail',
                                    align: 'center',
                                    width:'300px',
                                },
                                {
                                    title: '图片',
                                    dataIndex: 'imageUrl',
                                    key: 'imageUrl',
                                    align: 'center',
                                    render: (imageUrl, record) => {
                                        return <Button type="primary" color="blue"
                                                       onClick={() => this.showImageUrl(imageUrl)}>查看</Button>
                                    }
                                },
                                {
                                    title: '反馈时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    align: 'center',
                                },
                                {
                                    title: '操作',
                                    dataIndex: 'operate',
                                    key: 'operate',
                                    align: 'center',
                                    render: (text, record) => {
                                        return <div>
                                            <Popconfirm title={`确定要删除该记录吗？`}
                                                        onConfirm={() => this.onDeleteReportEvent(record.id)}
                                                        okText="确认" cancelText="取消">
                                                <Button type="danger">删除</Button>
                                            </Popconfirm></div>;

                                    }
                                },
                            ]
                        }
                        title={
                            this.renderTableTitle
                        }
                        dataSource={reports.data ? reports.data : []}
                        bordered
                        loading={loading}
                        pagination={{
                            total: reports ? reports.total : 0,
                            current: pageNo,
                            pageSize: pageSize,
                            onChange: this.onPageChangeEvent,
                            showTotal:(total)=>{
                                return `总计 ${total}条数据`;
                            }
                        }}
                    />
                </Card>
                <Modal
                    title="图片查看"
                    visible={this.state.isShowImageModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={"600px"}
                    footer={
                        [] // 设置footer为空，去掉 取消 确定默认按钮
                    }

                >
                    {this.state.imageUrl.map((image) => {
                        return <img align={"center"} alt={""} width={"400px"} src={image ? image+'?x-oss-process=image/resize,w_400' : ''}/>
                    })}
                </Modal>
            </div>
        )
            ;

    }

// --------页面事件结束---------
}

const mapStateToProps = (state => {
        console.log(state.Report)
        return {
            ...state.Report
        }

    }

);

const mapDispatchToProps = {
    getReports,
    getReport,
    deleteReport,
    assign
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(injectIntl(FeedbackManagement)));