import React, {Component} from 'react';
import {Form, Input, DatePicker,Modal} from 'antd';
import PropTypes from 'prop-types';
import moment from "moment";

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 17},
};

const propTypes = {
    onSuccess: PropTypes.func,
    value: PropTypes.any,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
};

class AddEventModal extends Component {
    onSubmitEvent = () => {
        this.props.form.validateFields((err, values) => {
            console.log(values)
            if (!err) {
                let {onSubmitEvent} = this.props;
                if(values && values.resourcesClubId){
                    values.resourcesClubId = parseInt(values.resourcesClubId)
                }
                if(values && values.targetClubId){
                    values.targetClubId = parseInt(values.targetClubId)
                }
                if(values && values.changeDate){
                    values.changeDate = moment(values.changeDate).format('YYYY-MM-DD');
                }
                onSubmitEvent && onSubmitEvent(values.changeDate, values.changeFee, values.resourcesClubId,values.targetClubId);
            }
        });
    }

    renderTitle = () => {
        const {addFlag} = this.props;
        if (addFlag) {
            return (
                <div>添加选手转会纪录</div>
            )
        } else return (
            <div>修改选手转会纪录</div>
        )
    }

    render() {
        const {visible, values} = this.props;
        console.log("values" + values)
        const {getFieldDecorator} = this.props.form;

        return (
            <Modal
                visible={visible}
                title={this.renderTitle()}
                onCancel={this.props.onCancel}
                onOk={this.onSubmitEvent}
                okText={"确认"}
                cancelText={"取消"}
                destroyOnClose={true}
                width={"30%"}
            >
            <Form>
                <FormItem
                    label="转会时间"
                    {...formItemLayout}
                >
                    {getFieldDecorator('changeDate', {
                        rules: [{required: true, message: '请填写转会时间'}],
                        initialValue:values&& values.changeDate ? moment(values.changeDate, "YYYY-MM-DD") : moment().subtract('0', 'day'),
                    })(
                        <DatePicker format="YYYY-MM-DD"/>
                    )}
                </FormItem>
                <FormItem
                    label="转会费用"
                    {...formItemLayout}
                >
                    {getFieldDecorator('changeFee', {
                        rules: [{message: '请填写转会费用', pattern: /^\S+$/}],
                        initialValue: values && values.changeFee ? values.changeFee : "",
                    })(
                        <Input placeholder="请填写转会费用" type="number"/>,
                    )}
                </FormItem>
                <FormItem
                    label="原俱乐部ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('resourcesClubId', {
                        rules: [{message: '请填写原俱乐部ID', pattern: /^\S+$/}],
                        initialValue: values && values.resourcesClubId ? values.resourcesClubId : "",
                    })(
                        <Input placeholder="请填写原俱乐部ID" />,
                    )}
                </FormItem>
                <FormItem
                    label="新俱乐部ID"
                    {...formItemLayout}
                >
                    {getFieldDecorator('targetClubId', {
                        rules: [{required: true, message: '请填写新俱乐部ID', pattern: /^\S+$/}],
                        initialValue: values && values.targetClubId ? values.targetClubId : "",
                    })(
                        <Input placeholder="请填写新俱乐部ID"/>,
                    )}
                </FormItem>
            </Form>
            </Modal>
        );
    }
}

AddEventModal.propTypes = propTypes;

export default Form.create()(AddEventModal);
