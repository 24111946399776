import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select} from "antd";
import moment from "moment";
import MoneyFilter from "./MoneyFilter";
const dateFormat = 'YYYY/MM/DD';
const FormItem = Form.Item;
const Option = Select.Option;
const {RangePicker} = DatePicker;


class PayFilter extends Component{

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }



    componentDidMount() {
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {userId,status,dateRange} = values;

                console.log(values)

                onCommit && onCommit(userId,status,dateRange);
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let _self = this;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let {onCommit} = _self.props;
                let {userId,status,dateRange,attachType,orderId,payChannel} = values;

                console.log(values)

                onCommit && onCommit(userId,status,dateRange,attachType,orderId,payChannel);
            }
        });
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const {dataSource} = this.props;
        const {userId} = dataSource;
        return (
            <div>
            <Form onSubmit={this.handleSubmit} layout="inline">

                <FormItem label="用户展示ID">
                    {getFieldDecorator('userId', {
                            initialValue: userId

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="订单号">
                    {getFieldDecorator('orderId', {
                            initialValue: ""

                        }
                    )(
                        <Input style={{width: '150px'}} allowClear  />
                    )}
                </FormItem>
                <FormItem label="充值渠道">
                    {getFieldDecorator('payChannel', {
                            initialValue: ""

                        }
                    )(
                        <Select style={{width: 120}} allowClear>
                            <Option value="">全部</Option>
                            <Option value="APPLEPAY">苹果充值</Option>
                            <Option value="WX">微信充值</Option>
                            <Option value="ALIPAY">支付宝充值</Option>
                            <Option value="WX_LITE">小程序充值</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="商品类型">
                    {getFieldDecorator('attachType', {
                            initialValue: ""

                        }
                    )(
                        <Select style={{width: 120}} allowClear>
                            <Option value="">全部</Option>
                            <Option value="vip">VIP充值包</Option>
                            <Option value="recharge">浪币充值包</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="状态">
                    {getFieldDecorator('status', {
                            initialValue: ""
                        }
                    )(
                        <Select style={{width: 120}}>
                            <Option value="">全部</Option>
                            <Option value="INIT">初始化状态</Option>
                            <Option value="UNIFIED">未支付</Option>
                            <Option value="COMPLETE">完成</Option>
                            <Option value="REFUNDED">已退款</Option>
                            <Option value="CLOSE">关闭</Option>
                        </Select>
                    )}
                </FormItem>
                <FormItem label="发布时间区间：">
                    {getFieldDecorator('dateRange', {
                        initialValue: [moment().subtract('7','day'), moment().subtract('0','day')]
                    })(<RangePicker
                        allowClear
                        format={dateFormat}
                    />)}
                </FormItem>

                <FormItem>
                    <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                        查询
                    </Button>
                </FormItem>

            </Form>

                <MoneyFilter {...this.props}/>
            </div>
        );
    }
}



export default Form.create()(PayFilter);