import Login from 'pages/login';
import Unauthorized from 'pages/unauthorized';
import MenuManagement from 'pages/menu-management';
import RoleManagement from 'pages/role-management';
import TeamManagement from 'pages/team-management';
import CertificationManagement from 'pages/certification-management';
import CertificationCenterGameManagement from 'pages/certification-center-game-management';
import CertificationCenterRealManagement from 'pages/certification-center-real-management';
import BrandManagement from 'pages/brand-management';
import UserManagement from 'pages/user-management';
import UserRecommendManagement from 'pages/user-recommend-management';
import CircleManagement from 'pages/circle-management';
import MsgNoticeManagement from '../../pages/msg-notice-management'
import VipPackageManagement from 'pages/vip-package-management';
import ArticleManagement from 'pages/article-management';
import ClubManagement from 'pages/club-management';
import TrialManagement from "pages/club-management/trial-management";
import ListingManagement from "pages/club-management/listing-management";
import ClubPlanManagement from "pages/club-management/club-plan-management";
import BannerManagement from 'pages/banner-management'
import ReportManagement from 'pages/report-management'
import FeedbackManagement from 'pages/feedback-management'
import DynamicManagement from '../../pages/dynamic-management/index';
import CoinManagement from '../../pages/coin-management/index';
import PayManagement from '../../pages/pay-management';
import AppNoticeManagement from '../../pages/app-notice-management'
import PersonalMessage from 'pages/personal';
import PersonalSet from 'pages/set-personal';
import ErrorPage from 'pages/error';
import TrainingManagement from "../../pages/training-management";
import ConfigManagement from '../../pages/config-management'
import WithdrawManagement from '../../pages/withdraw-management'
import TrailPlanManagement from '../../pages/trail-plan-management'
import TrainingJoinManagement from "../../pages/training-join-management"
import TrainingVideoManagement from "../../pages/training-video-management"
import TrainingTemplateManagement from "../../pages/training-template-management"
import AdminManagement from "../../pages/admin-management";
import CommentManagement from "../../pages/comment-management"
import CircleReport from "../../pages/circle-report";
import AdminOperationLog from "../../pages/admin-operation-log";
import QrCodeManagement from "../../pages/qrcode-management";
import AppContactManagement from "../../pages/app-contact-management";
import AppStartManagement from "../../pages/app-start-management";
import VipActivityManagement from "../../pages/vip-activity-management";
import GradeManagement from "../../pages/grade-management";
import PersonalGrade from "../../pages/grade-management/PersonalGrade";
import SmallModelsManagement from "../../pages/small-models-management";
import SmallPlanManagement from "../../pages/small-plan-management";
import Welcome from "../../pages/welcome";
import PartnerTrainingTeacherManagement from "../../pages/partner-training-teacher-management";
import PartnerTrainingGoodsManagement from "../../pages/partner-training-goods-management";
import PartnerTrainingOrderManagement from "../../pages/partner-training-order-management";
import PelDataIntegralManagement from "../../pages/pel-data-integral-management";
import PelDataPlayerManagement from "../../pages/pel-data-player-management";
import PelDataTeamManagement from "../../pages/pel-data-team-management";
import TrainingDataIntegralManagement from "../../pages/training-data-integral-management";
import TrainingDataPlayerManagement from "../../pages/training-data-player-management";
import TrainingDataTeamManagement from "../../pages/training-data-team-management";
import PersonalTrainingDataIntegralManagement from "../../pages/personal-training-data-integral-management";
import PersonalTrainingDataPlayerManagement from "../../pages/personal-training-data-player-management";
import FoursomeLadderMatchDataIntegralManagement from "../../pages/foursome-ladder-match-data-integral-management";
import FoursomeLadderMatchDataPlayerManagement from "../../pages/foursome-ladder-match-data-player-management";
import ProfessionalPlayerManage from "../../pages/professional-player-manage";
import BrandPersonManagement from "../../pages/brand-person-management";
import TrainingPersonManagement from "../../pages/training-person-management";
import CertificationPersonManagement from "../../pages/certification-person-management";
import TrainingVideoPersonManagement from "../../pages/training-video-person-management";
import TrainingTemplatePersonManagement from "../../pages/training-template-person-management";
import TrainingJoinPersonManagement from "../../pages/training-join-person-management";
import GradePersonTrainingManagement from "pages/grade-person-training-management/PersonalGrade";
import BrandLadderManagement from "../../pages/brand-ladder-management";
import TrainingLadderManagement from "../../pages/training-ladder-management";
import CertificationLadderManagement from 'pages/certification-ladder-management';
import TrainingTemplateLadderManagement from 'pages/training-template-ladder-management';
import TrainingJoinLadderManagement from 'pages/training-join-ladder-management';
import GradeLadderManagement from 'pages/grade-ladder-training-management';
import GradeLadderPersonManagement from 'pages/grade-ladder-training-management/PersonalGrade';
import TrainingVideoLadderManagement from 'pages/training-video-ladder-management';

const authorizedRoutes = [{
    path: '/system/menu_manage',
    exact: true,
    permissions: [],
    component: MenuManagement,
    unauthorized: Unauthorized,
    pageTitle: '菜单管理',
}, {
    path: '/system/role_manage',
    exact: true,
    permissions: [],
    component: RoleManagement,
    unauthorized: Unauthorized,
    pageTitle: '角色管理',
}, {
    path: '/account_manage/user_manage',
    exact: true,
    permissions: [],
    component: UserManagement,
    unauthorized: Unauthorized,
    pageTitle: '用户管理',
},
    {
        path: '/account_manage/user_recommend_manage',
        exact: true,
        permissions: [],
        component: UserRecommendManagement,
        unauthorized: Unauthorized,
        pageTitle: '选手推荐管理',
    },
    {
    path: '/circle/circle_manage',
    exact: true,
    permissions: [],
    component: CircleManagement,
    unauthorized: Unauthorized,
    pageTitle: '圈子管理',
}, {
    path: '/circle/comment_manage',
    exact: true,
    permissions: [],
    component: CommentManagement,
    unauthorized: Unauthorized,
    pageTitle: '评论管理',
},{
    path: '/app/start_manage',
    exact: true,
    permissions: [],
    component: AppStartManagement,
    unauthorized: Unauthorized,
    pageTitle: 'app启动页配置',
},{
    path: '/app/contact_manage',
    exact: true,
    permissions: [],
    component: AppContactManagement,
    unauthorized: Unauthorized,
    pageTitle: '联系信息配置',
}, {
    path: '/circle/circle_report',
    exact: true,
    permissions: [],
    component: CircleReport,
    unauthorized: Unauthorized,
    pageTitle: '圈子举报',
}, {
    path: '/account_manage/pay_manage',
    exact: true,
    permissions: [],
    component: PayManagement,
    unauthorized: Unauthorized,
    pageTitle: '支付订单管理',
}, {
    path: '/account_manage/coin_manage',
    exact: true,
    permissions: [],
    component: CoinManagement,
    unauthorized: Unauthorized,
    pageTitle: '浪币充值包管理',
}, {
    path: '/account_manage/vip_manage',
    exact: true,
    permissions: [],
    component: VipPackageManagement,
    unauthorized: Unauthorized,
    pageTitle: 'vip充值包管理',
}, {
    path: '/account_manage/activity_management',
    exact: true,
    permissions: [],
    component: VipActivityManagement,
    unauthorized: Unauthorized,
    pageTitle: 'vip活动信息',
}, {
    path: '/banner_manage',
    exact: true,
    permissions: [],
    component: BannerManagement,
    unauthorized: Unauthorized,
    pageTitle: '轮播图管理',
}, {
    path: '/team_manage',
    exact: true,
    permissions: [],
    component: TeamManagement,
    unauthorized: Unauthorized,
    pageTitle: '战队管理',
}, {
    path: '/team_training/training_manage',
    exact: true,
    permissions: [],
    component: TrainingManagement,
    unauthorized: Unauthorized,
    pageTitle: '训练赛管理',
}, {
    path: '/team_training/training_join_manage/:trainingId/:trainingName/:gameStartDate/:brandId/:teamTotal',
    exact: true,
    permissions: [],
    component: TrainingJoinManagement,
    unauthorized: Unauthorized,
    pageTitle: '参与详情',
},
// {
//     path: '/club_manage/trial_plan_manage',
//     exact: true,
//     permissions: [],
//     component: TrailPlanManagement,
//     unauthorized: Unauthorized,
//     pageTitle: '试训计划管理',
// },
{
    path: '/team_training/grade_manage/:trainingId/:gameNum/:trainingName/:page',
    exact: true,
    permissions: [],
    component: GradeManagement,
    unauthorized: Unauthorized,
    pageTitle: '战队战绩管理',
}, {
    path: '/team_training/personal_grade_manage/:trainingId/:gameNum/:trainingName',
    exact: true,
    permissions: [],
    component: PersonalGrade,
    unauthorized: Unauthorized,
    pageTitle: '个人战绩管理',
},
    {
    path: '/team_training/certification_manage',
    exact: true,
    permissions: [],
    component: CertificationManagement,
    unauthorized: Unauthorized,
    pageTitle: '参赛认证管理',
},
    {
    path: '/certification_center_manage/game',
    exact: true,
    permissions: [],
    component: CertificationCenterGameManagement,
    unauthorized: Unauthorized,
    pageTitle: '游戏实力认证管理',
},
    {
    path: '/certification_center_manage/real',
    exact: true,
    permissions: [],
    component: CertificationCenterRealManagement,
    unauthorized: Unauthorized,
    pageTitle: '用户实名认证管理',
},

    {
    path: '/team_training/brand_manage',
    exact: true,
    permissions: [],
    component: BrandManagement,
    unauthorized: Unauthorized,
    pageTitle: '品牌赛事管理',
}, {
    path: '/notice/msg_notice_manage',
    exact: true,
    permissions: [],
    component: MsgNoticeManagement,
    unauthorized: Unauthorized,
    pageTitle: '短信通知',
}
    , {
        path: '/system/config_manage',
        exact: true,
        permissions: [],
        component: ConfigManagement,
        unauthorized: Unauthorized,
        pageTitle: '系统配置管理',
    },
    {
        path: '/account_manage/withdraw_manage',
        exact: true,
        permissions: [],
        component: WithdrawManagement,
        unauthorized: Unauthorized,
        pageTitle: '提现管理',
    },
    {
        path: '/article_manage',
        exact: true,
        permissions: [],
        component: ArticleManagement,
        unauthorized: Unauthorized,
        pageTitle: '文章管理',
    },
    {
        path: '/report_manage',
        exact: true,
        permissions: [],
        component: ReportManagement,
        unauthorized: Unauthorized,
        pageTitle: '举报管理',
    },
    {
        path: '/feedback_manage',
        exact: true,
        permissions: [],
        component: FeedbackManagement,
        unauthorized: Unauthorized,
        pageTitle: '举报管理',
    },
    {
        path: '/notice/app_notice_manage',
        exact: true,
        permissions: [],
        component: AppNoticeManagement,
        unauthorized: Unauthorized,
        pageTitle: '应用通知日志',
    },
    {
        path: '/system/personal_message',
        exact: true,
        permissions: [],
        component: PersonalMessage,
        unauthorized: Unauthorized,
        pageTitle: '个人中心',
    },
    {
        path: '/system/personal_set',
        exact: true,
        permissions: [],
        component: PersonalSet,
        unauthorized: Unauthorized,
        pageTitle: '设置',
    },
    {
        path: '/circle/circle_dynamic',
        exact: true,
        permissions: [],
        component: DynamicManagement,
        unauthorized: Unauthorized,
        pageTitle: '动态管理',
    },
    {
        path: '/small/models_manage',
        exact: true,
        permissions: [],
        component: SmallModelsManagement,
        unauthorized: Unauthorized,
        pageTitle: '机型管理',
    },
    {
        path: '/small/plan_manage',
        exact: true,
        permissions: [],
        component: SmallPlanManagement,
        unauthorized: Unauthorized,
        pageTitle: '方案管理',
    },{
        path: '/club_manage',
        exact: true,
        permissions: [],
        component: ClubManagement,
        unauthorized: Unauthorized,
        pageTitle: '俱乐部管理',
    }, {
        path: '/club_trial/trial_manage',
        exact: true,
        permissions: [],
        component: TrialManagement,
        unauthorized: Unauthorized,
        pageTitle: '试训管理',
    },{
        path: '/club_trial/listing_manage',
        exact: true,
        permissions: [],
        component: ListingManagement,
        unauthorized: Unauthorized,
        pageTitle: '自荐管理',
    },
    {
        path: '/club_trial/club_plan_manage',
        exact: true,
        permissions: [],
        component: ClubPlanManagement,
        unauthorized: Unauthorized,
        pageTitle: '试训计划管理',
    },
    {
        path: '/other/404',
        exact: true,
        permissions: [],
        component: ErrorPage,
        unauthorized: Unauthorized,
        pageTitle: '404',
    },
    {
        path: '/welcome',
        exact: true,
        permissions: [],
        component: Welcome,
        unauthorized: Unauthorized,
        pageTitle: '',
    },
    {
        path: '/system/admin_management',
        exact: true,
        permissions: [],
        component: AdminManagement,
        unauthorized: Unauthorized,
        pageTitle: '管理员管理',
    },
    {
        path: '/admin_operation_log',
        exact: true,
        permissions: [],
        component: AdminOperationLog,
        unauthorized: Unauthorized,
        pageTitle: '管理员操作日志',

    }, {
        path: '/qrcode_management',
        exact: true,
        permissions: [],
        component: QrCodeManagement,
        unauthorized: Unauthorized,
        pageTitle: '二维码配置',

    }, {
        path: '/team_training/training_video_management',
        exact: true,
        permissions: [],
        component: TrainingVideoManagement,
        unauthorized: Unauthorized,
        pageTitle: '回放视频管理',
    }, {
        path: '/team_training/training_template_management',
        exact: true,
        permissions: [],
        component: TrainingTemplateManagement,
        unauthorized: Unauthorized,
        pageTitle: '赛事模板管理',
    }, {
        path: '/partner_training/teacher_management',
        exact: true,
        permissions: [],
        component: PartnerTrainingTeacherManagement,
        unauthorized: Unauthorized,
        pageTitle: '导师管理',
    }, {
        path: '/partner_training/goods_management',
        exact: true,
        permissions: [],
        component: PartnerTrainingGoodsManagement,
        unauthorized: Unauthorized,
        pageTitle: '陪练商品管理',
    }, {
        path: '/partner_training/order_management',
        exact: true,
        permissions: [],
        component: PartnerTrainingOrderManagement,
        unauthorized: Unauthorized,
        pageTitle: '陪练订单管理',
    }, {
        path: '/pel_data_management/integral',
        exact: true,
        permissions: [],
        component: PelDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: 'PEL积分榜单管理',
    }, {
        path: '/pel_data_management/player',
        exact: true,
        permissions: [],
        component: PelDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: 'PEL选手榜单管理',
    }, {
        path: '/pel_data_management/team',
        exact: true,
        permissions: [],
        component: PelDataTeamManagement,
        unauthorized: Unauthorized,
        pageTitle: 'PEL战队榜单管理',
    }, {
        path: '/personal_training_data_management/personalIntegral',
        exact: true,
        permissions: [],
        component: PersonalTrainingDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: '天梯单排赛积分榜单管理',
    }, {
        path: '/personal_training_data_management/personalPlayer',
        exact: true,
        permissions: [],
        component: PersonalTrainingDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: '天梯单排赛选手榜单管理',
    },
    {
        path: '/foursome_ladder_match_data_management/foursomeIntegral',
        exact: true,
        permissions: [],
        component: FoursomeLadderMatchDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: '天梯四排赛积分榜单管理',
    }, {
        path: '/foursome_ladder_match_data_management/foursomePlayer',
        exact: true,
        permissions: [],
        component: FoursomeLadderMatchDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: '天梯四排赛选手榜单管理',
    }, {
        path: '/training_data_management/integral',
        exact: true,
        permissions: [],
        component: TrainingDataIntegralManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛积分榜单管理',
    }, {
        path: '/training_data_management/player',
        exact: true,
        permissions: [],
        component: TrainingDataPlayerManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛选手榜单管理',
    }, {
        path: '/training_data_management/team',
        exact: true,
        permissions: [],
        component: TrainingDataTeamManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛战队榜单管理',
    },
    {
        path: '/professional_player_manage',
        exact: true,
        permissions: [],
        component: ProfessionalPlayerManage,
        unauthorized: Unauthorized,
        pageTitle: '职业选手主页管理',
    },
    {
        path: '/person_training/brand_person_manage',
        exact: true,
        permissions: [],
        component: BrandPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '品牌赛事管理',
    }, {
        path: '/person_training/training_person_manage',
        exact: true,
        permissions: [],
        component: TrainingPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛管理',
    }, {
        path: '/person_training/certification_person_manage',
        exact: true,
        permissions: [],
        component: CertificationPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '参赛认证管理',
    }, {
        path: '/person_training/training_video_person_management',
        exact: true,
        permissions: [],
        component: TrainingVideoPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '回放视频管理',
    }, {
        path: '/person_training/training_template_person_management',
        exact: true,
        permissions: [],
        component: TrainingTemplatePersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '赛事模板管理',
    }, {
        path: '/person_training/training_join_manage/:trainingId/:trainingName/:gameStartDate/:brandId/:personalTotal',
        exact: true,
        permissions: [],
        component: TrainingJoinPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '参与详情',
    }, {
        path: '/person_training/personal_grade_manage/:trainingId/:gameNum/:trainingName',
        exact: true,
        permissions: [],
        component: GradePersonTrainingManagement,
        unauthorized: Unauthorized,
        pageTitle: '个人战绩管理',
    },

    {
        path: '/ladder_training/brand_ladder_management',
        exact: true,
        permissions: [],
        component: BrandLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '品牌赛事管理',
    }, {
        path: '/ladder_training/training_ladder_management',
        exact: true,
        permissions: [],
        component: TrainingLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '训练赛管理',
    }, {
        path: '/ladder_training/certification_ladder_management',
        exact: true,
        permissions: [],
        component: CertificationLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '参赛认证管理',
    }, {
        path: '/ladder_training/training_video_ladder_management',
        exact: true,
        permissions: [],
        component: TrainingVideoLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '回放视频管理',
    }, {
        path: '/ladder_training/training_template_ladder_management',
        exact: true,
        permissions: [],
        component: TrainingTemplateLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '赛事模板管理',
    }, {
        path: '/ladder_training/training_join_manage/:trainingId/:trainingName/:gameStartDate/:brandId/:personalTotal',
        exact: true,
        permissions: [],
        component: TrainingJoinLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '参与详情',
    }, {
        path: '/ladder_training/grade_manage/:trainingId/:gameNum/:trainingName/:page',
        exact: true,
        permissions: [],
        component: GradeLadderManagement,
        unauthorized: Unauthorized,
        pageTitle: '战队战绩管理',
    }, {
        path: '/ladder_training/personal_grade_manage/:trainingId/:gameNum/:trainingName',
        exact: true,
        permissions: [],
        component: GradeLadderPersonManagement,
        unauthorized: Unauthorized,
        pageTitle: '个人战绩管理',
    }

];

const normalRoutes = [{
    path: '/',
    exact: true,
    redirect: '/welcome',
}, {
    path: '/login',
    exact: true,
    component: Login,
}];

const combineRoutes = [
    ...authorizedRoutes,
    ...normalRoutes,
];

export {
    authorizedRoutes,
    normalRoutes,
    combineRoutes,
};
